import { profileScoreHistoryAction } from "redux/modules";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

const ProfileScoreHistory = props => {
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.profileScoreHistory.isLoading);

  const scoreTrxList = useSelector(
    store => store.profileScoreHistory.scoreTrxList
  );
  const totalPages = useSelector(store => store.profileScoreHistory.totalPages);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    dispatch(
      profileScoreHistoryAction.getTrxList(employeeFirm.id, pageNumber - 1)
    );
  }
  useEffect(() => {
    dispatch(profileScoreHistoryAction.getTrxList(employeeFirm.id, 0));
    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [scoreTrxList, employeeFirm]);

  return (
    <div
      className="tab-pane fade show active"
      id="availability-point"
      role="tabpanel"
      aria-labelledby="availability-point-tab"
    >
      <GlobalLoader isLoading={isLoading} />
      <div className="user-content-item orders">
        <div className="user-content-text">
          <p style={{ fontSize: "14px" }}>
            Kazandığınız puanları bu bölümde detaylı olarak görebilir, takip
            edebilirsiniz. &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
            &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
            &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
          </p>
          <div className="orders-list-container orders-past-list mt-4">
            {scoreTrxList && scoreTrxList.length > 0
              ? scoreTrxList.map((item, index) => {
                  return (
                    <div key={index} className="orders-past-item">
                      <div className="row">
                        <div className="col-md-4 orders-past-item-col">
                          <span className={"d-inline-block mr-2"}>
                            <img
                              alt=""
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMwZjVmYTIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLXRyZW5kaW5nLXVwIj48cG9seWxpbmUgcG9pbnRzPSIyMyA2IDEzLjUgMTUuNSA4LjUgMTAuNSAxIDE4Ij48L3BvbHlsaW5lPjxwb2x5bGluZSBwb2ludHM9IjE3IDYgMjMgNiAyMyAxMiI+PC9wb2x5bGluZT48L3N2Zz4="
                            />
                          </span>
                          <span>Puan</span>
                          {item.scoreType === "SIPARIS" ? (
                            <p>-{item.changedScore}</p>
                          ) : (
                            <p>+{item.changedScore}</p>
                          )}
                        </div>
                        <div className="col-md-4 orders-past-item-col">
                          <span className={"d-inline-block mr-2"}>
                            <img
                              alt=""
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMwZjVmYTIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWJvb2ttYXJrIj48cGF0aCBkPSJNMTkgMjFsLTctNS03IDVWNWEyIDIgMCAwIDEgMi0yaDEwYTIgMiAwIDAgMSAyIDJ6Ij48L3BhdGg+PC9zdmc+"
                            />
                          </span>
                          <span>Açıklama</span>
                          <p>{item.scoreTypeName}</p>
                        </div>
                        <div className="col-md-4 orders-past-item-col">
                          <span className={"d-inline-block mr-2"}>
                            <img
                              alt=""
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMwZjVmYTIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNhbGVuZGFyIj48cmVjdCB4PSIzIiB5PSI0IiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHJ4PSIyIiByeT0iMiI+PC9yZWN0PjxsaW5lIHgxPSIxNiIgeTE9IjIiIHgyPSIxNiIgeTI9IjYiPjwvbGluZT48bGluZSB4MT0iOCIgeTE9IjIiIHgyPSI4IiB5Mj0iNiI+PC9saW5lPjxsaW5lIHgxPSIzIiB5MT0iMTAiIHgyPSIyMSIgeTI9IjEwIj48L2xpbmU+PC9zdmc+"
                            />
                          </span>
                          <span>Tarih</span>
                          <p>{item.dateCreated.slice(0, 10)}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <Pagination
        activePage={activePage}
        totalItemsCount={10 * totalPages}
        itemsCountPerPage={10}
        pageRangeDisplayed={
          totalPages !== null && totalPages > 5 ? 5 : totalPages
        }
        itemClass="page-item"
        linkClass="page-link"
        onChange={handlePageChange.bind(this)}
      />
    </div>
  );
};

export default ProfileScoreHistory;
