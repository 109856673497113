import React, { useState } from "react";
import { useSelector } from "react-redux";
import CatalogContent from "./CatalogContent";
import CatalogLeftMenu from "./CatalogLeftMenu";
import TopAnnouncement from "../TopAnnouncement/TopAnnouncement";
import UserPortalMenu from "../UserPortalMenu/UserPortalMenu";
import UserPortalInfo from "../UserPortalInfo/UserPortalInfo";
import UserPortalWidget from "../UserPortalWidget/UserPortalWidget";
import UserPortalFooter from "../UserPortalFooter/UserPortalFooter";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowForward
} from "react-icons/io5";
import { FaInfoCircle, FaShoppingCart } from "react-icons/fa";

import { Button, Modal } from "react-bootstrap";

const Catalog = props => {
  const currentCatalogContent = useSelector(
    store => store.catalog.currentCatalogContent
  );
  const SelectedContentDisplay = CatalogContent(currentCatalogContent);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <TopAnnouncement />
      <UserPortalMenu />
      <UserPortalInfo />

      {/* main */}
      <main className="bg-slate-50 pb-20">
        {/* title */}
        <section className="glaire bg-[#16435E] py-6 md:py-12 flex flex-col justify-center items-center min-h-[146px]">
          <div className="container mx-auto px-4">
            <h2 className="text-center text-2xl font-semibold leading-none text-white md:text-[32px]">
              Bizim Dükkan
            </h2>
          </div>
        </section>

        <div className="mx-auto mt-4 px-4 xl:container md:mt-20 lg:mt-12">
          <div className="grid grid-cols-1 gap-3 sm:gap-4 md:grid-cols-12">
            <CatalogLeftMenu props={props} />

            <SelectedContentDisplay />
          </div>
        </div>
      </main>

      <UserPortalWidget />
      <UserPortalFooter />
    </>
  );
};

export default Catalog;
