import { combineReducers } from "redux";

import modal from "./modal/modal.reducer";
import auth from "./auth/auth.reducer";
import user from "./user/user.reducer";
import employeeFirm from "./employeeFirm/employeeFirm.reducer";
import profile from "./profile/profile.reducer";
import profileAddress from "./profile/profileAddress/profileAddress.reducer";
import profileScoreHistory from "./profile/profileScoreHistory/profileScoreHistory.reducer";
import home from "./home/home.reducer";
import brandStyle from "./brandStyle/brandStyle.reducer";
import brand from "./brand/brand.reducer";
import order from "./order/order.reducer";
import about from "./about/about.reducer";
import lookFind from "./lookFind/lookFind.reducer";
import searchFind from "./searchFind/searchFind.reducer";
import pickFind from "./pickFind/pickFind.reducer";
import scoreHunt from "./scoreHunt/scoreHunt.reducer";
import weekStore from "./weekStore/weekStore.reducer";
import magazine from "./magazine/magazine.reducer";
import ourArena from "./ourArena/ourArena.reducer";
import catalog from "./catalog/catalog.reducer";
import game from "./game/game.reducer";
import tvArea from "./tvArea/tvArea.reducer";
import disclaimer from "./disclaimer/disclaimer.reducer";
import eorder from "./eorder/eorder.reducer";
import topAnnouncement from "./topAnnouncement/topAnnouncement.reducer";
import camelLaunch from "./camel-launch/camelLaunch.reducer";
import widget from "./widget/widget.reducer";
import news from "./news/news.reducer";
import leaderboard from "./leaderboard/leaderboard.reducer";

const rootReducer = combineReducers({
  auth,
  modal,
  user,
  employeeFirm,
  profile,
  profileAddress,
  profileScoreHistory,
  home,
  brandStyle,
  brand,
  order,
  about,
  lookFind,
  searchFind,
  pickFind,
  scoreHunt,
  weekStore,
  magazine,
  ourArena,
  catalog,
  game,
  tvArea,
  disclaimer,
  eorder,
  topAnnouncement,
  camelLaunch,
  widget,
  news,
  leaderboard
});

export default rootReducer;
