import React from "react";
import "./Score.scss";

import NpkLogo from "assets/portal-resource/img/npk-logo.png";
import FaqIcon from "assets/portal-resource/img/icon/faq.png";
import TermConditionsIcon from "assets/portal-resource/img/icon/term-and-conditions.png";
import UserGuideIcon from "assets/portal-resource/img/icon/user-guide.png";

import { Link } from "react-router-dom";
import Accordion from "../../../components/Accordion";

const Score = () => {
  function setClicked(id) {
    let collapseFlag = document.querySelectorAll(
      "a[data-target=" + "'#" + id + "'" + "]"
    )[0].ariaExpanded;
    if (collapseFlag === "false") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  const accordionItemsScore = [
    {
      title: `Giriş`,
      content: `Benim Bayim’e giriş yapın, giriş yaptığınız her gün için 20 puan kazanın.`
    },

    {
      title: `Ziyaret Sıklığı / Ay`,
      content: `Benim Bayim’e giriş yaptığınız her ay için, ilgili ayda ilk girişinizde sadece bir kez olmak üzere 200 puan kazanın.`
    },

    {
      title: `Anket`,
      content: `Her hafta yenilenen Anket sorusunu yanıtlayarak 200 puan kazanın. Yeni Anket sorusu Pazartesi günleri yayınlanır ve ankete haftada sadece bir kez yanıt verilebilir.`
    },

    {
      title: `Yarışmalar`,
      content: `<div>
      <h3>Rozet Macerası</h3>
      ·      Ana sayfadaki üst menüden Yarışmalar Ana
      Sayfasına gidin, açılan sayfadan Rozet Macerası butonunu
      tıklayın.
      <br />
      ·      Ana sayfa orta alandan da yarışma ikonlarına
      tıklayarak yarışmalara giriş yapabilirsiniz.
      <br />
      ·      Rozet Macerası sayfasında Winston, Camel, LD,
      Monte Carlo için toplamda 4 kutucuk bulunmaktadır.
      İstediğiniz markanın sorusunu seçip,
      yanıtlayabilirsiniz.
      <br />
      ·      Başla butonuna tıkladığınızda soru açılacak, 90
      saniye içinde soruyu cevaplamanız gerekecektir.
      <br />
      ·      Rozet Macerası'da her markanın kendine ait 5
      rozeti mevcuttur.
      <br />
      ·       Her 6 doğru yanıtta 1 rozet kazanacaksınız. Dönem sonuna kadar 30 doğru cevap ile bir markanın 5 rozetini de kazanabilirsiniz.
       Toplam 120 doğru cevap ile 4 markanın da 5'er rozetini kazanabilirsiniz.
      <br />
      ·      90 saniye içinde doğru cevabı verin, puan
      kazanın.
      <br />
      ·      Rozet Macerası soruları yalnızca Pazartesi,
      Çarşamba ve Perşembe günleri sorulacaktır.
      <br />
      ·       Bu yarışma sorularını doğru yanıtladığınız her gün için 100 puan kazanın.
      <br />
      <br />
      <h3>Puan Avı</h3>
      ·      Ana sayfadaki menüden Yarışmalar Ana Sayfasına
      gidin, açılan sayfadan Puan Avı butonunu tıklayın.
      <br />
      ·      Puan Avı sorusu her seferinde farklı bir markanın stillerinden birinde gizlidir.
      <br />
      ·      Başla butonuna tıkladığınızda otomatik olarak sorunun o gün gizlendiği markanın sayfasına yönlendirilirsiniz. 
      <br />
      ·      Puan Avı ikonunu bulana kadar o markanın tüm stillerini inceleyin.
      <br />
      ·      Puan Avı ikonunu bulduğunuzda ikonu tıklayarak soruya ulaşın.
      <br />
      ·      90 saniye içinde doğru cevabı verin, puan
      kazanın.
      <br />
      ·      Puan Avı soruları yalnızca Salı, Çarşamba ve Cuma günleri sorulacaktır.
      <br />
      ·       Bu yarışma sorularını doğru yanıtladığınız her gün için 100 puan kazanın.
      <br />
      <br />
      <h3>Eşle Kazan</h3>
      ·       Ana sayfadaki menüden Yarışmalar Ana Sayfasına
      gidin, açılan sayfadan Eşle Kazan butonunu tıklayın.
      <br />
      ·       Ana sayfa orta alandan da yarışma ikonlarına
      tıklayarak yarışmalara giriş yapabilirsiniz.
      <br />
      ·       Eşle Kazan soruları, JTI ürünleri ile bu
      ürünlere ait özelliklerin eşleştirilmesi yarışmasıdır.
      <br />
      ·       Bu yarışmada süre sınırı yoktur. Tüm ürünlerle
      özellikleri doğru olarak eşleştirin, puan kazanın.
      <br />
      ·       Eşle Kazan soruları yalnızca Pazartesi, Salı,
      Perşembe ve Cuma günleri sorulacaktır.
      <br />
      ·       Bu yarışma sorularını doğru yanıtladığınız her
      gün için 100 puan kazanın.
      <br />
      <br />
      <h3>Haftanın Dükkanı</h3>
      ·       Ana sayfadaki menüden Yarışmalar Ana Sayfasına
      gidin, açılan sayfadan Haftanın Dükkanı butonunu
      tıklayın.
      <br />
      ·       Ana sayfa orta alandan da yarışma ikonlarına
      tıklayarak yarışmalara giriş yapabilirsiniz.
      <br />
      ·       Açılan menüden Haftanın Dükkanı butonuna
      tıklayın.
      <br />
      ·       Haftanın Dükkanı’nda diğer bayilerin dükkan
      fotoğraflarına oy vererek her hafta 200 puan kazanın.
      <br />
      <br />
    </div>`
    },

    {
      title: `Stand Puanı`,
      content: `<div>
      Bayinizde bulunan JTI standının çeşidine göre aşağıda
      belirtilen puanları kazanabilirsiniz.
      <br />
      ( Standınızın hangi puan grubuna ait olduğu bilgisini
      JTI satış temsilcinizden öğrenebilirsiniz. )
      <br />
      <br />
      <table class="tg">
        <thead>
          <tr>
            <th class="tg-0lax">1.Grup</th>
            <th class="tg-0lax">2.Grup</th>
            <th class="tg-0lax">3.Grup</th>
            <th class="tg-0lax">4.Grup</th>
            <th class="tg-0lax">5.Grup</th>
            <th class="tg-0lax">6.Grup</th>
            <th class="tg-0lax">7.Grup</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tg-0lax">200</td>
            <td class="tg-0lax">550</td>
            <td class="tg-0lax">750</td>
            <td class="tg-0lax">1100</td>
            <td class="tg-0lax">1850</td>
            <td class="tg-0lax">2200</td>
            <td class="tg-0lax">2750</td>
          </tr>
        </tbody>
      </table>
      <br />
      İçinde bulunulan aya ait stand puanları bir sonraki ayın
      ilk 10 günü içinde puanlarınıza eklenecektir.
      <br />
      Bayinizde birden fazla JTI standı bulunması durumunda,
      yalnızca en büyük stand için geçerli olan puanı
      kazanırsınız. Tüm standların puanı toplanmaz.
      <br />
      Ay içinde bayinize stand takılması ya da standınızın
      geri alınması durumunda, o ay için de stand puanının
      tamamı bir sonraki ay puanıza eklenir.
      <br />
      İlgili ayın stand puanının yüklenmesi için, o ay içinde
      Benim Bayim'e en az bir kez giriş yapmalısınız.
      <br />
      10 Temmuz 2023- 09 Haziran 2024 tarihleri arasında
      gerçekleştirilecek programın stand puanı yükleme tablosu
      şu şekildedir:
      <br />
      <br />
      <table class="tg">
        <thead>
          <tr>
            <th class="tg-zj9c">Stand Puanı Dönemi</th>
            <th class="tg-zj9c">Yükleme Zamanı</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tg-zj9c">Temmuz 2023</td>
            <td class="tg-zj9c">
            Ağustos ilk 10 günü içinde
            </td>
          </tr>
          <tr>
            <td class="tg-zj9c">Ağustos 2023</td>
            <td class="tg-zj9c">Eylül ilk 10 günü içinde</td>
          </tr>
          <tr>
            <td class="tg-zj9c">Eylül 2023</td>
            <td class="tg-zj9c">Ekim ilk 10 günü içinde</td>
          </tr>
          <tr>
            <td class="tg-zj9c">Ekim 2023</td>
            <td class="tg-zj9c">Kasım ilk 10 günü içinde</td>
          </tr>
          <tr>
            <td class="tg-zj9c">Kasım 2023</td>
            <td class="tg-zj9c">
            Aralık ilk 10 günü içinde
            </td>
          </tr>
          <tr>
            <td class="tg-zj9c">Aralık 2023</td>
            <td class="tg-zj9c">Ocak ilk 10 günü içinde</td>
          </tr>
          <tr>
            <td class="tg-zj9c">Ocak 2024</td>
            <td class="tg-zj9c">
            Şubat ilk 10 günü içinde
            </td>
          </tr>
          <tr>
            <td class="tg-zj9c">Şubat 2024</td>
            <td class="tg-zj9c">Mart ilk 10 günü içinde</td>
          </tr>
          <tr>
            <td class="tg-zj9c">Mart 2024</td>
            <td class="tg-zj9c">Nisan ilk 10 günü içinde</td>
          </tr>
          <tr>
            <td class="tg-zj9c">Nisan 2024</td>
            <td class="tg-zj9c">Mayıs ilk 10 günü içinde</td>
          </tr>
          <tr>
            <td class="tg-zj9c">Mayıs 2024</td>
            <td class="tg-zj9c">Haziran ilk 10 günü içinde</td>
          </tr>
          <tr>
            <td class="tg-zj9c">Haziran 2024</td>
            <td class="tg-zj9c">Haziran ayı için stand puanı yüklenmeyecektir.
            </td>
          </tr>
        </tbody>
      </table>
    </div>`
    },

    {
      title: `Puan Kullanım Süresi`,
      content: `<h3>Puanlarımın Süresi ne kadardır?</h3>
    <p>
    Benim Bayim’in 2023-2024 dönemi 10 Temmuz 2023 ila 09 Haziran 2024 tarihleri arasındadır. Bu dönemde kazandığınız puanlarınızı 09 Haziran 2024 
    günü saat 23:59’a kadar harcayabilirsiniz. Harcadıktan sonra hesabınızda kalan ya da çeşitli nedenlerle kullanılmamış tüm puanlar silinir. 
    Silinen puanlara ilişkin JTI’dan hiçbir talepte bulunulamaz.
    </p>`
    }
  ];

  return (
    <section>
      <div className="container-fluid mx-auto text-center page-head">
        <h3>Nasıl Puan Kazanırım</h3>
      </div>

      <div className="container mx-auto my-20">
        <div className="flex flex-col md:flex-row w-100">
          <div className="basis-full md:basis-3/12">
            <div className="detail-page-left-menu">
              <ul>
                <li>
                  <Link to={`/userportal/faq`} className="font-medium">
                    Sıkça Sorulan Sorular
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/tac`} className="font-medium">
                    Şartlar Koşullar
                  </Link>
                </li>

                <li className="active">
                  <Link to={`/userportal/score`} className="font-medium">
                    Nasıl Puan Kazanırım
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/userGuide`} className="font-medium">
                    Kullanım Kılavuzu
                  </Link>
                </li>
                <li>
                  <Link to={`/userportal/announcement`} className="font-medium">
                    Duyurular
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="basis-full md:basis-9/12 pl-10">
            <Accordion accordionItems={accordionItemsScore} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Score;
