import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { camelLaunchAction } from "../../../redux/modules";
import { imageShowUrl, videoShowUrl } from "../../../config/api.config";
import Swiper from "swiper";
import "./Camel.scss";
import Modal from "react-responsive-modal";

// Img
import secondarySliderBg from "../../../assets/images/secondary-slider@2x.jpg";
import secondarySliderGrayscaleBg from "../../../assets/images/secondary-slider-grayscale@2x.png";
import camelMainSliderGif from "../../../assets/images/banner-web.gif";
import camelMainSliderGifMobile from "../../../assets/images/banner-mobile.gif";
import camelYellow from "../../../assets/images/camel-yellow-100.png";

// Logos
import camelLogo from "../../../assets/images/logo/camel.png";
import camelCommercialBrochure from "../../../assets/images/logo/camel-ticari-brosur.png";
import camelProductInfo from "../../../assets/images/logo/camel-urun-ozellikleri.png";
import camelVideos from "../../../assets/images/logo/camel-videolar.png";
import camelLeaf from "../../../assets/images/leaf.png";
import { API } from "../../../services/http.service";
import { userAction } from "../../../redux/modules";

import commercial1 from "../../../assets/images/commercial/commercial-1.jpg";
import commercial2 from "../../../assets/images/commercial/commercial-2.jpg";
import commercial3 from "../../../assets/images/commercial/commercial-3.jpg";
import commercial4 from "../../../assets/images/commercial/commercial-4.jpg";

// Text List
import priceListText2x from "../../../assets/images/text-list/fiyat-listesi@2x.png";
import doYouKnowThisWhiteText2x from "../../../assets/images/text-list/bunlari-biliyor-musunuz-white@2x.png";
import doYouKnowThisText2x from "../../../assets/images/text-list/bunlari-biliyor-musunuz@2x.png";
import infoText2x from "../../../assets/images/text-list/ozellikler@2x.png";
import commercialBrochureText2x from "../../../assets/images/text-list/ticari-brosur@2x.png";

import $ from "jquery";

const Camel = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modalImg, setModalImg] = useState(null);
  const [web, setWeb] = useState();
  const [mob, setMob] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const camelLaunchVideoList = useSelector(
    store => store.camelLaunch.camelLaunchVideoList
  );
  const [camelVideoList, setCamelVideoList] = useState(null);
  const [imgModal, setImgModal] = useState(false);
  const [imgBrochureModal, setImgBrochureModal] = useState(false);
  const [priceListImage, setPriceListImage] = useState("");

  useEffect(() => {
    const asyncPriceListImageData = async () => {
      const response = await API.get("price/Fiyat Listesi");
      setPriceListImage(response);
    };
    asyncPriceListImageData();
  }, [priceListImage === null]);

  useEffect(() => {
    dispatch(camelLaunchAction.getCamelLaunchVideo());

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    setCamelVideoList(camelLaunchVideoList);

    return () => {};
  }, [camelLaunchVideoList]);

  useEffect(() => {
    const asyncPopupImg = async () => {
      const { data } = await API.get("focus/Lansman Pop Up");

      setModalImg(data);
      if (
        data &&
        data[0].webPic != undefined &&
        data[0].mobilPic != undefined &&
        data[0].name != undefined &&
        data[0].description != undefined
      ) {
        setWeb(data[0].webPic);
        setMob(data[0].mobilPic);
        setName(data[0].name);
        setDescription(data[0].description);
      }
    };
    asyncPopupImg();
  }, [modalImg === null]);

  function closeModal(params) {
    setOpen(!open);
    dispatch(userAction.setLoginedUser("logined"));
  }

  function openImgModal(param) {
    setImgModal(true);
  }

  function openImgBrochureModal(e, index) {
    setImgBrochureModal(true);
    const bodyElem = $("body");
    bodyElem.addClass("camel-launch-page");

    setTimeout(() => {
      window.camelCommercialBrochurePopup = new Swiper(
        ".swiper-commercial-brochure-popup",
        {
          navigation: {
            nextEl: ".swiper-button-next.swiper-commercial-brochure-popup-next",
            prevEl: ".swiper-button-prev.swiper-commercial-brochure-popup-prev"
          },
          initialSlide: index - 1
        }
      );
    }, 300);
  }

  function detailPageOpen(param, index) {
    const elem = document.getElementById("camel-page-content-detail");
    elem.classList.remove("hide");
    document.getElementById(param).classList.remove("hide");

    $("html,body").animate(
      {
        scrollTop:
          $(".camel-page-content-detail").offset().top -
          $(".header-menu").height()
      },
      700
    );

    setTimeout(() => {
      if (window.innerWidth >= 768) {
        window.camelCommercialBrochure = new Swiper(
          ".swiper-commercial-brochure",
          {
            slidesPerView: 2.5,
            centeredSlides: false,
            spaceBetween: 60,
            navigation: {
              nextEl: ".swiper-commercial-brochure-next",
              prevEl: ".swiper-commercial-brochure-prev"
            },
            loop: false
            // breakpoints: {
            //   768: {
            //     slidesPerView: 1.5,
            //   },
            //   1650: {
            //     slidesPerView: 2.5,
            //   }
            // }
          }
        );
      } else {
        window.camelCommercialBrochure.destroy();
      }
    }, 300);

    setTimeout(() => {
      window.camelInfoSlider = new Swiper(".swiper-info-slider", {
        slidesPerView: 2,
        centeredSlides: false,
        spaceBetween: 30
      });
    }, 300);

    setTimeout(() => {
      if (window.camelVideoDetailSlider) {
        window.camelVideoDetailSlider.destroy();
      }

      window.camelVideoDetailSlider = new Swiper(
        ".swiper-video-detail-slider",
        {
          slidesPerView: 1,
          navigation: {
            nextEl: ".swiper-video-detail-next",
            prevEl: ".swiper-video-detail-prev"
          },
          initialSlide: index,
          on: {
            slideChange: function() {
              const videoList = document.getElementsByTagName("video");
              for (let i = 0; i < videoList.length; i++) {
                videoList[i].pause();
              }
            }
          }
        }
      );
    }, 300);

    setTimeout(() => {
      window.camelCommercialBrochurePopup = new Swiper(
        ".swiper-commercial-brochure-popup",
        {
          navigation: {
            nextEl: ".swiper-button-next.swiper-commercial-brochure-popup-next",
            prevEl: ".swiper-button-prev.swiper-commercial-brochure-popup-prev"
          }
        }
      );
    }, 300);
  }

  function detailPageClose() {
    window.camelVideoDetailSlider.destroy();

    const elem = document.getElementById("camel-page-content-detail");
    elem.classList.add("hide");

    const allFluid = document.querySelectorAll(
      "#camel-page-content-detail > .container-fluid"
    );
    for (let i = 0; i < allFluid.length; i++) {
      allFluid[i].classList.add("hide");
    }

    const videoList = document.getElementsByTagName("video");
    for (let i = 0; i < videoList.length; i++) {
      videoList[i].pause();
      videoList[i].currentTime = 0;
    }
  }

  useEffect(() => {
    function commercialFunc() {
      setTimeout(() => {
        if (window.innerWidth >= 768) {
          window.camelCommercialBrochure = new Swiper(
            ".swiper-commercial-brochure",
            {
              slidesPerView: 1,
              centeredSlides: false,
              spaceBetween: 60,
              navigation: {
                nextEl: ".swiper-commercial-brochure-next",
                prevEl: ".swiper-commercial-brochure-prev"
              }
              // breakpoints: {
              //   320: {
              //     slidesPerView: 1.5,
              //   },
              //   1650: {
              //     slidesPerView: 2.5,
              //   }
              // }
            }
          );
        } else {
          window.camelCommercialBrochure.destroy();
        }
      }, 300);
    }

    window.onresize = commercialFunc;
    commercialFunc();

    setTimeout(() => {
      window.camelMainSlider = new Swiper(".swiper-camel-main-slider", {
        slidesPerView: 1,
        loop: false,
        autoHeight: true
      });
    }, 300);

    setTimeout(() => {
      window.camelSecondaryPageSlider = new Swiper(
        ".swiper-camel-secondary-slider",
        {
          slidesPerView: 1,
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          loop: true,
          autoHeight: false
        }
      );
    }, 300);

    setTimeout(() => {
      function videoSlider() {
        if (window.innerWidth >= 768) {
          if (window.camelVideoSlider) {
            window.camelVideoSlider.destroy();
          }

          window.camelVideoSlider = new Swiper(".swiper-video-slider", {
            slidesPerView: "auto",
            centeredSlides: false,
            spaceBetween: 30,
            navigation: {
              nextEl: ".swiper-video-next",
              prevEl: ".swiper-video-prev"
            },
            breakpoints: {
              320: {
                slidesPerView: 1,
                spaceBetween: 10
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30
              }
            }
          });
        } else {
          window.camelVideoSlider.destroy();
        }
      }

      window.onresize = videoSlider;
      videoSlider();
    }, 300);

    setTimeout(() => {
      window.camelInfoSlider = new Swiper(".swiper-info-slider", {
        slidesPerView: 2,
        centeredSlides: false,
        spaceBetween: 30
      });
    }, 300);
  }, []);

  return (
    <div className="camel-page">
      <div className="camel-page-sliders">
        <section className="main-slider camel-slider">
          <div className="main-slider-container">
            <div className="swiper-container swiper-main-slider swiper-camel-main-slider">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="main-slider-bg main-slider-desktop">
                    <img src={camelMainSliderGif} className="img-fluid" />
                  </div>

                  <div className="main-slider-bg main-slider-mobil">
                    <img src={camelMainSliderGifMobile} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="main-slider camel-secondary-slider">
          <div className="main-slider-container">
            <div className="swiper-container swiper-main-slider swiper-camel-secondary-slider">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <a href="#" target="_blank">
                    <h3 className="d-none d-md-block">
                      <img
                        src={doYouKnowThisWhiteText2x}
                        className="img-fluid"
                      />
                    </h3>
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(${secondarySliderBg})`
                      }}
                    >
                      <p>
                        Bir poşet Camel sarmalık kıyılmış tütünle 6 pakete kadar
                        sigara hazırlayabilirsiniz.
                      </p>
                    </div>

                    <h3 className="d-block d-md-none">
                      <img
                        src={doYouKnowThisWhiteText2x}
                        className="img-fluid"
                      />
                    </h3>
                    <div className="main-slider-bg main-slider-mobil">
                      <img src={secondarySliderBg} className="img-fluid" />
                      <p>
                        Bir poşet Camel sarmalık kıyılmış tütünle 6 pakete kadar
                        sigara hazırlayabilirsiniz.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="swiper-slide grayscale">
                  <a href="#" target="_blank">
                    <h3 className="d-none d-md-block">
                      <img src={doYouKnowThisText2x} className="img-fluid" />
                    </h3>
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(${secondarySliderGrayscaleBg})`
                      }}
                    >
                      <p>
                        Tüketiciler Camel sarmalık kıyılmış tütünü makaronu
                        doldurarak hazırlayabilir. İsterlerse sigara kağıdı ile
                        sararak da hazırlayabilirler.
                      </p>
                    </div>

                    <h3 className="d-block d-md-none">
                      <img src={doYouKnowThisText2x} className="img-fluid" />
                    </h3>
                    <div className="main-slider-bg main-slider-mobil">
                      <img
                        src={secondarySliderGrayscaleBg}
                        className="img-fluid"
                      />
                      <p>
                        Tüketiciler Camel sarmalık kıyılmış tütünü makaronu
                        doldurarak hazırlayabilir. İsterlerse sigara kağıdı ile
                        sararak da hazırlayabilirler.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="swiper-slide">
                  <a href="#" target="_blank">
                    <h3 className="d-none d-md-block">
                      <img
                        src={doYouKnowThisWhiteText2x}
                        className="img-fluid"
                      />
                    </h3>
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(${secondarySliderBg})`
                      }}
                    >
                      <p>
                        Camel sarmalık kıyılmış tütün Türkiye’de piyasaya
                        sunulmuş ilk uluslararası sarmalık kıyılmış tütün
                        ürünüdür.
                      </p>
                    </div>

                    <h3 className="d-block d-md-none">
                      <img
                        src={doYouKnowThisWhiteText2x}
                        className="img-fluid"
                      />
                    </h3>
                    <div className="main-slider-bg main-slider-mobil">
                      <img src={secondarySliderBg} className="img-fluid" />
                      <p>
                        Camel sarmalık kıyılmış tütün Türkiye’de piyasaya
                        sunulmuş ilk uluslararası sarmalık kıyılmış tütün
                        ürünüdür.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="swiper-slide grayscale">
                  <a href="#" target="_blank">
                    <h3 className="d-none d-md-block">
                      <img src={doYouKnowThisText2x} className="img-fluid" />
                    </h3>
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(${secondarySliderGrayscaleBg})`
                      }}
                    >
                      <p>
                        Camel sarmalık kıyılmış tütün, tüketicilerine yüksek
                        Camel kalitesiyle tam içim keyfi deneyimi sunar.
                      </p>
                    </div>

                    <h3 className="d-block d-md-none">
                      <img src={doYouKnowThisText2x} className="img-fluid" />
                    </h3>
                    <div className="main-slider-bg main-slider-mobil">
                      <img
                        src={secondarySliderGrayscaleBg}
                        className="img-fluid"
                      />
                      <p>
                        Camel sarmalık kıyılmış tütün, tüketicilerine yüksek
                        Camel kalitesiyle tam içim keyfi deneyimi sunar.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="swiper-slide">
                  <a href="#" target="_blank">
                    <h3 className="d-none d-md-block">
                      <img
                        src={doYouKnowThisWhiteText2x}
                        className="img-fluid"
                      />
                    </h3>
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(${secondarySliderBg})`
                      }}
                    >
                      <p>
                        Camel sarmalık kıyılmış tütün, tüketicilere yüksek
                        kaliteyi iyi bir fiyat avantajı ile sunar.
                      </p>
                    </div>

                    <h3 className="d-block d-md-none">
                      <img
                        src={doYouKnowThisWhiteText2x}
                        className="img-fluid"
                      />
                    </h3>
                    <div className="main-slider-bg main-slider-mobil">
                      <img src={secondarySliderBg} className="img-fluid" />
                      <p>
                        Camel sarmalık kıyılmış tütün, tüketicilere yüksek
                        kaliteyi iyi bir fiyat avantajı ile sunar.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="swiper-slide grayscale">
                  <a href="#" target="_blank">
                    <h3 className="d-none d-md-block">
                      <img src={doYouKnowThisText2x} className="img-fluid" />
                    </h3>
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(${secondarySliderGrayscaleBg})`
                      }}
                    >
                      <p>
                        Camel sarmalık kıyılmış tütün poşetleri, fermuarlı
                        kapakları sayesinde açıldıktan sonra da içindeki tütünü
                        taze tutar.
                      </p>
                    </div>

                    <h3 className="d-block d-md-none">
                      <img src={doYouKnowThisText2x} className="img-fluid" />
                    </h3>
                    <div className="main-slider-bg main-slider-mobil">
                      <img
                        src={secondarySliderGrayscaleBg}
                        className="img-fluid"
                      />
                      <p>
                        Camel sarmalık kıyılmış tütün poşetleri, fermuarlı
                        kapakları sayesinde açıldıktan sonra da içindeki tütünü
                        taze tutar.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="swiper-slide">
                  <a href="#" target="_blank">
                    <h3 className="d-none d-md-block">
                      <img
                        src={doYouKnowThisWhiteText2x}
                        className="img-fluid"
                      />
                    </h3>
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(${secondarySliderBg})`
                      }}
                    >
                      <p>
                        100 gr’lık Camel sarmalık kıyılmış tütün poşetinin
                        fiyatı 75 TL’dir.
                      </p>
                    </div>

                    <h3 className="d-block d-md-none">
                      <img
                        src={doYouKnowThisWhiteText2x}
                        className="img-fluid"
                      />
                    </h3>
                    <div className="main-slider-bg main-slider-mobil">
                      <img src={secondarySliderBg} className="img-fluid" />
                      <p>
                        100 gr’lık Camel sarmalık kıyılmış tütün poşetinin
                        fiyatı 75 TL’dir.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="swiper-slide grayscale">
                  <a href="#" target="_blank">
                    <h3 className="d-none d-md-block">
                      <img src={doYouKnowThisText2x} className="img-fluid" />
                    </h3>
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(${secondarySliderGrayscaleBg})`
                      }}
                    >
                      <p>
                        Türkiye’deki kayıtlı sarmalık kıyılmış tütün kategorisi
                        yıllar geçtikçe hızla büyüyor.
                      </p>
                    </div>
                    <h3 className="d-block d-md-none">
                      <img src={doYouKnowThisText2x} className="img-fluid" />
                    </h3>
                    <div className="main-slider-bg main-slider-mobil">
                      <img
                        src={secondarySliderGrayscaleBg}
                        className="img-fluid"
                      />
                      <p>
                        Türkiye’deki kayıtlı sarmalık kıyılmış tütün kategorisi
                        yıllar geçtikçe hızla büyüyor.
                      </p>
                    </div>
                  </a>
                </div>
              </div>

              <div className="swiper-pagination"></div>
            </div>
          </div>

          <div className="main-slider-container hide">
            <div className="swiper-container swiper-main-slider swiper-camel-main-slider">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <a href="#" target="_blank">
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(https://content.jtibenimbayim.com/ppfiles/image/14348.jpg)`
                      }}
                    ></div>
                    <div
                      className="main-slider-bg main-slider-mobil"
                      style={{
                        backgroundImage: `url(https://content.jtibenimbayim.com/ppfiles/image/14348.jpg)`
                      }}
                    ></div>
                  </a>
                </div>
                <div className="swiper-slide">
                  <a href="#" target="_blank">
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(https://content.jtibenimbayim.com/ppfiles/image/14353.jpg)`
                      }}
                    ></div>
                    <div
                      className="main-slider-bg main-slider-mobil"
                      style={{
                        backgroundImage: `url(https://content.jtibenimbayim.com/ppfiles/image/14353.jpg)`
                      }}
                    ></div>
                  </a>
                </div>
                <div className="swiper-slide">
                  <a href="#" target="_blank">
                    <div
                      className="main-slider-bg main-slider-desktop"
                      style={{
                        backgroundImage: `url(https://content.jtibenimbayim.com/ppfiles/image/14348.jpg)`
                      }}
                    ></div>
                    <div
                      className="main-slider-bg main-slider-mobil"
                      style={{
                        backgroundImage: `url(https://content.jtibenimbayim.com/ppfiles/image/14348.jpg)`
                      }}
                    ></div>
                  </a>
                </div>
              </div>

              <div className="swiper-pagination"></div>
            </div>
          </div>
        </section>
      </div>

      <div className="camel-page-content">
        <div className="container-fluid no-gutters p-0">
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <div className="wrapper-camel-category">
                <div className="wrapper-camel-category-items">
                  <div className="wrapper-category-img">
                    <img
                      src={camelCommercialBrochure}
                      className="category-img"
                    />
                  </div>
                </div>

                <div className="category-title">
                  <a onClick={() => detailPageOpen("commercialBrochure")}>
                    <img src={camelLeaf} />
                    <p>
                      Camel
                      <br />
                      Yellow 100
                      <br />
                      Ticari Broşür
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="wrapper-camel-category-content">
                <div>
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <h3>
                        Camel Yellow 100 ile ilgili merak ettiğiniz, bilmek
                        istediğiniz her şey için tıklayın!
                      </h3>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="wrapper-button-category-content">
                        <a
                          onClick={() => detailPageOpen("commercialBrochure")}
                          className="camel-page-button"
                        >
                          Görüntüle
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <div className="wrapper-camel-category video-category">
                <div className="wrapper-camel-category-items">
                  <div className="wrapper-category-img">
                    <img src={camelVideos} className="category-img" />
                  </div>

                  <div className="camel-videos-buttons">
                    <div className="swiper-button-prev swiper-video-prev"></div>
                    <div className="swiper-button-next swiper-video-next"></div>
                  </div>
                </div>

                <div className="swiper-container swiper-video-slider">
                  <div className="swiper-wrapper">
                    {camelVideoList && camelVideoList !== null
                      ? camelVideoList.map((item, index) => {
                          return (
                            <div className="swiper-slide" key={index}>
                              <div className="category-title">
                                <a
                                  onClick={() => detailPageOpen("video", index)}
                                >
                                  <img src={camelLeaf} />
                                  <p>
                                    <span>{item.name}</span>
                                  </p>
                                </a>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row camel-bottom-row">
            <div className="col-12 col-md-12">
              <div className="wrapper-camel-category">
                <div className="wrapper-camel-category-items">
                  <div className="wrapper-category-img">
                    <img src={camelProductInfo} className="category-img" />
                  </div>
                </div>

                <div className="row w-100">
                  <div className="col-12 col-md-4">
                    <div className="category-title">
                      <a onClick={() => detailPageOpen("info")}>
                        <img src={camelLeaf} />
                        <p>Özellikler</p>
                      </a>
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="category-title">
                      <a onClick={() => detailPageOpen("priceList")}>
                        <img src={camelLeaf} />
                        <p>Fiyat Listesi</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="camel-page-content-detail"
            className="camel-page-content-detail hide"
          >
            <div className="container-fluid no-gutters p-0 hide" id="priceList">
              <div className="row no-gutters">
                <div className="col-12 col-md-2">
                  <img src={camelLogo} />
                </div>

                <div className="col-12 col-md-10">
                  <div className="d-flex flex-column justify-content-center text-center">
                    <div className="header-detail-page text-center">
                      <h3 className="mb-5 text-center">
                        <img src={priceListText2x} className="img-fluid" />
                      </h3>

                      <a
                        onClick={() => detailPageClose()}
                        className="close-button"
                      >
                        <span className="close-dark"></span>
                      </a>
                    </div>

                    <div className="text-center wrapper-camel-price-list">
                      <a onClick={() => openImgModal()}>
                        <img
                          src={imageShowUrl + priceListImage.data}
                          className="img-fluid"
                        />
                      </a>

                      <Modal
                        open={imgModal}
                        onClose={() => {
                          setImgModal(!imgModal);
                        }}
                        role="dialog"
                        closeOnEsc={true}
                        showCloseIcon={true}
                        closeOnOverlayClick={true}
                        closeIconId="focus-close-icon"
                      >
                        <img
                          src={imageShowUrl + priceListImage.data}
                          className="img-fluid camel-price-item"
                        />
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="container-fluid no-gutters p-0 hide"
              id="commercialBrochure"
            >
              <div className="row no-gutters">
                <div className="col-12 col-md-2">
                  <img src={camelLogo} />
                  <div className="wrapper-commercial-brochure-arrows">
                    <div className="swiper-button-prev swiper-commercial-brochure-prev"></div>
                    <div className="swiper-button-next swiper-commercial-brochure-next"></div>
                  </div>
                </div>

                <div className="col-12 col-md-10">
                  <div className="header-detail-page text-center">
                    <h3 className="mb-5 text-center">
                      <img
                        src={commercialBrochureText2x}
                        className="img-fluid"
                      />
                    </h3>

                    <a
                      onClick={() => detailPageClose()}
                      className="close-button"
                    >
                      <span className="close-dark"></span>
                    </a>
                  </div>

                  <div className="d-flex flex-column justify-content-center text-center">
                    <div>
                      <div className="swiper-container swiper-commercial-brochure">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <a onClick={e => openImgBrochureModal(e, 1)}>
                              <img src={commercial1} />
                            </a>
                          </div>

                          <div className="swiper-slide">
                            <a onClick={e => openImgBrochureModal(e, 2)}>
                              <img src={commercial2} />
                            </a>
                          </div>

                          <div className="swiper-slide">
                            <a onClick={e => openImgBrochureModal(e, 3)}>
                              <img src={commercial3} />
                            </a>
                          </div>

                          <div className="swiper-slide">
                            <a onClick={e => openImgBrochureModal(e, 4)}>
                              <img src={commercial4} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Modal
                    open={imgBrochureModal}
                    onClose={() => {
                      $("body").removeClass("camel-launch-page");
                      setImgBrochureModal(!imgBrochureModal);
                    }}
                    role="dialog"
                    closeOnEsc={true}
                    showCloseIcon={true}
                    closeOnOverlayClick={true}
                    closeIconId="focus-close-icon"
                  >
                    <div className="swiper-container swiper-commercial-brochure-popup">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide text-center">
                          <img src={commercial1} />
                        </div>

                        <div className="swiper-slide text-center">
                          <img src={commercial2} />
                        </div>

                        <div className="swiper-slide text-center">
                          <img src={commercial3} />
                        </div>

                        <div className="swiper-slide text-center">
                          <img src={commercial4} />
                        </div>
                      </div>

                      <div className="swiper-button-prev swiper-commercial-brochure-popup-prev"></div>
                      <div className="swiper-button-next swiper-commercial-brochure-popup-next"></div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="container-fluid no-gutters p-0 hide" id="video">
              <div className="row no-gutters">
                <div className="col-12 col-md-2">
                  <img src={camelLogo} />
                </div>

                <div className="col-12 col-md-10">
                  <div className="header-detail-page">
                    <a
                      onClick={() => detailPageClose()}
                      className="close-button"
                    >
                      <span className="close-dark"></span>
                    </a>
                  </div>

                  <div className="d-flex flex-column justify-content-center text-center">
                    <div>
                      <div className="swiper-container swiper-video-detail-slider">
                        <div className="swiper-wrapper">
                          {camelVideoList && camelVideoList !== null
                            ? camelVideoList.map((item, index) => {
                                return (
                                  <div className="swiper-slide" key={index}>
                                    <h3 className="mb-5 text-center">
                                      {item.imgFont && (
                                        <img
                                          src={imageShowUrl + item.imgFont}
                                          className="img-fluid"
                                        />
                                      )}
                                    </h3>

                                    <div className="wrapper-video">
                                      <video
                                        controls
                                        className="swiper-no-swiping camel-video-player"
                                        width="100%"
                                      >
                                        <source
                                          src={videoShowUrl + item.videoUrl}
                                          type="video/mp4"
                                        />
                                      </video>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>

                        <div className="swiper-button-prev swiper-video-detail-prev"></div>
                        <div className="swiper-button-next swiper-video-detail-next"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid no-gutters p-0 hide" id="info">
              <div className="row no-gutters">
                <div className="col-12 col-md-2">
                  <img src={camelLogo} />
                </div>

                <div className="col-12 col-md-10">
                  <div className="camel-category-item">
                    <div className="header-detail-page text-center">
                      <h3 className="mb-5 text-center">
                        <img src={infoText2x} className="img-fluid" />
                      </h3>

                      <a
                        onClick={() => detailPageClose()}
                        className="close-button"
                      >
                        <span className="close-dark"></span>
                      </a>
                    </div>

                    <div className="wrapper-info-container">
                      <div className="row w-100">
                        <div className="col-12 col-md-4">
                          <img
                            src={camelYellow}
                            alt="Camel Yellow 100"
                            className="img-fluid"
                          />
                        </div>

                        <div className="col-12 col-md-8">
                          <h3>CAMEL YELLOW 100</h3>
                          <hr />
                          <p>
                            · Tam içim keyfi sunar.
                            <br />
                            · Makaronu doldurarak, eğer istenirse sigara kağıdı
                            ile sararak içilebilir.
                            <br />· Kilitli, fermuar ile açılıp kapanan kapak
                            ile içindeki tütünü taze tutar.
                          </p>

                          <div className="info-price">
                            <p>Ağırlık: 100g</p>
                            <p>Fiyat: 75 TL</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Camel;
