import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import AboutBottomIcon from "assets/portal-resource/img/about-bottom-img.jpg";
import KTIcon from "assets/portal-resource/img/KT.png";

const JtiTurkeyAbout = props => {
  return (
    <section className="about-page about-3-page bbd-menu-active">
      <div className="page-container">
        <div className="about-text">
          <h2>JTI Türkiye Hakkında</h2>
          <div className="about-bottom-img">
            <img src={AboutBottomIcon} alt="" />
          </div>
          <p>
            JTI Türkiye, JTI Tütün Ürünleri Pazarlama A.Ş., JTI Tütün Ürünleri
            Sanayi A.Ş. ve JTI Yaprak Tütün A.Ş şirketlerinden oluşmaktadır.
            <br /> <br />
            JTI Türkiye operasyonlarını, Torbalı’daki yönetim ve üretim merkezi,
            Ankara ve İstanbul irtibat büroları ve tüm Türkiye’ye yayılmış
            toplam 10 bölge satış ofisi ile sürdürmektedir. Şirket, Türkiye
            tütün ürünleri pazarının ikinci büyük tütün şirketidir.
            <br /> <br />
            İç pazarda portföyünde 4 marka ve 43 çeşit bulunan JTI Türkiye,
            30'dan fazla ülkeye 12 farklı marka ihraç ederek yıllık ortalama 100
            milyon Amerikan doları tutarında ihracat gerçekleştirmektedir.
            Camel, Winston, Monte Carlo ve LD markalarını hem iç pazar hem de
            ihracat pazarı için üreten JTI Türkiye’nin ihraç ettiği diğer sigara
            markaları: Aspen, Doral, Dorchester, Gold Coast, Hi-Lite, Wave,
            Winchester ve Wings’tir. Türkiye'den tütün ürünleri ihraç eden ilk
            şirket olan JTI Türkiye’nin ihracata başladığı 1994 yılından bu yana
            gerçekleştirdiği toplam ihracat tutarı 2.6 milyar Amerikan Dolarına
            ulaşmıştır. 2018 yılında 2.3 milyar Amerikan Doları vergi ödeyen JTI
            Türkiye, iç pazardan aldığı yıllık 110 milyon Amerikan Doları şark
            tipi tütün ile Türk çiftçisine de destek olmaktadır.
          </p>
        </div>
        <div className="about-img">
          <img src={KTIcon} alt="" />
        </div>
      </div>
    </section>
  );
};
export default JtiTurkeyAbout;
