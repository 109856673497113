import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ChangePassword from "./ChangePassword";
import LoginInfo from "./LoginInfo";
import Security from "./Security";

import { profileAction } from "redux/modules";

import showErrMessage from "../../../hooks/showErrMessage";
import showMessage from "../../../hooks/showMessage";

const ProfileAccountInfo = props => {
  const [employeeFirm, setEmployeeFirm] = useState(
    useSelector(store => store.auth.employeeFirm)
  );
  const dispatch = useDispatch();
  const [sms, setSms] = useState(false);
  const [call, setCall] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  function changeProfileStatus(status) {
    if (status) {
      setEditProfile(true);
    } else {
      setEditProfile(false);
    }
  }

  function changeStatus(type, status) {
    if (type === "message") {
      if (status) {
        setSms(false);
      } else {
        setSms(true);
      }
    } else {
      if (status) {
        setCall(false);
      } else {
        setCall(true);
      }
    }
  }

  function sendStatus() {
    const data = {
      empId: employeeFirm.id,
      iysPartList: [
        {
          type: "message",
          status: sms
        },
        {
          type: "call",
          status: call
        }
      ]
    };

    dispatch(profileAction.saveMessageManagement(data))
      .then(res => {
        setEmployeeFirm(res.data);
        dispatch({
          type: "UPDATE_EMPLOYEE_FIRM",
          data: {
            ...employeeFirm,
            name: res
          }
        });
        setEditProfile(false);
        showMessage("Bilgileriniz başarılı bir şekilde güncellenmiştir.");
      })
      .catch(err => {
        showErrMessage(err.error.response.data.errors[0].message);
      });
  }

  useEffect(() => {
    if (
      employeeFirm &&
      employeeFirm.employee &&
      employeeFirm.employee.smsPermission
    ) {
      setSms(employeeFirm.employee.smsPermission);
    }

    if (
      employeeFirm &&
      employeeFirm.employee &&
      employeeFirm.employee.callPermission
    ) {
      setCall(employeeFirm.employee.callPermission);
    }
    return () => {};
  }, [employeeFirm]);

  return (
    <div
      className="tab-pane fade show active"
      id="accountinfo"
      role="tabpanel"
      aria-labelledby="accountinfo-tab"
    >
      <div className="user-content-item account-info">
        <div className="user-content-text">
          <p style={{ fontSize: "14px" }}>
            Aşağıdaki bilgilerde değişiklik yapabilmek için 0850 532 5 584 (JTI)
            numaralı Çağrı Merkezi’mizi arayabilir, Satış Belgesi numaranızı ve
            bayi kodunuzu kullanarak müşteri temsilcimizle görüşebilirsiniz.
            Şifre değiştirme bölümü aracılığıyla şifrenizi değiştirebilirsiniz.
          </p>
        </div>

        <ul className="nav" role="tablist">
          <li>
            <a
              className="active"
              id="accountinfo-sub-tab"
              data-toggle="tab"
              href="#accountinfo-sub"
              role="tab"
              aria-controls="accountinfo-sub"
              aria-selected="true"
            >
              Hesap Bilgilerim
            </a>
          </li>
          <li className="nav-item">
            <a
              id="changepassword-tab"
              data-toggle="tab"
              href="#changepassword"
              role="tab"
              aria-controls="changepassword"
              aria-selected="false"
            >
              Şifre Değiştirme
            </a>
          </li>

          <li className="nav-item">
            <a
              id="logininfo-tab"
              data-toggle="tab"
              href="#logininfo"
              role="tab"
              aria-controls="logininfo"
              aria-selected="false"
            >
              Giriş Bilgileri
            </a>
          </li>

          <li className="nav-item">
            <a
              id="security-tab"
              data-toggle="tab"
              href="#security"
              role="tab"
              aria-controls="security"
              aria-selected="false"
            >
              Güvenlik
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="accountinfo-sub"
            role="tabpanel"
            aria-labelledby="accountinfo-sub-tab"
          >
            <div className="form-container">
              <form
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <div className="form-row">
                  {employeeFirm && employeeFirm.vendorName && (
                    <div className="form-el">
                      <div className={"mb-1"}>Bayi Tabela Adı</div>
                      <input
                        id="vendorName"
                        type="text"
                        className="form-control"
                        placeholder="Bayi Tabela Adı"
                        disabled={true}
                        value={employeeFirm.vendorName}
                      />
                    </div>
                  )}

                  {employeeFirm &&
                    employeeFirm.employee &&
                    employeeFirm.employee.name && (
                      <div className="form-el">
                        <div className={"mb-1"}>Üye Adı</div>
                        <input
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Üye Adı"
                          disabled={true}
                          value={employeeFirm.employee.name}
                        />
                      </div>
                    )}
                  {/* <div className="form-el">
                    <div className={"mb-1"}>Bayi Kodu</div>
                    <input
                      id="companyCode"
                      type="text"
                      className="form-control"
                      placeholder="Bayi Kodu"
                      disabled={true}
                      value={employeeFirm.vendorERPCode}
                    />
                  </div> */}

                  {employeeFirm &&
                    employeeFirm.employee &&
                    employeeFirm.employee.surname && (
                      <div className="form-el">
                        <div className={"mb-1"}>Üye Soyadı</div>
                        <input
                          id="surname"
                          type="text"
                          className="form-control"
                          placeholder="Üye Soyadı"
                          disabled={true}
                          value={employeeFirm.employee.surname}
                        />
                      </div>
                    )}

                  {employeeFirm && employeeFirm.tapdkFull && (
                    <div className="form-el">
                      <div className={"mb-1"}>Satış Belgesi Numarası</div>
                      <input
                        id="tapdkNumber"
                        type="text"
                        className="form-control"
                        placeholder="Satış Belgesi Numarası"
                        disabled={true}
                        value={employeeFirm.tapdkFull}
                      />
                    </div>
                  )}

                  {employeeFirm && employeeFirm.ercCode && (
                    <div className="form-el">
                      <div className={"mb-1"}>ERC Kodu</div>
                      <input
                        id="username"
                        type="text"
                        className="form-control"
                        placeholder="ERC Kodu"
                        disabled={true}
                        value={employeeFirm.ercCode}
                      />
                    </div>
                  )}

                  {employeeFirm &&
                    employeeFirm.employee &&
                    employeeFirm.employee.mobileNo && (
                      <div className="form-el">
                        <div className={"mb-1"}>Cep Telefonu</div>
                        <input
                          id="mobileno"
                          type="text"
                          className="form-control"
                          placeholder="Cep Telefonu"
                          disabled={true}
                          value={employeeFirm.employee.mobileNo}
                        />
                      </div>
                    )}

                  {/* {employeeFirm && employeeFirm.employee && (
                    <div className="form-el">
                      <label>İleti Yönetimi</label>
                      <div className="form-check">
                        <input
                          disabled={!editProfile}
                          type="checkbox"
                          defaultChecked={employeeFirm.employee.smsPermission}
                          className="form-check-input"
                          id="sms"
                          onChange={() => changeStatus("message", sms)}
                        />
                        <label className="form-check-label" htmlFor="sms">
                          SMS
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          disabled={!editProfile}
                          type="checkbox"
                          defaultChecked={employeeFirm.employee.callPermission}
                          className="form-check-input"
                          id="call"
                          onChange={() => changeStatus("call", call)}
                        />
                        <label className="form-check-label" htmlFor="call">
                          Telefon
                        </label>
                      </div>
                    </div>
                  )} */}

                  <div className="pl-md-4 pr-md-4 pl-4 pr-4 w-100 d-none">
                    <p className="profile-account-info-desc">
                      Benim Bayim'den gelecek iletileri hangi kanaldan almayı
                      tercih ettiğinizi buradan belirleyebilirsiniz.
                    </p>
                  </div>

                  <div className="m-0 mt-3 pl-md-4 pr-md-4 w-100 submit d-block d-md-flex">
                    {/* <button
                      className="btn btn-warning"
                      onClick={() => changeProfileStatus(true)}
                    >
                      GÜNCELLE
                    </button> */}

                    <button
                      type="button"
                      className="ea-btn bg-green d-block"
                      onClick={() => sendStatus()}
                    >
                      KAYDET
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ChangePassword />
          <LoginInfo />
          <Security />
        </div>
      </div>
    </div>
  );
};

export default ProfileAccountInfo;
