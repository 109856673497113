import * as disclaimerTypes from "./disclaimer.type";

const initialState = {
  disclaimerList: null,
  ali: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_PENDING}`:
    case `${disclaimerTypes.SAVE_DISCLAIMER_CHECK_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        disclaimerList: payload.data
      };

    case `${disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${disclaimerTypes.SAVE_DISCLAIMER_CHECK_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${disclaimerTypes.SAVE_DISCLAIMER_CHECK_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default reducer;
