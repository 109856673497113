/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { imageShowUrl } from "../../config/api.config";
import { API } from "services/http.service";
import { useDispatch, useSelector } from "react-redux";
import { homeAction } from "redux/modules";

import { IoArrowForward } from "react-icons/io5";

import Camel from "assets/images/new-design/price-list-icons/camel/camel.png";
import Winston from "assets/images/new-design/price-list-icons/winston/winston.png";
import Ld from "assets/images/new-design/price-list-icons/ld/ld.png";
import MonteCarlo from "assets/images/new-design/price-list-icons/monte-carlo/monte-carlo.png";

const HomePrice = () => {
  const [priceListImage, setPriceListImage] = useState("");
  const homePriceList = useSelector(store => store.home.homePriceList);
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncPriceListImageData = async () => {
      const response = await API.get("price/Fiyat Listesi");
      setPriceListImage(response);
    };
    asyncPriceListImageData();
  }, [priceListImage === null]);

  useEffect(() => {
    dispatch(homeAction.getHomePriceList());
  }, []);

  return (
    <section className="mt-6">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-4 rounded-xl rounded-bl-[100px] bg-slate-100 px-12 py-20">
          <div className="col-span-3 pb-5 md:pb-0 md:col-span-1">
            <h6 className="text-slate text-[48px] font-semibold leading-[56px] text-slate-700">
              Fiyat <br /> Listesi
            </h6>
            <a
              data-fancybox="gallery"
              className="mt-12 inline-flex items-center gap-2 rounded-full border-2 border-slate-700 bg-white px-6 py-3 text-sm text-slate-700"
              href={priceListImage ? imageShowUrl + priceListImage.data : null}
            >
              Detaylı Bilgi <IoArrowForward className="h-4 w-4" />
            </a>
          </div>

          <div className="col-span-3 md:col-span-3">
            <div className=" grid grid-cols-1 md:grid-cols-3 gap-y-6">
              {homePriceList && homePriceList.length > 0 ? (
                homePriceList.map(item => {
                  return (
                    <div
                      key={item.id}
                      className="flex gap-4 border-b-2 border-white border-opacity-50 pb-6"
                    >
                      <div className="rounded-xl h-12 w-12 shadow-2xl flex justify-center align-items-center">
                        {item?.name.search("CAMEL") > -1 && (
                          <img
                            src={Camel}
                            alt=""
                            className="shrink-0 object-cover"
                          />
                        )}

                        {item.name.search("WINSTON") > -1 && (
                          <img
                            src={Winston}
                            alt=""
                            className="shrink-0 object-cover"
                          />
                        )}

                        {item.name.search("LD") > -1 && (
                          <img
                            src={Ld}
                            alt=""
                            className="shrink-0 object-cover"
                          />
                        )}

                        {item.name.search("MONTE CARLO") > -1 && (
                          <img
                            src={MonteCarlo}
                            alt=""
                            className="shrink-0 object-cover"
                          />
                        )}
                      </div>

                      <div>
                        <h6 className="text-slate-400">{item.name}</h6>
                        <span className="text-2xl font-semibold text-slate-700">
                          {item.price} ₺
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>Lütfen Fiyat Bilgisi Giriniz</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomePrice;
