import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { useHistory, Redirect } from "react-router-dom";

import StarImage3 from "../assets/images/stars/star-3.png";

const CompetitionRule = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  var disclaimer = null;
  const isLoading = useSelector(store => store.game.isLoading);
  const [isChecked, setIsChecked] = useState(false);
  const [disclaimerList, setDisclaimerList] = useState([]);
  let history = useHistory();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    dispatch(
      gameAction.getCompetitionDisclaimerByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    ).then(response => {
      setDisclaimerList(response.data);

      setLoaded(true);
    });
    return () => {};
  }, []);

  function handleCheck() {
    setIsChecked(!isChecked);
  }

  function saveCompetitionDisclaimer(
    competitionDisclaimer,
    employeeFirmId,
    firmId
  ) {
    dispatch(
      gameAction.saveCompetitionDisclaimer(
        competitionDisclaimer,
        employeeFirmId,
        firmId
      )
    ).then(() => {
      history.push("/competition");
    });
  }

  return (
    <div
      className="wrapper-scroller m-0 wrapper-question-item"
      style={{
        color:
          disclaimerList && disclaimerList[0] && disclaimerList[0].primaryColor
            ? disclaimerList[0].primaryColor
            : "#FFF"
      }}
    >
      {/* <GlobalLoader isLoading={!loaded} /> */}
      {disclaimerList ? (
        disclaimerList.length > 0 ? (
          disclaimerList.map((item, index) => {
            return (
              <div key={index}>
                <div style={{ color: item.primaryColor }}>
                  <h1 style={{ fontWeight: "bold", color: item.primaryColor }}>
                    Yarışma Kuralları
                  </h1>
                  <div
                    className={
                      "disclaimer-text " +
                      (item.checkedDisclaimerCompetition ? "" : "with-form")
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                  {item.checkedDisclaimerCompetition ? null : (
                    <div className="row mt-3">
                      <div className="col-lg-6 mt-2">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="compotition-rule-checkbox"
                            onChange={() => {
                              handleCheck();
                            }}
                            defaultChecked={isChecked}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="compotition-rule-checkbox"
                          >
                            Şartlar ve Koşulları okudum onaylıyorum.
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-2">
                        <a
                          className={
                            "btn pl-5 pr-5 " + (!isChecked ? "disabled" : "")
                          }
                          id="compotition-rule-check-button"
                          style={{
                            borderRadius: "32px",
                            backgroundColor:
                              disclaimerList[0]?.buttonList[0]?.buttonColor
                          }}
                          onClick={() => {
                            saveCompetitionDisclaimer(
                              item.topCompetitionId,
                              employeeFirm.id,
                              employeeFirm.firm.id
                            );
                          }}
                        >
                          ONAYLIYORUM
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div> Yarışmaya ait Disclaimer metni düzenlenmemiştir.</div>
        )
      ) : null}
    </div>
  );
};
export default CompetitionRule;
