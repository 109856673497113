/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import { useHistory, Redirect, Link } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import Countdown from "react-countdown";

import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import BannerHome from "assets/images/new-design/banners/banner-home-competition.jpg";
import BannerHomeMobile from "assets/images/new-design/banners/banner-home-competition-mobile.jpg";
import BannerHomeStarted from "assets/images/new-design/banners/banner-home-competition-started.jpg";
import BannerHomeStartedMobile from "assets/images/new-design/banners/banner-home-competition-started-mobile.jpg";

import "./HomeCompetition.scss";

const HomeCompetition = () => {
  const dispatch = useDispatch();
  const [isActiveComp, setIsActiveComp] = useState(false);
  const [specialDate, setSpecialDate] = useState(null);
  const [compDate, setCompDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(gameAction.getCompetitionDate()).then(response => {
      setIsLoading(false);

      if (response.data.activeComp) {
        setIsActiveComp(true);
      } else {
        setIsActiveComp(false);
      }

      setSpecialDate(response.data);
    });
    return () => {};
  }, []);

  const Complete = () => (
    <div className="text-center relative">
      <img
        src={BannerHomeStarted}
        className="rounded-xl hidden md:block"
        alt=""
      />
      <img
        src={BannerHomeStartedMobile}
        className="rounded-xl block md:hidden"
        alt=""
      />

      <div className="home-competition-date home-competition-date-started">
        <Link to="/competition">Başla</Link>
      </div>
    </div>
  );

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Complete />;
    } else {
      // Render a countdown
      return (
        <>
          {isActiveComp === false ? (
            <div className="text-center relative">
              <img
                src={BannerHome}
                className="rounded-xl hidden md:block"
                alt=""
              />
              <img
                src={BannerHomeMobile}
                className="rounded-xl block md:hidden"
                alt=""
              />

              <div className="home-competition-date">
                <div>
                  <span>
                    {hours + days * 24 < 10
                      ? `0${hours + days * 24}`
                      : hours + days * 24}
                  </span>
                  <div className="hc-hour">SA</div>
                </div>

                <div>
                  <span>{minutes < 10 ? `0${minutes}` : minutes}</span>
                  <div className="hc-min">DAK</div>
                </div>

                <div>
                  <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
                  <div className="hc-sec">SN</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center relative">
              <img
                src={BannerHomeStarted}
                className="rounded-xl hidden md:block"
                alt=""
              />
              <img
                src={BannerHomeStartedMobile}
                className="rounded-xl block md:hidden"
                alt=""
              />

              <div className="home-competition-date home-competition-date-started">
                <Link to="/competition">Başla</Link>
              </div>
            </div>
          )}
        </>
      );
    }
  };

  if (specialDate && specialDate.now && specialDate.compDate) {
    const time1 = new Date(specialDate.now);
    const time2 = new Date(specialDate.compDate);

    return (
      <section className="mt-6">
        <GlobalLoader isLoading={isLoading} />
        <div className="container mx-auto">
          <div className="grid grid-cols-1 overflow-hidden rounded-xl md:min-h-[200px]">
            <div>
              <Countdown date={time2} renderer={renderer} />
            </div>
          </div>
        </div>
      </section>
    );
  }
};
export default HomeCompetition;
