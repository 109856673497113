import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { profileAction } from "redux/modules";
import { brandStyleAction } from "redux/modules";
import { Link } from "react-router-dom";
import { brandAction } from "redux/modules";

import JtiLogo from "../../../assets/images/new-design/logo-jti.svg";

const UserportalFooter = props => {
  const dispatch = useDispatch();
  const brandList = useSelector(store => store.brand.brandList);

  const employeeFirm = useSelector(store => store.auth.employeeFirm);

  useEffect(() => {
    dispatch(brandAction.getBrandsByFirmId(employeeFirm.firm.id));
    return () => {};
  }, []);

  const actives = [];
  /* function setOrigin() {
    dispatch(profileAction.setProfileOrigin("a"));
  }  */

  useEffect(() => {
    if (brandList != null) {
      brandList.slice(0, -1);
    }
    return () => {};
  }, []);

  function onClick(brandId, index2) {
    dispatch(brandStyleAction.getBrandStyleByBrandId(brandId));
    dispatch(brandAction.setFooterFlag(true));

    brandList.map(item => {
      index2 === item.imageOrder ? actives.push(true) : actives.push(false);
    });
  }

  function goToAdress(key, index2) {
    [0, 1, 2, 3, 4, 5].map((item, index) => {
      actives.push(false);
    });

    actives.map((item, index) => {
      index === index2 ? (actives[index] = true) : (actives[index] = false);
    });

    dispatch(profileAction.setActives(actives));
    dispatch(profileAction.setProfileContent(key));
  }

  return (
    <footer className="py-28 text-sm bg-white">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-6">
          <div className="col-span-2">
            <img src={JtiLogo} alt="Jti Logo" />
            <p className="mt-6 font-light text-slate-500">
              Japan Tobacco Şirketler Grubu Üyesi
            </p>
            <p className="mt-1 text-xs font-light text-slate-400">
              2023 © Tüm Hakları Saklıdır.
            </p>
            <p className="mt-6 text-base font-semibold text-primary-500">
              0850 532 55 84
            </p>
          </div>
          <div className="col-span-1 font-light">
            <h6 className="font-normal text-slate-700 pt-3">Benim Bayim</h6>
            <div className="mt-6 flex flex-col gap-4 text-slate-500">
              <Link to="/userportal/brand">Markalar</Link>
              <Link to="/userportal/catalog">Dükkan</Link>
              <Link to="/userportal/competitions">Yarışmalar</Link>
            </div>
          </div>
          <div className="col-span-1 font-light">
            <h6 className="font-normal text-slate-700 pt-3">İçerikler</h6>
            <div className="mt-6 flex flex-col gap-4 text-slate-500">
              <Link to="/userportal/magazine">Dergi</Link>
              <Link to="/userportal/tvArea">TV</Link>
              <Link to="/userportal/ourArena">Bizim Meydan</Link>
              <Link to="/userportal/news">Haberler</Link>
            </div>
          </div>
          <div className="col-span-1 font-light">
            <h6 className="font-normal text-slate-700 pt-3">Yardım</h6>
            <div className="mt-6 flex flex-col gap-4 text-slate-500">
              <Link to={{ pathname: "/userportal/faq" }}>
                Sıkça Sorulan Sorular
              </Link>
              <Link to={{ pathname: "/userportal/tac" }}>Şartlar Koşullar</Link>
              <Link to={{ pathname: "/userportal/score" }}>
                Nasıl Puan Kazanırım
              </Link>
              <Link to={{ pathname: "/userportal/userGuide" }}>
                Kullanım Kılavuzu
              </Link>
              <Link to={{ pathname: "/userportal/announcement" }}>
                Duyurular
              </Link>
            </div>
          </div>
          <div className="col-span-1 font-light">
            <h6 className="font-normal text-slate-700 pt-3">JTI</h6>
            <div className="mt-6 flex flex-col gap-4 text-slate-500">
              <Link to="/userportal/about">JTI Hakkında</Link>
              <Link to="/userportal/camel">Camel Özel Sayfa</Link>
              <Link
                to={{ pathname: "/userportal/profile", from: "footer" }}
                onClick={() => {
                  goToAdress("message", 2);
                }}
              >
                Bize Ulaşın
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default UserportalFooter;
