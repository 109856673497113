import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";
import { ourArenaAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import Picker from "emoji-picker-react";
import Pagination from "react-js-pagination";

import { IoArrowForward } from "react-icons/io5";

const PastSubjets = () => {
  // Emoji Picker
  const [initialMessageText, setInitialMessageText] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState(null);
  //const [message, setMessageForm] = useState("");
  const ref = useRef(null);
  const onEmojiClick = (event, emojiObject) => {
    const cursor = ref.current.selectionEnd;
    const text =
      initialMessageText.slice(0, cursor) +
      initialMessageText.slice(cursor) +
      emojiObject.emoji;
    setInitialMessageText(text);
  };
  //const [activePage, setActivePage] = useState(1);
  const totalPages = useSelector(store => store.ourArena.totalPages);

  // PastSubjects
  const dispatch = useDispatch();
  const [keyDown, setKeyDown] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjectId2, setSubjectId2] = useState(null);
  const [activeSubject, setActiveSubject] = useState(null);
  const [remainingCharCount, setRemainingCharCount] = useState(500);
  //const [initialMessageText, setInitialMessageText] = useState("");
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  let subjectPastList = useSelector(store => store.ourArena.subjectPastList);
  const forumMessageList = useSelector(
    store => store.ourArena.forumMessageList
  );
  const toggleId = "#answers";
  const toggleItem = "answers";

  useEffect(() => {
    dispatch(ourArenaAction.getPastSubject(employeeFirm.id));
    return () => {};
  }, []);

  useEffect(() => {
    if (subjectPastList && subjectPastList.length > 0) {
      subjectPastList.forEach((item, index) => {
        item.activePage = 1;
      });
    }
    return () => {};
  }, [subjectPastList]);

  function wordCount(e) {
    var currentText = e.target.value;
    setInitialMessageText(currentText);
    var characterCount = currentText.length;
    setRemainingCharCount(500 - characterCount);
  }

  function handlePageChange(id, activePage) {
    //setActivePage( thisPageNumber );
    dispatch(
      ourArenaAction.getSelectedPastSubject(
        subjectId2,
        employeeFirm.id,
        activePage - 1
      )
    );

    if (subjectPastList && subjectPastList.length > 0) {
      subjectPastList.forEach((item, index) => {
        if (id === item.id) {
          item.activePage = activePage;
        }
      });
    }
  }

  function checkRequiredFields(e) {
    if (initialMessageText.length <= 0) {
      showErrMessage("Mesaj Giriniz");
      e.preventDefault();
      setInitialMessageText("");
      setRemainingCharCount(500);
    } else if (!subjectPastList || subjectPastList.length < 1) {
      showErrMessage("Bu haftanın bir konusu henüz yoktur.");
      e.preventDefault();
      setInitialMessageText("");
      setRemainingCharCount(500);
    } else {
      if (selectedSubject.subjectId)
        saveInitialMessage(initialMessageText, selectedSubject.subjectId);
      else saveInitialMessage(initialMessageText, selectedSubject.id);

      setInitialMessageText("");
      setRemainingCharCount(500);
    }
  }

  function showComments(subjectId, e, activePage) {
    dispatch(
      ourArenaAction.getSelectedPastSubject(
        subjectId,
        employeeFirm.id,
        activePage - 1
      )
    );
    setSubjectId2(subjectId);
    setActiveSubject(e);
  }

  function saveInitialMessage(message, subjectId) {
    dispatch(
      ourArenaAction.saveInitialMessage(message, subjectId, employeeFirm.id)
    )
      .then(e => {
        showMessage("Yorumunuz onaylandıktan sonra görüntülenecektir.");
        setInitialMessageText("");
        setRemainingCharCount(500);
      })
      .catch(err => {
        showErrMessage("Mesajınız gönderilemedi");
      });
  }
  async function likePost(forumLikeId, messageId, subjectId, activePage) {
    await dispatch(
      ourArenaAction.saveLike(
        forumLikeId,
        messageId,
        employeeFirm.id,
        subjectId
      )
    );
    dispatch(ourArenaAction.getPastSubject(employeeFirm.id));
    dispatch(
      ourArenaAction.getSelectedPastSubject(
        subjectId,
        employeeFirm.id,
        activePage - 1
      )
    );
  }

  function commentPost(senderName) {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setInitialMessageText("@" + senderName);
  }

  function toggleShowHide(e) {
    e.preventDefault();

    document.getElementById("pickerWrapper").classList.toggle("hide");
  }

  function paginationClicked(e) {
    window.target = e.target;
    setTimeout(function() {
      var answerCollapseItems = window.target
        .closest(".topic-box-content")
        .querySelectorAll(".answers.collapse");

      for (let i = 0; i < answerCollapseItems.length; i++) {
        answerCollapseItems[i].classList.add("show");
      }
    }, 500);
  }

  // UseRef
  const pickerWrapperRef = useRef(null);

  const handleClick = event => {
    if (
      pickerWrapperRef.current &&
      !pickerWrapperRef.current.contains(event.target) &&
      !event.target.closest(".picker-wrapper")
    ) {
      const pickerWrapperElements = document.querySelectorAll(
        ".picker-wrapper .pickerWrapper"
      );
      pickerWrapperElements.forEach(element => {
        element.classList.add("hide");
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div>
      {subjectPastList && subjectPastList.length > 0 && selectedSubject ? (
        <div className="message">
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className={`textarea ${keyDown ? "active" : ""}`}>
              {subjectPastList && subjectPastList.length > 0 ? (
                <div className="position-relative">
                  <div
                    className="position-absolute text-right picker-wrapper"
                    style={{ right: "0" }}
                    ref={pickerWrapperRef}
                  >
                    <div
                      className="emoji-button"
                      onClick={e => toggleShowHide(e)}
                    >
                      😃
                    </div>
                    <div
                      id="pickerWrapper"
                      className="hide pickerWrapper"
                      style={{
                        position: "relative",
                        zIndex: "9999",
                        userSelect: "none"
                      }}
                    >
                      <Picker onEmojiClick={onEmojiClick} />
                    </div>
                  </div>
                  <textarea
                    id="benim-meydan-textarea"
                    name="mesaj"
                    placeholder="Yeni Mesaj"
                    maxLength="500"
                    ref={ref}
                    value={initialMessageText}
                    onKeyPress={e => {
                      if (e.key !== "Enter") return;
                    }}
                    onChange={e => setInitialMessageText(e.target.value)}
                  />
                </div>
              ) : (
                <textarea
                  id="benim-meydan-textarea"
                  name="mesaj"
                  placeholder="Yeni Mesaj"
                  maxLength="500"
                  disabled={true}
                ></textarea>
              )}
            </div>
            <div className="actions d-flex align-items-center">
              <div className="count">
                <span>{remainingCharCount} </span>
                karakter kaldı
              </div>
              {subjectPastList &&
              subjectPastList.length > 0 &&
              selectedSubject !== null ? (
                <div className="ml-auto">
                  <button
                    type="submit"
                    onClick={checkRequiredFields}
                    className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                  >
                    Gönder <IoArrowForward className="h-5 w-5 text-white" />
                  </button>
                </div>
              ) : (
                <div className="ml-auto">
                  <button
                    type="submit"
                    onClick={checkRequiredFields}
                    className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                    disabled={true}
                  >
                    Gönder <IoArrowForward className="h-5 w-5 text-white" />
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      ) : null}
      {subjectPastList && subjectPastList.length > 0 ? (
        subjectPastList.map((item, index) => {
          return (
            <div key={index} className="topic-box">
              <div className="topic-box-content">
                <div
                  className="topic"
                  onClick={e => {
                    showComments(item.id, e, item.activePage);
                  }}
                >
                  <div
                    className="d-flex navbar-toggler"
                    data-toggle="collapse"
                    data-target={toggleId.concat(index.toString())}
                    aria-controls={toggleItem.concat(index.toString())}
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <div className="down-icon">
                      <div className="icon">
                        <div></div>
                      </div>
                    </div>
                    <div className="question flex-grow-1">
                      <p>{item.subject}</p>
                      <div className="sub d-flex">
                        {/* <div>Kimden : {item.senderName}</div> */}
                        <div className="ml-auto">
                          {item.dateBegin.slice(0, 10)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats d-flex">
                    <div className="likes mr-2">
                      <div className="stat-box d-flex align-items-center">
                        <div
                          className={`${
                            item.forumMessageLikeStatus === 1
                              ? "icon heart-red"
                              : "icon heart"
                          }`}
                          onClick={() => {
                            likePost(null, null, item.id, item.activePage);
                          }}
                        ></div>
                        <div className="stat">{item.countLike}</div>
                      </div>
                    </div>
                    <div className="comments">
                      <div className="stat-box d-flex align-items-center">
                        <div
                          className="icon comment"
                          onClick={() => {
                            setSelectedSubject(item);
                            window.scroll({
                              top: 0,
                              left: 0,
                              behavior: "smooth"
                            });
                          }}
                        ></div>
                        <div className="stat">{item.countComment}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {forumMessageList && forumMessageList.length > 0
                  ? forumMessageList.map((item2, index2) => {
                      return (
                        <div
                          key={index2}
                          className="answers collapse"
                          id={toggleItem.concat(index.toString())}
                        >
                          <div className="answer d-flex">
                            <div className="message">
                              <div className="text">
                                {forumMessageList[index2].message}
                              </div>
                              <div className="from mt-3">
                                Kimden: {forumMessageList[index2].senderName}
                              </div>
                            </div>
                            <div className="stats ml-auto">
                              <div className="d-flex">
                                <div className="stat-box d-flex align-items-center">
                                  {/* <IoHeartOutline className="w-4 h-4" /> */}
                                  <div
                                    className={`${
                                      forumMessageList[index2]
                                        .forumMessageLikeStatus === 1
                                        ? "icon heart-red"
                                        : "icon heart"
                                    }`}
                                    onClick={() => {
                                      likePost(
                                        forumMessageList[index2]
                                          .forumMessageLikeId,
                                        forumMessageList[index2].id,
                                        forumMessageList[index2].subjectId
                                      );
                                    }}
                                  ></div>
                                  <div className="stat">
                                    {forumMessageList[index2].countLike}
                                  </div>
                                </div>
                                <div className="stat-box d-flex align-items-center ml-2">
                                  <div
                                    className="icon comment"
                                    onClick={() => {
                                      setSelectedSubject(
                                        forumMessageList[index2]
                                      );
                                      commentPost(
                                        forumMessageList[index2].senderName
                                      );
                                    }}
                                  ></div>
                                  <div className="stat">
                                    {forumMessageList[index2].countComment}
                                  </div>
                                </div>
                              </div>
                              <div className="date text-right">
                                {forumMessageList[index2].dateBegin.slice(
                                  0,
                                  10
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                {forumMessageList &&
                forumMessageList.length > 0 &&
                subjectId2 !== null ? (
                  <div onClick={e => paginationClicked(e)}>
                    <Pagination
                      id={activeSubject}
                      activePage={item.activePage}
                      totalItemsCount={10 * totalPages}
                      itemsCountPerPage={10}
                      pageRangeDisplayed={
                        totalPages !== null && totalPages > 5 ? 5 : totalPages
                      }
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={handlePageChange.bind(this, item.id)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          );
        })
      ) : (
        <p>Gösterilecek bir konu yoktur.</p>
      )}
    </div>
  );
};
export default PastSubjets;
