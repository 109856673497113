import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OurArenaContent from "./OurArenaContent";
import OurArenaLeftMenu from "./OurArenaLeftMenu";

import { IoCloseOutline } from "react-icons/io5";
import { FaInfoCircle } from "react-icons/fa";

import "./OurArena.scss";

// import MothersDayBanner from "assets/images/mothers-day/banner.jpg";
// import MothersDayBannerMobile from "assets/images/mothers-day/banner-mobile.jpg";

const OurArena = props => {
  const currentArenaContent = useSelector(
    store => store.ourArena.currentArenaContent
  );
  const SelectedContentDisplay = OurArenaContent(currentArenaContent);
  const [showTerms, setShowTerms] = useState(false);

  return (
    <section id="bizim-meydan">
      {/* <div className="banner-special-days text-center">
        <picture>
          <source
            srcset={MothersDayBanner}
            media="(min-width: 768px)"
            className="img-fluid"
          />
          <img
            src={MothersDayBannerMobile}
            className="img-fluid"
            alt="Anneler Günü"
          />
        </picture>
      </div> */}

      <section className="glaire bg-[#16435E] flex flex-col justify-center items-center min-h-[146px]">
        <div className="container mx-auto px-4">
          <h2 className="text-center text-2xl font-semibold leading-none text-white md:text-[32px]">
            Bizim Meydan
          </h2>
        </div>
      </section>

      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row w-100">
          {/* Left Menu */}
          <OurArenaLeftMenu />

          {/* Right Content */}
          <div className="basis-full md:basis-9/12 pl-10">
            <div className="bg-slate-200 h-100 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8">
              <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                <FaInfoCircle className="h-6 w-6" /> Bizim Meydan’a Hoş geldiniz
              </h3>

              <p className="mb-6 tracking-wide">
                Bu bölümde her hafta farklı bir konuda fikir ve yorumlarınızı
                paylaşabilirsiniz.{" "}
                <span className="text-slate-700">Bizim Meydan</span>'da tüm
                yorumlar yayınlanmadan önce gözden geçirilir. Bu nedenle
                yorumlarınız{" "}
                <span className="text-slate-700">anında değil</span>, yapılacak{" "}
                <span className="text-slate-700">
                  uygunluk değerlendirilmesinin
                </span>{" "}
                ardından yayınlanır.
              </p>

              <a
                href="javascript:void(0)"
                onClick={() => {
                  setShowTerms(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                Bizim Meydan Kullanım Koşulları'nı incelemek için tıklayınız.
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-100 mt-12">
          <div className="basis-full md:basis-3/12"></div>
          <div className="basis-full md:basis-9/12 pl-10">
            <SelectedContentDisplay />
          </div>
        </div>
      </div>

      {showTerms && (
        <div className="fixed inset-0 z-[9999] bg-slate-900/75 backdrop-blur-sm">
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 sm:p-4">
              <div className="relative w-[1300px] max-w-full rounded-xl bg-white shadow-md">
                <button
                  className="absolute right-4 top-4 md:right-6 md:top-6"
                  onClick={() => {
                    setShowTerms(false);
                    document.body.style.overflow = "visible";
                  }}
                >
                  <IoCloseOutline className=" h-8 w-8 text-slate-500" />
                </button>

                <div className="!grid !grid-cols-12 items-center !gap-6 !p-4 max-lg:!pt-16 md:!gap-12 lg:!gap-16 lg:!p-16">
                  <div className="col-span-12 md:col-span-12 lg:col-span-12">
                    <div>
                      <h1 className="!mb-8 text-center font-semibold text-slate-900 text-3xl">
                        Kullanım Koşulları
                      </h1>
                      <p className="!mb-4">
                        Bizim Meydan’ı siz değerli bayilerimizin birikim,
                        tecrübe ve görüşlerini keyifli bir platformda
                        paylaşabilmesi için tasarladık. Platformun kullanım
                        koşulları konusunda göstereceğiniz hassasiyet için
                        şimdiden teşekkür ederiz.
                      </p>

                      <p className="!mb-4">
                        Bizim Meydan’da tüm yorumlar yayınlanmadan önce gözden
                        geçirilir. Bu nedenle yorumlarınız paylaşıldığı anda
                        değil, yapılacak kısa bir değerlendirmenin ardından
                        yayınlanır. Sayfa yöneticileri, uygun olmayan içerik,
                        yorum ve paylaşımları yayına almaz ve gerektiğinde
                        uygunsuz paylaşımlarda bulunan kullanıcıların
                        engellenmesi için gerekli önlemleri alabilir.
                      </p>

                      <h3 className="!mb-4 text-slate-700 font-semibold">
                        Bizim Meydan’da paylaşılması uygun olmayan içerikler:
                      </h3>

                      <p className="!mb-0">
                        • Konu ve sayfa içeriğiyle ilgisiz içerik ve yorumlar
                      </p>
                      <p className="!mb-0">
                        • Diğer takipçilere zarar verici veya onur kırıcı
                        paylaşımlar
                      </p>

                      <p className="!mb-0">
                        • Herhangi bir 3. şahıs veya kurumun haklarını ihlal
                        eden her türlü paylaşım
                      </p>
                      <p className="!mb-0">
                        • Küfür veya hakaret içeren paylaşımlar
                      </p>
                      <p className="!mb-0">
                        • Her türlü bağlantı adresi paylaşımları (linkler)
                      </p>
                      <p className="!mb-0">
                        • Toplum ahlakına aykırı veya topluma zarar verici
                        paylaşımlar
                      </p>
                      <p className="!mb-0">
                        • Siyasi, dini, cinsellik ve şiddet içerikli paylaşımlar
                      </p>
                      <p className="!mb-0">
                        • Her türlü ayrımcılık veya nefret söylemi taşıyan
                        paylaşımlar
                      </p>
                      <p className="!mb-0">
                        • Yasalara aykırı yorumlar veya bağlantı adresleri
                        (linkler)
                      </p>

                      <p className="!mb-0">
                        • Yasa dışı girişimleri destekleyen ve teşvik eden her
                        türlü yorumlar veya bağlantı adresleri
                      </p>
                      <p className="!mb-0">
                        • Yalan haber veya beyan içeren paylaşımlar
                      </p>
                      <p className="!mb-0">
                        • Bir başka marka veya kurum adına reklam veya kötüleme
                        amaçlı paylaşımlar
                      </p>
                      <p className="!mb-0">
                        • JTI, JTI’ın ortakları, çalışanları, rakipleri,
                        forumdaki diğer kullanıcılar veya diğer markalar
                        hakkında asılsız iddia niteliğinde paylaşımlar{" "}
                      </p>
                      <p className="!mb-4">
                        • Genel kullanımda olmayan deyimler, ifadeler ve yabancı
                        sözcükler içeren paylaşımlar
                      </p>

                      <h3 className="!mb-4 text-slate-700 font-semibold">
                        Dikkat:
                      </h3>
                      <p className="!mb-4">
                        Lütfen yorum ve içeriklerinizi yazarken büyük harf
                        kullanmayın.
                      </p>

                      <h3 className="!mb-4 text-slate-700 font-semibold">
                        Uyarı:
                      </h3>
                      <p className="!mb-4">
                        • Kullanıcılar, Bizim Meydan’da paylaştıkları içerik ve
                        yorumların tek sorumlusudur. Kullanıcılar tarafından
                        yapılmış yorumlar Benim Bayim sayfa yöneticileri
                        tarafından incelenmiş olsa dahi kullanıcı, paylaştığı
                        yorum veya içerikten dolayı diğer kullanıcılara, JTI’a
                        veya 3. kişilere karşı sorumludur.
                      </p>

                      <p className="!mb-4">
                        • Sadece sayfa yöneticileri, güncel haberleri paylaşmak
                        adına 3. şahıs ve kurumlara ait internet sitelerinden
                        paylaşımlar gerçekleştirebilir. Bu sitelere ait internet
                        bağlantılarına (linklere) tıklamak, sizleri Benim Bayim
                        tarafından hazırlanmamış içerik ve sayfalara
                        yönlendirecektir. Bu sitelerde yer alan içerik ve
                        yazılardan Benim Bayim sorumlu değildir ve Benim Bayim
                        ’in görüşünü yansıttığı sonucu çıkarılamaz.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default OurArena;
