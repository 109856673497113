import React, { useEffect, useState } from "react";
import HomeSlider from "./HomeSlider";
import HomeBrands from "./HomeBrands";
import HomePrice from "./HomePrice";
import HomeSurvey from "./HomeSurvey";
import HomeGames from "./HomeGames";
import HomeCompetition from "./HomeCompetition";
import HomeBanner from "./HomeBanner";
import HomeFocus from "./HomeFocus";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "redux/modules";
import { useHistory } from "react-router";

const Home = props => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loginedUser = useSelector(store => store.user.loginedUser);
  const disclaimerList = useSelector(store => store.disclaimer.disclaimerList);
  const logined = props.location.logined;

  useEffect(() => {
    let abortController = new AbortController();

    const reloadControl = localStorage.getItem("reControl");

    if (
      localStorage.getItem("saveDisclaimer") === null &&
      localStorage.getItem("disclamerForce") == "true" &&
      disclaimerList !== null &&
      disclaimerList[0].checkedDisclaimerInTheWeek === false
    ) {
      localStorage.removeItem("saveDisclaimer");
      history.push("/disclaimer");
    }

    if (localStorage.getItem("iysForce") == "true") {
      localStorage.setItem("iysForce", "false");
      history.push("/iys");
    }

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      dispatch(userAction.setLoginedUser("logined"));
    }

    setTimeout(() => {
      window.mainPageSlider = new Swiper(".swiper-main-slider", {
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function(index, className) {
            // return '<span class="' + className + '">' + (index + 1) + "</span>";
            return '<span class="' + className + '">' + "</span>";
          }
        },
        loop: true
      });
    }, 300);

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      {/* main */}
      <main className="bg-slate-50 pb-20">
        {/* slider */}
        <HomeSlider />

        {/* brands */}
        <HomeBrands />

        {/* items */}
        <HomePrice />

        <HomeCompetition />

        {/* games */}
        <HomeGames />

        {/* poll */}
        <HomeSurvey />
      </main>
    </>
  );
};

export default Home;
