import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { brandAction, brandStyleAction, scoreHuntAction } from "redux/modules";
import BrandSlider from "./BrandSlider";
import { Link, Redirect } from "react-router-dom";
import { imageShowUrl } from "../../../config/api.config";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import UserPortalMenu from "../UserPortalMenu/UserPortalMenu";
import UserPortalInfo from "../UserPortalInfo/UserPortalInfo";
import UserPortalFooter from "../UserPortalFooter/UserPortalFooter";
import UserPortalWidget from "../UserPortalWidget/UserPortalWidget";
import TopAnnouncement from "../TopAnnouncement/TopAnnouncement";
import Swiper from "swiper";

import { IoCloseOutline, IoArrowForward } from "react-icons/io5";

import Camel from "assets/images/new-design/price-list-icons/camel/camel.png";
import Winston from "assets/images/new-design/price-list-icons/winston/winston.png";
import Ld from "assets/images/new-design/price-list-icons/ld/ld.png";
import MonteCarlo from "assets/images/new-design/price-list-icons/monte-carlo/monte-carlo.png";

import ScoreHunt from "assets/images/new-design/game/score-hunt-icon@2x.png";

import "./Brand.scss";

const Brand = props => {
  const dispatch = useDispatch();
  // Score Hunt
  const controlScoreHunt = useSelector(
    store => store.scoreHunt.controlScoreHunt
  );

  const [redirect, setRedirect] = useState(false);

  const brandList = useSelector(store => store.brand.brandList);

  const actives = useSelector(store => store.brand.actives);
  const footerFlag = useSelector(store => store.brand.footerFlag);

  const activesLast = props.location.actives;
  const isLoading = useSelector(store => store.brandStyle.isLoading);

  const brandIdLast = props.location.brandId;

  const $ = window.$;

  const [showHistory, setShowHistory] = useState(false);
  const [showProducts, setShowProducts] = useState(true);

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const brandStyleList = useSelector(store => store.brandStyle.brandStyleList);

  // New Design
  const [activeBrand, setActiveBrand] = useState(0);
  const [activeTab, setActiveTab] = useState("products");
  const [activeItem, setActiveItem] = useState();

  useEffect(() => {
    dispatch(scoreHuntAction.getControlScoreHuntByFirmId(employeeFirm.id));
    return () => {};
  }, []);

  // Tab Active
  useEffect(() => {
    if (props?.location?.brandIndex) {
      setActiveBrand(props.location.brandIndex - 1);
    }
  }, []);

  useEffect(() => {
    dispatch(brandAction.getBrandsByFirmId(employeeFirm.firm.id));
    return () => {};
  }, []);

  useEffect(() => {
    if (props.location.handleImgClick) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });

      handleBrandListImgClick(
        props.location.brandId,
        props.location.brandIndex
      );

      setShowProducts(true);
      setShowHistory(false);

      setTimeout(function() {
        window.productsMobileSlider.slideTo(props.location.brandIndex, 0);
      }, 500);
    }

    if (footerFlag)
      if (brandList && brandList.length > 0 && !brandIdLast)
        dispatch(brandStyleAction.getBrandStyleByBrandId(brandList[0].id));
      else if (brandList && brandList.length > 0 && brandIdLast)
        dispatch(brandStyleAction.getBrandStyleByBrandId(brandIdLast));
    return () => {};
  }, [brandList, actives, activesLast, brandIdLast]);

  useEffect(() => {
    if (activeItem) {
      setActiveItem(null);
    }
  }, []);

  async function onClick(brandId, index2) {
    dispatch(brandAction.setFooterFlag(false));
    if (brandList && brandList.length > 0)
      dispatch(brandStyleAction.getBrandStyleByBrandId(brandId));
    brandList.map((item, index) => {
      index === index2 ? (actives[index] = true) : (actives[index] = false);
    });
    dispatch(brandStyleAction.setSlider(false));
    dispatch(brandStyleAction.setIndexStyle(0));
    dispatch(brandStyleAction.setIndexBullet(0));
    dispatch(brandStyleAction.changeSelectedDataList(brandId));
  }

  function isCurrentBrand(index) {
    if (!activesLast) return actives && actives[index];
    else return activesLast && activesLast[index];
  }

  function handleBrandListImgClick(brandId, index) {
    if (!isCurrentBrand(index)) {
      onClick(brandId, index);
      setShowProducts(true);
      setShowHistory(false);
      dispatch(brandStyleAction.getBrandStyleByBrandId(brandId));
      setTimeout(function() {
        window.productsMobileSlider.slideTo(index, 0);
      }, 50);
    }
  }

  // function handleBrandSublinkTarihceClick(item, index) {
  //   onClick(item.id, index);
  //   setShowProducts(false);
  //   setShowHistory(true);
  //   setBrandIcon(item.icon);
  //   setBrandName(item.name);
  //   setBrandHistory(item.history);
  //   setTimeout(function() {
  //     window.productsMobileSlider.slideTo(index, 0);
  //   }, 50);
  // }

  // function handleBrandSublinkUrunlerClick(item, index) {
  //   onClick(item.id, index);
  //   setShowProducts(true);
  //   setShowHistory(false);

  //   setTimeout(function() {
  //     window.productsMobileSlider.slideTo(index, 0);
  //   }, 50);
  // }

  const handleClickScroll = () => {
    const element = document.getElementById("itemGrid");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    }
  };

  if (redirect) {
    return <Redirect to="/userportal/scoreHuntQuestion" />;
  }

  return (
    <div>
      <GlobalLoader isLoading={isLoading} />
      <TopAnnouncement />
      <UserPortalMenu />
      <UserPortalInfo />

      <main className="bg-slate-50 pb-20">
        {/* title & tabs */}
        <section className="glaire bg-[#16435E] !pt-6 md:!pt-12">
          <div className="container mx-auto !px-4">
            <h2 className="text-center text-2xl font-semibold leading-none text-white md:text-[32px]">
              Markalarımız
            </h2>
            <div className="relative -bottom-6 grid grid-cols-2 items-end rounded-xl shadow-xl md:-bottom-8 md:grid-cols-4">
              {brandList &&
                brandList.length > 0 &&
                brandList.map((item, index) => (
                  <div key={index} className="md:min-h-[154px]">
                    <div
                      className={`
											relative bg-gradient-to-t from-gray-200 to-white to-20% !p-2 md:!p-6 md:min-h-[154px]
											${
                        index !== 0
                          ? "before:absolute before:bottom-0 before:left-0 before:z-10 before:block before:h-full before:w-6 before:-translate-x-full before:transform before:bg-gradient-to-l before:from-gray-200 before:to-transparent before:opacity-50 before:content-['']"
                          : ""
                      }
											${index === 0 ? "rounded-tl-xl md:rounded-l-xl" : ""}
											${index === 1 ? "max-md:rounded-tr-xl" : ""}
											${index === 2 ? "max-md:rounded-bl-xl max-md:before:hidden" : ""}
											${index === 3 ? "rounded-br-xl md:rounded-r-xl" : ""}
											${
                        index === activeBrand
                          ? "md:rounded-tl-xl md:rounded-tr-[48px] md:before:max-h-[129px] lg:before:max-h-[154px]"
                          : ""
                      }
										`}
                    >
                      <button
                        className="w-full md:min-h-[100px]"
                        onClick={() => {
                          setActiveBrand(index);
                          setActiveTab("products");
                          setActiveItem();
                          onClick(item.id, index);
                        }}
                      >
                        <img
                          src={imageShowUrl + item.icon}
                          alt=""
                          className="mx-auto max-h-[64px] md:max-h-[100px]"
                        />
                      </button>
                      {index === activeBrand && (
                        <div className="relative !mt-4 hidden items-center justify-center md:flex lg:gap-2">
                          <button
                            className={`!px-4 !py-2 text-xs 
														${
                              activeTab === "products"
                                ? "rounded-full bg-primary-500 text-white"
                                : "text-slate-500"
                            }
													`}
                            onClick={() => {
                              setActiveTab("products");
                              setActiveItem();
                            }}
                          >
                            Ürünler
                          </button>
                          <button
                            className={`px-4 py-2 text-xs 
														${
                              activeTab === "about"
                                ? "rounded-full bg-primary-500 text-white"
                                : "text-slate-500"
                            }
													`}
                            onClick={() => {
                              setActiveTab("about");
                              setActiveItem();
                            }}
                          >
                            Tarihçe
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>

        <div className="container mx-auto mt-12 px-4 md:mt-20">
          <div className="relative mb-6 flex items-center justify-center md:hidden lg:gap-2">
            <button
              className={`px-4 py-2 text-xs 
								${
                  activeTab === "products"
                    ? "rounded-full bg-primary-500 text-white"
                    : "text-slate-500"
                }
							`}
              onClick={() => setActiveTab("products")}
            >
              Ürünler
            </button>
            <button
              className={`px-4 py-2 text-xs 
								${
                  activeTab === "about"
                    ? "rounded-full bg-primary-500 text-white"
                    : "text-slate-500"
                }
							`}
              onClick={() => setActiveTab("about")}
            >
              Tarihçe
            </button>
          </div>

          {activeItem && (
            <>
              {brandStyleList &&
                brandStyleList.length > 0 &&
                brandStyleList
                  .filter(item => item.id === activeItem)
                  .map(item => (
                    <div
                      className="mb-12 grid grid-cols-11 gap-4"
                      id="itemGrid"
                      key={item.id}
                    >
                      <div className="col-span-11 rounded-xl bg-white p-4 lg:p-12 lg:pb-4 xl:col-span-6">
                        <div className="grid grid-cols-3 gap-4 lg:gap-6">
                          {item.listImages &&
                            item.listImages.length > 0 &&
                            item.listImages.slice(1, 2).map((item, index) => (
                              <div className="text-center" key={index}>
                                <img
                                  src={imageShowUrl + item}
                                  alt=""
                                  className="mx-auto lg:max-w-[180px]"
                                />
                                {index === 0 && (
                                  <p className="mt-4 text-sm font-regular tracking-tighter text-slate-700">
                                    Düz Standart Paket Uygulama Sonrası
                                  </p>
                                )}
                              </div>
                            ))}

                          {item.listImages &&
                            item.listImages.length > 0 &&
                            item.listImages.slice(0, 1).map((item, index) => (
                              <div className="text-center" key={index}>
                                <img
                                  src={imageShowUrl + item}
                                  alt=""
                                  className="mx-auto lg:max-w-[180px]"
                                />
                                {index === 0 && (
                                  <p className="mt-4 text-sm font-regular tracking-tighter text-slate-700">
                                    Düz Standart Paket Uygulama Öncesi
                                  </p>
                                )}
                              </div>
                            ))}

                          {item.listImages &&
                            item.listImages.length > 0 &&
                            item.listImages.slice(2, 3).map((item, index) => (
                              <div className="text-center" key={index}>
                                <img
                                  src={imageShowUrl + item}
                                  alt=""
                                  className="mx-auto lg:max-w-[180px]"
                                />
                              </div>
                            ))}
                        </div>
                      </div>

                      <div className="relative col-span-11 rounded-xl bg-white px-6 pb-6 pt-16 md:p-12 xl:col-span-5">
                        <button
                          className="absolute right-4 top-4"
                          onClick={() => setActiveItem()}
                        >
                          <IoCloseOutline className=" h-8 w-8 text-slate-500" />
                        </button>
                        <div className="grid h-full grid-cols-6 items-end gap-2 sm:gap-2">
                          <div className="col-span-3 flex h-full flex-col sm:col-span-2">
                            <h1 className="text-2xl font-semibold leading-6 text-slate-700">
                              {item.name && item.name}
                            </h1>
                            <div className="w-100">
                              {controlScoreHunt &&
                                controlScoreHunt.length > 0 &&
                                controlScoreHunt.map((itemLogo, subindex) => (
                                  <a
                                    key={subindex}
                                    className="inline-block mt-3"
                                  >
                                    {itemLogo.answerable &&
                                    item.iconSearchFind ? (
                                      <img
                                        onClick={e => setRedirect(true)}
                                        style={{
                                          maxWidth: "157px"
                                        }}
                                        src={ScoreHunt}
                                      />
                                    ) : null}
                                  </a>
                                ))}
                            </div>
                            <div className="mt-auto">
                              <p className="mt-6 text-sm font-regular tracking-tighter text-slate-700">
                                FİYAT
                              </p>

                              {item.name && item.name.search("Winston") > -1 && (
                                <div className="winston-degrade mt-2 flex h-[64px] w-[64px] items-center justify-center gap-1 rounded-xl bg-winston-700 text-center text-[24px] text-white sm:h-[100px] sm:w-[100px] sm:text-[40px] font-semibold">
                                  {item.price && item.price}{" "}
                                  <span className="text-lg">₺</span>
                                </div>
                              )}

                              {item.name && item.name.search("Camel") > -1 && (
                                <div className="camel-degrade mt-2 flex h-[64px] w-[64px] items-center justify-center gap-1 rounded-xl bg-camel-700 text-center text-[24px] text-[#033e88] sm:h-[100px] sm:w-[100px] sm:text-[40px] font-semibold">
                                  {item.price && item.price}{" "}
                                  <span className="text-lg">₺</span>
                                </div>
                              )}

                              {item.name && item.name.search("LD") > -1 && (
                                <div className="ld-degrade mt-2 flex h-[64px] w-[64px] items-center justify-center gap-1 rounded-xl bg-ld-700 text-center text-[24px] text-white sm:h-[100px] sm:w-[100px] sm:text-[40px] font-semibold">
                                  {item.price && item.price}{" "}
                                  <span className="text-lg">₺</span>
                                </div>
                              )}

                              {item.name &&
                                item.name.search("Monte Carlo") > -1 && (
                                  <div className="monte-carlo-degrade mt-2 flex h-[64px] w-[64px] items-center justify-center gap-1 rounded-xl bg-montecarlo-700 text-center text-[24px] text-white sm:h-[100px] sm:w-[100px] sm:text-[40px] font-semibold">
                                    {item.price && item.price}{" "}
                                    <span className="text-lg">₺</span>
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-span-1 text-center sm:col-span-1">
                            <img
                              src={
                                item.listImages &&
                                imageShowUrl +
                                  item.listImages[item.listImages.length - 1]
                              }
                              alt=""
                              className="col-span-1 mx-auto h-[230px]"
                            />
                          </div>

                          <div className="col-span-3 flex flex-1 flex-col gap-2 max-sm:col-span-6">
                            <div className="grid grid-cols-1 gap-4">
                              {item.listProperty &&
                                item.listProperty.length > 0 &&
                                item.listProperty.map(property => (
                                  <div
                                    className="flex items-center gap-3 sm:gap-4"
                                    key={property.id}
                                  >
                                    <div className="h-[48px] w-[48px] shrink-0 rounded-xl sm:h-[64px] sm:w-[64px] flex flex-col justify-center items-center">
                                      {property.propertyIcon && (
                                        <img
                                          src={
                                            imageShowUrl + property.propertyIcon
                                          }
                                          className="max-w-[64px]"
                                        />
                                      )}
                                    </div>
                                    <div>
                                      <p className="text-xs font-regular tracking-tighter text-slate-700 sm:text-sm break-word">
                                        {property.propertyName &&
                                          property.propertyName}
                                      </p>
                                      <span className="text-sm sm:text-base break-word font-semibold">
                                        {property.propertyValue &&
                                          property.propertyValue}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </>
          )}

          {activeTab === "products" && (
            <div className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 lg:grid-cols-4">
              {brandStyleList &&
                brandStyleList.length > 0 &&
                brandStyleList.map(item => (
                  <button
                    className="overflow-hidden rounded-xl transition-all duration-300 hover:shadow-lg bg-white d-flex flex-col justify-between items-center"
                    key={item.id}
                    onClick={() => {
                      setActiveItem(item.id), handleClickScroll();
                    }}
                  >
                    <div className="bg-white p-2 md:p-4 w-100">
                      {item.name && item.icon && (
                        <img
                          src={imageShowUrl + item.icon}
                          alt={item.name}
                          className="mx-auto max-w-[130px]"
                        />
                      )}
                    </div>
                    <div className="flex items-center gap-4 bg-slate-200 p-4 text-left w-100">
                      <div className="bg-white w-12 h-12 flex flex-col justify-center align-items-center rounded-xl shadow-2xl">
                        {item?.name.search("Camel") > -1 && (
                          <img src={Camel} alt={item.name} />
                        )}

                        {item.name.search("Winston") > -1 && (
                          <img src={Winston} alt={item.name} />
                        )}

                        {item.name.search("LD") > -1 && (
                          <img src={Ld} alt={item.name} />
                        )}

                        {item.name.search("Monte Carlo") > -1 && (
                          <img src={MonteCarlo} alt={item.name} />
                        )}
                      </div>

                      <h3>{item.name && item.name}</h3>
                      <IoArrowForward className="ml-auto h-4 w-4 shrink-0 text-slate-500" />
                    </div>
                  </button>
                ))}
            </div>
          )}

          {activeTab === "about" && (
            <>
              {brandList &&
                brandList.length > 0 &&
                brandList.map((item, index) => (
                  <div
                    key={index}
                    className={
                      activeBrand === index
                        ? "grid grid-cols-4 overflow-hidden rounded-xl"
                        : "hidden"
                    }
                  >
                    <div className="col-span-1 bg-slate-200 p-6 max-lg:hidden lg:px-12 lg:py-20">
                      <img
                        src={imageShowUrl + item.icon}
                        alt={item.name}
                        className="mx-auto md:min-h-[100px] md:max-h-[100px]"
                      />
                    </div>
                    <div className="relative col-span-4 bg-white p-6 lg:col-span-3 lg:px-16 lg:py-20 [&>p]:mb-6 [&>p]:text-sm [&>p]:text-slate-500">
                      <button
                        className="absolute right-6 top-6"
                        onClick={() => setActiveTab("products")}
                      >
                        <IoCloseOutline className=" h-8 w-8 text-slate-500" />
                      </button>
                      <h2 className="mb-6 text-2xl font-bold text-slate-700 lg:mb-12 lg:text-4xl">
                        {item.name} Hakkında
                      </h2>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.history }}
                      ></div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </main>

      <UserPortalWidget />
      <UserPortalFooter />
    </div>
  );
};
export default Brand;
