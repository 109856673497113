import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ourArenaAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import OurArenaContent from "./OurArenaContent";

import BMFigure from "assets/portal-resource/img/benim-meydan-figure.png";

const OurArenaLeftMenu = props => {
  const dispatch = useDispatch();
  const isLoading = useSelector(store => store.ourArena.isLoading);

  const [showContent, setShowContent] = useState("subjectWeek");
  const [openTerms, setOpenTerms] = useState(false);

  useEffect(() => {
    return () => {};
  }, [showContent]);

  function setClicked(key) {
    dispatch(ourArenaAction.setArenaContent(key));
  }

  return (
    <div className="basis-full md:basis-3/12">
      <GlobalLoader isLoading={isLoading} />

      <div className="detail-page-left-menu">
        <img src={BMFigure} className="mx-auto mb-8" />

        <ul>
          <li
            className={`${
              showContent === "subjectWeek" ? "d-flex active" : "d-flex"
            }`}
          >
            <a
              className="d-block flex-grow-1"
              onClick={() => {
                setClicked("BU HAFTANIN KONUSU");
                setShowContent("subjectWeek");
              }}
            >
              Bu Haftanın Konusu
            </a>
          </li>
          <li
            className={`${
              showContent === "pastSubject" ? "d-flex active" : "d-flex"
            }`}
          >
            <a
              className="d-block flex-grow-1"
              onClick={() => {
                setClicked("GECMIS KONULAR");
                setShowContent("pastSubject");
              }}
            >
              Geçmiş Konular
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default OurArenaLeftMenu;
