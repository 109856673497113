import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickFindAction } from "redux/modules";
import showErrMessage from "hooks/showErrMessage";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { imageShowUrl } from "../../../config/api.config";
import WinstonWinner from "../../../assets/images/winners/winston-winner@3x.png";
import CamelWinner from "../../../assets/images/winners/camel-winner@3x.png";
import MonteCarloWinner from "../../../assets/images/winners/monte-carlo-winner@3x.png";
import LdWinner from "../../../assets/images/winners/ld-winner@3x.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import CardsIcon from "assets/portal-resource/img/games-icons/icon-cards.png";

import { FaInfoCircle } from "react-icons/fa";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowBack,
  IoArrowForward,
  IoInformationCircle,
  IoArrowDown
} from "react-icons/io5";

import PickFindIcon from "assets/images/new-design/game/card-icon.png";

const PickFind = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const controlPickFind = useSelector(store => store.pickFind.controlPickFind);
  var pickFindGame = null;
  const isLoading = useSelector(store => store.pickFind.isLoading);

  useEffect(() => {
    dispatch(
      pickFindAction.getControlPickFindByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    );
    return () => {};
  }, []);

  if (controlPickFind) {
    pickFindGame = controlPickFind.map((item, index) => {
      return (
        <div key={index}>
          {/* {item.availableTodayPickFind && !item.answeredTodayPickFind ? (
            <div className="w-full bg-white flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-4 py-6 md:px-8 px-4" style={{ display: 'none' }}>
              <div className="action flex justify-between w-full">
                <Link
                  to="/userportal/competitions"
                  className="font-semibold flex items-center justify-center md:text-[16px] text-[14px] gap-2 md:min-w-[180px] min-w-[130px] rounded-full border-2 border-slate-700 bg-white md:px-10 px-4 py-3 text-slate-700"
                >
                  <IoArrowBack className="h-4 w-4" />
                  Geri Git{' '}
                </Link>

                <Link
                  to="/userportal/pickFindQuestion"
                  className="font-semibold flex items-center justify-center md:text-[16px] text-[14px] gap-2 md:min-w-[180px] min-w-[130px] rounded-full border-2 bg-[#00b185] border-[#00b185] md:px-10 px-4 py-3 text-white"
                >
                  Başla{' '}
                  <IoArrowForward className="h-4 w-4" />
                </Link>
              </div>
            </div>
          ) : null} */}
          {!item.availableTodayPickFind ? (
            <>
              <p className="md:text-[16px] text-[14px] font-normal text-slate-500 mb-8">
                {item.nextDayMessage}
              </p>

              <div className="flex flex-col">
                <Link
                  to={{ pathname: "/userportal/competitions" }}
                  className="mr-auto font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                >
                  <IoArrowBack className="h-4 w-4" />
                  Yarışmalar{" "}
                </Link>
              </div>
            </>
          ) : null}
          {item.answeredTodayPickFind ? (
            <>
              <p className="md:text-[16px] text-[14px] font-normal text-slate-500 mb-8">
                {item.answeredNextDayMessage}
              </p>

              <div className="flex flex-col">
                <Link
                  to={{ pathname: "/userportal/competitions" }}
                  className="mr-auto font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                >
                  <IoArrowBack className="h-4 w-4" />
                  Yarışmalar{" "}
                </Link>
              </div>
            </>
          ) : null}
        </div>
      );
    });
  }

  return (
    <div className="games games-detail-page">
      <section>
        <div className="container mx-auto py-20 px-6 md:px-0">
          <div className="flex gap-4 h-100 flex-col md:flex-row">
            <div className="relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg.png')] bg-left-bottom bg-no-repeat mx-auto">
              <h2 className="md:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                Eşle Kazan
              </h2>
              <img
                src={PickFindIcon}
                className="w-[220px] absolute bottom-0 left-1/2 -translate-x-1/2"
                alt=""
              />
            </div>

            <div className="bg-slate-200 flex-1 flex justify-center rounded-[12px] flex-col py-8 px-8">
              <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                <FaInfoCircle className="h-6 w-6" /> Eşle Kazan’a Hoş geldiniz
              </h3>

              {controlPickFind &&
                controlPickFind.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.availableTodayPickFind &&
                        !item.answeredTodayPickFind && (
                          <p className="mb-6 tracking-wide">
                            JTI ürünleri ve ürün özellikleri ile ilgili bilgi
                            yarışmasıdır. Her hafta{" "}
                            <span className="text-slate-700">Pazartesi</span>,{" "}
                            <span className="text-slate-700">Salı</span>,{" "}
                            <span className="text-slate-700">Perşembe</span> ve{" "}
                            <span className="text-slate-700">Cuma</span> günleri
                            karşınıza çıkar. JTI ürünleri ile bu ürünlere ait
                            özellikleri doğru olarak eşleştirin ve{" "}
                            <span className="text-slate-700">100 puan</span>{" "}
                            kazanın. Bugünün Eşle Kazan sorusu için{" "}
                            <span className="text-slate-700">Başla</span>{" "}
                            butonuna tıklayın. Başarılar!
                          </p>
                        )}
                    </div>
                  );
                })}

              {pickFindGame}
            </div>
          </div>

          {controlPickFind &&
            controlPickFind.map((item, index) => {
              return (
                <div key={index}>
                  {item.availableTodayPickFind &&
                  !item.answeredTodayPickFind ? (
                    <div className="w-full md:w-[calc(100%-324px)] bg-white flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-4 py-6 md:px-8 px-4">
                      <div className="action flex justify-between w-full">
                        <Link
                          to="/userportal/competitions"
                          className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                        >
                          <IoArrowBack className="h-4 w-4" />
                          Geri Git{" "}
                        </Link>

                        <Link
                          to="/userportal/pickFindQuestion"
                          className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                        >
                          Başla <IoArrowForward className="h-4 w-4" />
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>

        <GlobalLoader isLoading={isLoading} />
      </section>
    </div>
  );
};
export default PickFind;
