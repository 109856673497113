import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import showErrMessage from "hooks/showErrMessage";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr"; // the locale you want
import Pagination from "react-js-pagination";

setDefaultLocale("tr");
registerLocale("tr", tr); // register it with the name you want

const CorrectResponder = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const allTrueAnswerList = useSelector(store => store.game.allTrueAnswerList);
  const isLoading = useSelector(store => store.game.isLoading);
  const [searchName, setSearchName] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const totalPages = useSelector(store => store.game.totalPages);

  const [disclaimerList, setDisclaimerList] = useState([]);

  const [date, setDate] = useState(null);
  let dateTime = null;

  useEffect(() => {
    if (!disclaimerList || disclaimerList.length === 0) {
      dispatch(
        gameAction.getCompetitionDisclaimerByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      ).then(response => {
        setLoaded(true);
        setDisclaimerList(response.data);
      });
    }

    return () => {};
  });

  useEffect(() => {
    dispatch(gameAction.getAllTrueAnswerByFirmId(employeeFirm.firm.id, 0)).then(
      response => {
        setLoaded(true);
      }
    );
    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [allTrueAnswerList, totalPages]);

  function setSearchNames(e) {
    var currentText = e.target.value;
    setSearchName(currentText);
  }

  function setSearchDates(e) {
    setDate(e);
  }

  function handlePageChange(pageNumber) {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setActivePage(pageNumber);
    let currentDate = null;

    if (date) {
      let todayTime = new Date(date);
      let month = todayTime.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      let day = todayTime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let year = todayTime.getFullYear();
      currentDate = day + "-" + month + "-" + year;
      dispatch(
        gameAction.getAllTrueAnswersByFilter(
          searchName,
          currentDate,
          employeeFirm.firm.id,
          pageNumber - 1
        )
      );
    }
    if (date === null)
      dispatch(
        gameAction.getAllTrueAnswerByFirmId(
          employeeFirm.firm.id,
          pageNumber - 1
        )
      );

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  function search() {
    let currentDate = null;

    if (date) {
      let todayTime = new Date(date);
      let month = todayTime.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      let day = todayTime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let year = todayTime.getFullYear();
      currentDate = day + "-" + month + "-" + year;
    } else {
      showErrMessage("Lütfen tarih seçiniz.");
      return false;
    }

    setActivePage(1);
    dispatch(
      gameAction.getAllTrueAnswersByFilter(
        searchName,
        currentDate,
        employeeFirm.firm.id,
        0
      )
    );
  }
  /*
        const columns = [
          {
            dataField: "userRank",
            text: "Sıra"
          },
          {
            dataField: "employeeFirm.vendorName",
            text: "Bayi Adı"
          },
          {
            dataField: "employeeFirm.employee.fullName",
            text: "Ad Soyad"
          },
          {
            dataField: "trueTableDate",
            text: "Tarih"
          }
        ]; */

  /*   var table;
        if (allTrueAnswerList && allTrueAnswerList.length > 0 > 0) {
          table = (
            <BootstrapTable
            bootstrap4
            keyField="id"
            data={allTrueAnswerList}
            columns={columns}
            pagination={paginationFactory()}
            />
            );
          } else {
            table = <div>Yarışma Sıralamasında Kullanıcı Mevcut Değil</div>;
          } */

  return (
    <div
      className="correct-responders"
      style={{
        color:
          disclaimerList && disclaimerList[0] && disclaimerList[0].primaryColor
            ? disclaimerList[0].primaryColor
            : "#FFF"
      }}
    >
      <div className="wrapper-question-item">
        <div>
          {/* <GlobalLoader isLoading={!loaded} /> */}
          <h1
            style={{
              color:
                disclaimerList &&
                disclaimerList[0] &&
                disclaimerList[0].primaryColor
                  ? disclaimerList[0].primaryColor
                  : "#FFF"
            }}
          >
            Tüm Doğru Yanıt Verenler
          </h1>
          <span>Lütfen bayi adınızı yazarak “Sorgula” tuşuna basınız</span>
          <form action="" className="mt-1 mb-3">
            <div className="row">
              <div className="col-sm mt-2">
                <input
                  type="text"
                  value={searchName}
                  className="form-control"
                  onChange={setSearchNames}
                  placeholder="Bayi Adınızı Yazınız"
                />
              </div>
              <div className="col-sm mt-2">
                <DatePicker
                  selected={date}
                  onChange={setSearchDates}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Tarih Yazınız"
                />
              </div>
              <div className="col-sm mt-2">
                <div>
                  <button
                    type="submit"
                    onClick={e => {
                      e.preventDefault();
                      search();
                    }}
                    className="btn pl-0 pr-0 w-100"
                    style={{
                      backgroundColor:
                        disclaimerList &&
                        disclaimerList[0] &&
                        disclaimerList[0]?.buttonList[0]?.buttonColor &&
                        disclaimerList[0]?.buttonList[0]?.buttonColor
                    }}
                  >
                    SORGULA
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* <div>{table}</div> */}
        <div>
          {allTrueAnswerList && allTrueAnswerList.length > 0 ? (
            <div className="react-bootstrap-table">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td style={{ width: "50px" }}>Sıra</td>
                    <td>Bayi Adı</td>
                    <td>İsim</td>
                    <td>Tarih</td>
                  </tr>
                </thead>
                <tbody>
                  {allTrueAnswerList.map((item, index) => (
                    <tr key={item.id}>
                      <td>{item.userRank}</td>
                      <td>{item.vendorName}</td>
                      <td>{item.employeeFirmDTO.employee.fullName} </td>
                      <td>{item.trueTableDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>Yarışma Sıralamasında Kullanıcı Mevcut Değil</div>
          )}
          <Pagination
            activePage={activePage}
            totalItemsCount={12 * totalPages}
            itemsCountPerPage={12}
            pageRangeDisplayed={
              totalPages !== null && totalPages > 5 ? 5 : totalPages
            }
            itemClass="page-item"
            linkClass="page-link"
            onChange={handlePageChange.bind(this)}
          />
          {/* <div className="overflow-table">
                  <table className="table">
                  <thead>
                  <tr>
                  <th scope="col" className="w-20">
                  Sıra
                  </th>
                  <th scope="col" className="w-20">
                  Bayi Adı
                  </th>
                  <th scope="col">Ad Soyad</th>
                  <th scope="col" className="w-20">
                  Tarih
                  </th>
                  </tr>
                  </thead>
                  <tbody>
                  {allTrueAnswerList && allTrueAnswerList.length > 0 ? (
                    allTrueAnswerList.map((item) => {
                      return (
                        <tr key={item.id}>
                        <td scope="row">{item.userRank}</td>
                        <td>{item.vendorName}</td>
                        <td>{item.employeeFirm.employee.fullName} </td>
                        <td>{item.trueTableDate}</td>
                        </tr>
                        );
                      })
                      ) : (
                        <tr>
                        <td colSpan="4">
                        Yarışma Sıralamasında Kullanıcı Mevcut Değil
                        </td>
                        </tr>
                        )}
                        </tbody>
                        </table>
                      </div> */}
        </div>
      </div>
      <style>
        {`
          .react-bootstrap-table .table.table-bordered th, .react-bootstrap-table .table.table-bordered td {
            color: ${
              disclaimerList &&
              disclaimerList[0] &&
              disclaimerList[0].primaryColor
                ? disclaimerList[0].primaryColor
                : "#FFF"
            };
          }
        `}
      </style>
    </div>
  );
};
export default CorrectResponder;
