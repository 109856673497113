import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import { Redirect, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "./assets/images/ramadan/logo.png";
import { imageShowUrl } from "../../../config/api.config";

const Competition = props => {
  const dispatch = useDispatch();
  var disclaimer = null;
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const [disclaimerList, setDisclaimerList] = useState([]);
  const [disclaimerInTheWeek, setDisclaimerInTheWeek] = useState(false);
  const [load, setLoad] = useState(false);
  const activeMenuItem = useSelector(store => store.game.indexMenu);

  const setIndexMenu = index => {
    dispatch(gameAction.setIndexMenu(index));
  };

  useEffect(() => {
    if (!disclaimerList || disclaimerList.length === 0) {
      dispatch(
        gameAction.getCompetitionDisclaimerByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      ).then(response => {
        response.data[0].menuList.forEach((element, index) => {
          if (element.path === location.pathname) {
            setIndexMenu(index);
          }
        });

        setDisclaimerList(response.data);
        props.getActionList(response.data);
      });
    }

    dispatch(
      gameAction.getDisclaimerByFirmId(employeeFirm.firm.id, employeeFirm.id)
    ).then(response => {
      if (response.data) {
        setDisclaimerInTheWeek(response.data[0].checkedDisclaimerInTheWeek);
        setLoad(true);
      }
    });

    return () => {};
  }, []);

  let location = useLocation();

  if (!disclaimerInTheWeek && load) {
    return <Redirect to="/disclaimer" />;
  }

  if (disclaimerList) {
    disclaimer = disclaimerList.map((item, index) => {
      if (item.memberStatu === "ASKIDA") {
        return <Redirect to="/" />;
      } else {
        if (item.checkedDisclaimerCompetition) {
          return (
            <div className="menu" key={index}>
              <div className="container-xl">
                <nav className="navbar navbar-expand-md navbar-dark p-0 m-0">
                  <a href="/">
                    <span className="navbar-brand p-0 m-0">
                      <img
                        src={imageShowUrl + item.logoImage}
                        alt=""
                        className="img-fluid mw-150"
                      />
                    </span>
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNav">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon" />
                    </button>
                    <ul className="navbar-nav">
                      {item.menuList &&
                        item.menuList.map((menuItem, menuIndex) => {
                          const activeClass = {
                            backgroundColor: item.choiceOutColor,
                            color: menuItem.textColor
                          };

                          const defaultClass = {
                            color:
                              item &&
                              item.primaryColor &&
                              menuItem &&
                              menuItem.textColor
                                ? menuItem.textColor
                                : item.primaryColor
                          };

                          const aElemActiveClass = {
                            color: menuItem.textColor,
                            backgroundColor: item.textColor
                          };

                          return (
                            <li
                              key={menuIndex}
                              id={menuIndex}
                              className={
                                menuIndex === activeMenuItem
                                  ? "nav-item active"
                                  : "nav-item"
                              }
                              style={
                                menuIndex === activeMenuItem
                                  ? activeClass
                                  : null
                              }
                            >
                              <Link
                                className="nav-link"
                                to={{
                                  pathname: menuItem.path
                                  /*logined: "logined",
                                  brandId: 149465,
                                  brandIndex: 2,
                                  handleImgClick: true*/
                                }}
                                onClick={() => setIndexMenu(menuIndex)}
                                style={
                                  menuIndex === activeMenuItem
                                    ? aElemActiveClass
                                    : defaultClass
                                }
                              >
                                {menuItem.name}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          );
        } else {
          if (location.pathname != "/competition/rules") {
            return <Redirect to="/competition/rules" />;
          } else {
            return (
              <div className="menu" key={index}>
                <div className="container-xl">
                  <nav className="navbar navbar-expand-md navbar-dark">
                    <a href="/">
                      <span className="navbar-brand">
                        <img
                          src={imageShowUrl + item.logoImage}
                          alt=""
                          className="m-2 img-fluid mw-150"
                        />
                      </span>
                    </a>
                    {/*<b utton
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNav">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon" />
                    </button>
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a className="btn pl-3 pr-3" href="/competition/">
                          BAŞLA
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/competition/how-do-compete"
                        >
                          NASIL YARIŞIRIM ?{" "}
                          <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li className="nav-item d-none">
                        <Link
                          className="nav-link"
                          to={{
                            pathname: "/userportal/brand",
                            brandId: 149465,
                            brandIndex: 1,
                            handleImgClick: true
                          }}
                        >
                          MARKALARIMIZ
                        </Link>
                      </li>
                      <li className="nav-item d-none">
                        <a className="nav-link" href="/competition/price-list">
                          FİYAT LİSTESİ
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/competition/gifts">
                          HEDİYELER
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/competition/correct-responders"
                        >
                          DOĞRU YANITLAYANLAR
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/competition/winners">
                          KAZANANLAR
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/competition/rules">
                          KURALLAR
                        </a>
                      </li>
                    </ul>
                  </div>*/}
                  </nav>
                </div>
              </div>
            );
          }
        }
      }
    });
  } else {
    if (location.pathname != "/competition/rules") {
      disclaimer = <Redirect to="/competition/rules" />;
    } else {
      disclaimer = (
        <div className="menu">
          <div className="container-xl">
            <nav className="navbar navbar-expand-md navbar-dark">
              <a
                className="navbar-brand p-0"
                href="/competition/correct-responder"
              >
                <img src={Logo} alt="" />
              </a>
            </nav>
          </div>
        </div>
      );
    }
  }

  return <div>{disclaimer}</div>;
};
export default Competition;
