import * as aboutTypes from "./about.type";

const initialState = {
  brandList: [],
  error: null,
  isLoading: false,
  actives: [true, false, false, false, false, false],
  activeAboutMenu: [true, false]
};

const reducer = (
  state = initialState,
  { type, payload, actives, ...params }
) => {
  switch (type) {
    case `${aboutTypes.GET_ABOUT_ACTIVE_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${aboutTypes.GET_ABOUT_ACTIVE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        actives: payload
      };
    case `${aboutTypes.GET_ABOUT_ACTIVE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        actives: null
      };

    default:
      return state;
  }
};

export default reducer;
