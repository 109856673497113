import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageShowUrl } from "../../../config/api.config";

import { searchFindAction, profileAction } from "redux/modules";
import GlobalLoader from "../../GlobalLoader/GlobalLoader";
import showMessage from "../../../hooks/showMessage";
import showErrMessage from "../../../hooks/showErrMessage";

const ProfileBadge = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.searchFind.isLoading);
  const allBadges = useSelector(store => store.searchFind.allBadges);
  const [jti, setJti] = useState([]);
  const [winston, setWinston] = useState([]);
  const [camel, setCamel] = useState([]);
  const [ld, setLd] = useState([]);
  const [monteCarlo, setMonteCarlo] = useState([]);
  const [badges, setBadges] = useState([]);
  const profileBadge = useSelector(store => store.profile.profileBadge);

  useEffect(() => {
    dispatch(searchFindAction.getAllBadges(employeeFirm.id, 1)).then(e => {
      setJti(e.data);
    });

    dispatch(searchFindAction.getAllBadges(employeeFirm.id, 149464)).then(e => {
      setWinston(e.data);
    });

    dispatch(searchFindAction.getAllBadges(employeeFirm.id, 149465)).then(e => {
      setCamel(e.data);
    });

    dispatch(searchFindAction.getAllBadges(employeeFirm.id, 149466)).then(e => {
      setLd(e.data);
    });

    dispatch(searchFindAction.getAllBadges(employeeFirm.id, 149467)).then(e => {
      setMonteCarlo(e.data);
    });

    return () => {};
  }, [dispatch, employeeFirm.id]);

  const handleSetProfileBadge = (e, badgeId) => {
    dispatch(profileAction.setProfileBadge(employeeFirm.id, badgeId))
      .then(e => {
        if (e.isHttpSuccess) {
          showMessage("Profil Rozeti olarak tanımlanmıştır!");
          dispatch(profileAction.getProfileBadge(employeeFirm.id));
        }
      })
      .catch(err => {
        if (err.isHttpError) {
          showErrMessage("Profil Rozeti olarak tanımlanamadı!");
        }
      });
  };

  return (
    <div
      className="tab-pane fade show active"
      id="badges"
      role="tabpanel"
      aria-labelledby="badges-tab"
    >
      <div className="games games-detail-page p-0">
        <GlobalLoader isLoading={isLoading} />

        <div className="container-fluid">
          <div className="wrapper-games-content row justify-content-center">
            <div className="games-brands games-badges">
              <div className="games-badges-actives mt-0">
                {profileBadge.profileBadge === false ? (
                  <p className="games-badges-empty-message">
                    Henüz kazandığınız bir rozet bulunmuyor.
                  </p>
                ) : (
                  <>
                    <h3>Kazandığım Rozetler</h3>
                    <p className="pb-5 m-0">
                      Kazandığınız rozetlerden istediğinizi seçerek profilinizde
                      yayınlayabilir, profil rozetinizi değiştirmek isterseniz
                      seçtiğiniz rozetin üzerine tıklayarak değiştirebilirsiniz.
                    </p>
                    <div className="row">
                      {jti &&
                        jti
                          .filter(item => item.hasBadge === true)
                          .map((item, index) => {
                            return (
                              <div
                                className="col-12 col-md-6 col-lg-6 col-xl-6"
                                key={index}
                              >
                                <div
                                  className={
                                    item.profileBadge
                                      ? "games-badge-list active-badge"
                                      : "games-badge-list"
                                  }
                                  onClick={e =>
                                    handleSetProfileBadge(e, item.badgeId)
                                  }
                                >
                                  <div className="games-badge-list-image">
                                    <img
                                      src={imageShowUrl + item.icon}
                                      className={
                                        item.hasBadge === false
                                          ? "img-fluid grayscale1"
                                          : "img-fluid height"
                                      }
                                      style={
                                        item.hasBadge === false
                                          ? { opacity: 0.2 }
                                          : { opacity: 1 }
                                      }
                                    />
                                  </div>

                                  <div className="games-badge-list-content">
                                    <h3>{item.badgeName}</h3>
                                    {item.description && (
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.description
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                      {winston &&
                        winston
                          .filter(item => item.hasBadge === true)
                          .map((item, index) => {
                            return (
                              <div
                                className="col-12 col-md-6 col-lg-6 col-xl-6"
                                key={index}
                              >
                                <div
                                  className={
                                    item.profileBadge
                                      ? "games-badge-list active-badge"
                                      : "games-badge-list"
                                  }
                                  onClick={e =>
                                    handleSetProfileBadge(e, item.badgeId)
                                  }
                                >
                                  <div className="games-badge-list-image">
                                    <img
                                      src={imageShowUrl + item.icon}
                                      className={
                                        item.hasBadge === false
                                          ? "img-fluid grayscale1"
                                          : "img-fluid height"
                                      }
                                      style={
                                        item.hasBadge === false
                                          ? { opacity: 0.2 }
                                          : { opacity: 1 }
                                      }
                                    />
                                  </div>

                                  <div className="games-badge-list-content">
                                    <h3>{item.badgeName}</h3>
                                    {item.description && (
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.description
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                      {camel &&
                        camel
                          .filter(item => item.hasBadge === true)
                          .map((item, index) => {
                            return (
                              <div
                                className="col-12 col-md-6 col-lg-6 col-xl-6"
                                key={index}
                              >
                                <div
                                  className={
                                    item.profileBadge
                                      ? "games-badge-list active-badge"
                                      : "games-badge-list"
                                  }
                                  onClick={e =>
                                    handleSetProfileBadge(e, item.badgeId)
                                  }
                                >
                                  <div className="games-badge-list-image">
                                    <img
                                      src={imageShowUrl + item.icon}
                                      className={"img-fluid height"}
                                      style={{ opacity: 1 }}
                                    />
                                  </div>

                                  <div className="games-badge-list-content">
                                    <h3>{item.badgeName}</h3>
                                    {item.description && (
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.description
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                      {ld &&
                        ld
                          .filter(item => item.hasBadge === true)
                          .map((item, index) => {
                            return (
                              <div
                                className="col-12 col-md-6 col-lg-6 col-xl-6"
                                key={index}
                              >
                                <div
                                  className={
                                    item.profileBadge
                                      ? "games-badge-list active-badge"
                                      : "games-badge-list"
                                  }
                                  onClick={e =>
                                    handleSetProfileBadge(e, item.badgeId)
                                  }
                                >
                                  <div className="games-badge-list-image">
                                    <img
                                      src={imageShowUrl + item.icon}
                                      className={
                                        item.hasBadge === false
                                          ? "img-fluid grayscale1"
                                          : "img-fluid height"
                                      }
                                      style={
                                        item.hasBadge === false
                                          ? { opacity: 0.2 }
                                          : { opacity: 1 }
                                      }
                                    />
                                  </div>

                                  <div className="games-badge-list-content">
                                    <h3>{item.badgeName}</h3>
                                    {item.description && (
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.description
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                      {monteCarlo &&
                        monteCarlo
                          .filter(item => item.hasBadge === true)
                          .map((item, index) => {
                            return (
                              <div
                                className="col-12 col-md-6 col-lg-6 col-xl-6"
                                key={index}
                              >
                                <div
                                  className={
                                    item.profileBadge
                                      ? "games-badge-list active-badge"
                                      : "games-badge-list"
                                  }
                                  onClick={e =>
                                    handleSetProfileBadge(e, item.badgeId)
                                  }
                                >
                                  <div className="games-badge-list-image">
                                    <img
                                      src={imageShowUrl + item.icon}
                                      className={
                                        item.hasBadge === false
                                          ? "img-fluid grayscale1"
                                          : "img-fluid height"
                                      }
                                      style={
                                        item.hasBadge === false
                                          ? { opacity: 0.2 }
                                          : { opacity: 1 }
                                      }
                                    />
                                  </div>

                                  <div className="games-badge-list-content">
                                    <h3>{item.badgeName}</h3>
                                    {item.description && (
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.description
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </>
                )}
              </div>

              <h3>Benim Bayim Rozetleri</h3>
              <div className="row">
                {jti &&
                  jti.length > 0 &&
                  jti.map((item, index) => {
                    return (
                      <div
                        className="col-12 col-md-6 col-lg-6 col-xl-6"
                        key={index}
                      >
                        <div className="games-badge-list">
                          <div className="games-badge-list-image">
                            <img
                              src={imageShowUrl + item.icon}
                              className="img-fluid height"
                            />
                          </div>

                          <div className="games-badge-list-content">
                            <h3>{item.badgeName}</h3>
                            {item.description && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <h3>Winston Rozetleri</h3>
              <div className="row">
                {winston &&
                  winston.length > 0 &&
                  winston.map((item, index) => {
                    return (
                      <div
                        className="col-12 col-md-6 col-lg-6 col-xl-6"
                        key={index}
                      >
                        <div className="games-badge-list">
                          <div className="games-badge-list-image">
                            <img
                              src={imageShowUrl + item.icon}
                              className="img-fluid height"
                            />
                          </div>

                          <div className="games-badge-list-content">
                            <h3>{item.badgeName}</h3>
                            {item.description && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <h3>Camel Rozetleri</h3>
              <div className="row">
                {camel &&
                  camel.length > 0 &&
                  camel.map((item, index) => {
                    return (
                      <div
                        className="col-12 col-md-6 col-lg-6 col-xl-6"
                        key={index}
                      >
                        <div className="games-badge-list">
                          <div className="games-badge-list-image">
                            <img
                              src={imageShowUrl + item.icon}
                              className="img-fluid height"
                            />
                          </div>

                          <div className="games-badge-list-content">
                            <h3>{item.badgeName}</h3>
                            {item.description && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <h3>LD Rozetleri</h3>
              <div className="row">
                {ld &&
                  ld.length > 0 &&
                  ld.map((item, index) => {
                    return (
                      <div
                        className="col-12 col-md-6 col-lg-6 col-xl-6"
                        key={index}
                      >
                        <div className="games-badge-list">
                          <div className="games-badge-list-image">
                            <img
                              src={imageShowUrl + item.icon}
                              className="img-fluid height"
                            />
                          </div>

                          <div className="games-badge-list-content">
                            <h3>{item.badgeName}</h3>
                            {item.description && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <h3>Monte Carlo Rozetleri</h3>
              <div className="row">
                {monteCarlo &&
                  monteCarlo.length > 0 &&
                  monteCarlo.map((item, index) => {
                    return (
                      <div
                        className="col-12 col-md-6 col-lg-6 col-xl-6"
                        key={index}
                      >
                        <div className="games-badge-list">
                          <div className="games-badge-list-image">
                            <img
                              src={imageShowUrl + item.icon}
                              className="img-fluid height"
                            />
                          </div>

                          <div className="games-badge-list-content">
                            <h3>{item.badgeName}</h3>
                            {item.description && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBadge;
