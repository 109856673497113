import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchFindAction, brandStyleAction, brandAction } from "redux/modules";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { imageShowUrl } from "../../../config/api.config";

import { IoArrowDown, IoArrowForward } from "react-icons/io5";
import { FaInfoCircle } from "react-icons/fa";
import CardIconGreen from "assets/images/new-design/game/card-icon-green.png";

const SearchFind = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const controlSearchFind = useSelector(
    store => store.searchFind.controlSearchFind
  );
  const brandList = useSelector(store => store.brand.brandList);
  var searchFindGame = null;
  const actives = [];
  const isLoading = useSelector(store => store.searchFind.isLoading);
  const brandBadges = useSelector(store => store.searchFind.brandBadges);

  useEffect(() => {
    dispatch(searchFindAction.getBrandBadges(employeeFirm.id));

    return () => {};
  }, []);

  function onClickGoBrand(brandId, index2) {
    if (brandList.length > 0)
      dispatch(brandStyleAction.getBrandStyleByBrandId(brandId));
    dispatch(brandStyleAction.setSlider(false));
    dispatch(brandStyleAction.setIndexStyle(index2));
    dispatch(brandStyleAction.setIndexBullet(0));

    brandList.map(item => {
      index2 === item.imageOrder ? actives.push(true) : actives.push(false);
    });
  }

  if (controlSearchFind) {
    searchFindGame = controlSearchFind.map(item => {
      return (
        <div key={item.questionId}>
          {item.availableTodayQuestion && item.answerable ? (
            <div className="mt-5">
              <Link
                className="arrowButton green d-inline-block"
                key={item.brandId}
                to={{
                  pathname: "/userportal/brand",
                  actives: actives,
                  brandId: item.brandId,
                  brandIndex: item.imageOrder,
                  brandList: brandList
                }}
                onClick={() => onClickGoBrand(item.brandId, item.imageOrder)}
              >
                BAŞLA
              </Link>
            </div>
          ) : null}
          {item.availableTodayQuestion && !item.answerable ? (
            <div style={{ color: "red" }}> {item.answeredNextDayMessage} </div>
          ) : null}
          {!item.availableTodayQuestion ? (
            <div style={{ color: "red" }}>{item.nextDayMessage}</div>
          ) : null}
        </div>
      );
    });
  }

  const handleClickBrand = (e, brandId, item) => {
    localStorage.setItem("selectedItem", JSON.stringify(item));
    localStorage.setItem("selectedBrandId", brandId);
  };

  return (
    <main className="bg-slate-50 pb-20">
      <GlobalLoader isLoading={isLoading} />

      <div className="mx-auto px-4 pt-4 xl:container lg:pt-20">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div className="col-span-4 flex flex-col max-h-[270px] md:max-h-[333px] h-[100%] md:h-[333px] justify-center rounded-xl bg-[url('assets/images/new-design/game/card-bg-green.png')] bg-no-repeat mx-auto w-full bg-cover p-6 text-center md:col-span-2 md:col-start-2 lg:col-span-1 lg:rounded-bl-[100px] rounded-bl-[22px]">
            <h1 className="mb-4 text-2xl font-bold text-white md:mb-6 lg:text-4xl">
              Rozet Macerası
            </h1>
            <img
              src={CardIconGreen}
              className="mx-auto max-h-[120px] lg:max-h-[200px]"
              alt=""
            />
          </div>
          <div className="relative col-span-4 rounded-xl bg-slate-200 bg-opacity-75 !p-6 text-[15px] leading-7 text-slate-500 lg:col-span-3 !lg:px-12 !lg:py-14">
            <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
              <FaInfoCircle className="h-6 w-6" /> Rozet Macerası’na Hoşgeldiniz
            </h3>
            <p className="mb-6">
              JTI ürünleri ile ilgili bilgi yarışmasıdır. Her hafta{" "}
              <span className="text-slate-700">
                Pazartesi, Çarşamba ve Perşembe
              </span>{" "}
              günleri karşınıza çıkar. Soruyu{" "}
              <span className="text-slate-700">90 saniye</span> içinde doğru
              yanıtlarsanız <span className="text-slate-700">100 puan</span>{" "}
              kazanırsınız.
            </p>
            <p>
              Ayrıca Rozet Macerası sorularından marka rozetleri kazanırsınız.
              Her markanın kazanılabilecek{" "}
              <span className="text-slate-700">5’er</span> rozeti mevcuttur. Her{" "}
              <span className="text-slate-700">6 doğru</span> yanıtta{" "}
              <span className="text-slate-700">1 rozet</span> kazanırsınız.
              Dönem sonuna kadar{" "}
              <span className="text-slate-700">30 doğru</span> cevap ile bir
              markanın <span className="text-slate-700">5 rozetini</span> de
              kazanabilirsiniz. Toplam{" "}
              <span className="text-slate-700">120</span> doğru cevap ile{" "}
              <span className="text-slate-700">
                4 markanın da 5’er rozetini
              </span>{" "}
              kazanabilirsiniz. İstediğiniz markayı seçip sorusunu
              yanıtladığınızda, soru yanıtlama hakkınız o gün için sona
              erecektir. Her markaya ait 1. sıradaki Rozeti tamamladığınızda 2.
              sıradaki rozet'e geçebilirsiniz.
            </p>
            <div className="absolute bottom-0 left-1/2 flex h-12 w-12 -translate-x-1/2 translate-y-1/2 transform items-center justify-center rounded-full bg-white shadow-lg">
              <IoArrowDown className="h-5 w-5" />
            </div>
          </div>
        </div>
        <div className="mt-14 grid grid-cols-4 gap-4">
          <div className="col-span-4 lg:col-span-3 lg:col-start-2">
            <h4 className="text-center font-semibold text-slate-700">
              Marka Seçiniz
            </h4>
            <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-3">
              {brandBadges && brandBadges.length > 0
                ? brandBadges.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href="/userportal/searchFindStart"
                        onClick={e => handleClickBrand(e, item.id, item)}
                        className="rounded-xl bg-white px-4 py-6 transition-all duration-300 hover:shadow-lg flex flex-col justify-center align-items-center"
                        style={
                          item.answerable === false
                            ? { pointerEvents: "none" }
                            : {}
                        }
                      >
                        <img
                          src={imageShowUrl + item.icon}
                          className="mb-10 mx-auto"
                          style={
                            item.id === 149467
                              ? { height: "66px" }
                              : item.id === 149466
                              ? { height: "58px" }
                              : { height: "53px" }
                          }
                        />

                        <div className="flex items-center justify-between w-100">
                          <span className="text-primary-500 font-semibold">
                            {item.employeeBadge}/{item.totalBadge} Rozet
                          </span>
                          <IoArrowForward className="text-slate-500" />
                        </div>
                        <div className="mt-3 h-2 w-full overflow-hidden rounded-lg bg-slate-200">
                          <div
                            className="h-full rounded-lg bg-primary-500"
                            style={{
                              width:
                                (
                                  (item.employeeBadge * 100) /
                                  item.totalBadge
                                ).toFixed(2) + "%"
                            }}
                          ></div>
                        </div>
                      </a>
                    );
                  })
                : "Rozet Bulunamadı!"}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default SearchFind;
