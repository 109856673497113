import React, { useEffect } from "react";

import NpkLogo from "assets/portal-resource/img/npk-logo.png";
import FaqIcon from "assets/portal-resource/img/icon/faq.png";
import TermConditionsIcon from "assets/portal-resource/img/icon/term-and-conditions.png";
import UserGuideIcon from "assets/portal-resource/img/icon/user-guide.png";

import { Link } from "react-router-dom";
import Accordion from "../../../components/Accordion";

const UserGuide = () => {
  function setClicked(id) {
    let collapseFlag = document.querySelectorAll(
      "a[data-target=" + "'#" + id + "'" + "]"
    )[0].ariaExpanded;
    if (collapseFlag === "false") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  const accordionItemsUserGuide = [
    {
      title: `Benim Bayim Hakkında`,
      content: `<div>
      <h3>Benim Bayim Nedir?</h3>
      Benim Bayim, siz değerli bayilerimiz için hazırlanmış,
      bilgilendirici ve eğlenceli bir platformdur.
      <br />
      <br />
      <h3>Benim Bayim’e Kimler Katılabilir?</h3>
      Tarım ve Orman Bakanlığı Tütün ve Alkol Dairesi
      Başkanlığı'ndan alınmış geçerli Tütün Mamulleri
      Perakende Satış Belgesi'ne sahip olan bayiler bu
      programdan yararlanabilirler.
      <br />
      <br />
      <h3>Benim Bayim’e Nasıl Katılabilirim?</h3>
      Geçerli Tütün Mamulleri Perakende Satış Belgesi
      numaranız, bayi kodunuz ve ERC kodunuz ile üyelik başvuru formunu
      doldurarak başvuru yapabilirsiniz. Girmiş olduğunuz bilgiler
      kontrol edildikten sonra doğru ise başvuru formunda
      belirttiğiniz cep telefonu numaranıza “ERC Kodu” ve
      “Şifre”niz gönderilecektir. Benim Bayim’e giriş
      yapabilirsiniz. Eğer bilgilerinizde hata varsa JTI satış
      temsilciniz ile görüşüp bilgi doğrulaması yaparak
      başvurunuzu tekrarlamalısınız.
      <br />
      <br />
      <h3>Benim Bayim 2023-2024 dönemi ne zaman başlar ve biter?</h3>
      Benim Bayim’in 2023-2024 dönemi 10 Temmuz 2023 - 09 Haziran 2024 tarihleri arasındadır. Bu dönemde kazandığınız puanlarınızı 09 Haziran 2024 
      günü saat 23:59’a kadar harcayabilirsiniz. Harcadıktan sonra hesabınızda kalan ya da çeşitli nedenlerle kullanılmamış tüm puanlar silinir. 
      Silinen puanlara ilişkin JTI’dan hiçbir talepte bulunulamaz.
    </div>`
    },

    {
      title: `Benim Bayim Giriş Bilgileri`,
      content: `<div>
      <h3>Benim Bayim Şifremi Unuttum</h3>
      Şifrenizi unuttuysanız giriş ekranında «Şifremi Unuttum»
      butonunu tıklayın ve açılan kutucukta ‘ERC Kodu’
      bilginizi ve ekranda görünen doğrulama kodunu ilgili
      alana girerek “Şifre Gönder” butonuna basın. Benim
      Bayim’de kayıtlı olan cep telefonu numaranıza giriş
      şifreniz gönderilecektir.
      <br />
      <br />
      <h3>Benim Bayim ERC Kodumu Unuttum</h3>
      ERC Kodunuzu unuttuysanız faturanızın üstünde ERC kodunuzu bulabilirsiniz. ERC Kodunuzu kullanarak şifreniz ile giriş yapabilirsiniz.
    </div>`
    },

    {
      title: `Markalarımız`,
      content: `Bu bölümde Stiller ve Tarihçe bölümleri aracılığıyla JTI markalarını daha yakından tanıma fırsatı bulabilirsiniz.`
    },

    {
      title: `Benim Bayim Dükkanı`,
      content: `<div>
      <p>Benim Bayim'de kazandığınız puanları harcayabileceğiniz bölümdür. 
      Hediyeler hakkında detaylı bilgi almak için yapmanız gereken tek şey hediyenin altındaki Detaylı İncele butonunu tıklayarak ürün detay sayfasına ulaşmak. 
      Ürün detay sayfasında hediyenizle ilgili tüm bilgileri ve şartları okuduysanız Sipariş Ver butonuna tıklayarak hediye alım işleminize devam edebilirsiniz. 
      Sipariş Özeti sayfasında hediyenizin gönderileceği adres bilgisini mutlaka kontrol ediniz ve doğru adresi seçtiğinizden emin olunuz. Aşağıdaki koşulları ve şartları okudum onaylıyorum. 
      metnini okuyup kutusunu işaretledikten sonra Siparişi Onayla butonuna tıklayarak sipariş verme işleminizi tamamlayabilirsiniz. 
      Eğer adres bilgileriniz yanlış ise ana sayfanın sağ üst köşesindeki menüde bulunan Adres Bilgilerim bölümünde bilgilerinizi güncelleyebilirsiniz.</p>
      <br />
      <p>Benim Bayim’den siparişini vermiş olduğunuz ürünler, sipariş verildikten sonra yirmi (25) iş günü (resmi tatiller ve bayram tatilleri ile haftasonu günleri iş günlerine dahil değildir) içinde sistemde kayıtlı bulunan ve Sipariş Adresi olarak seçilmiş olan adresinize teslim edilecektir. Bu nedenle siparişlerinizi vermeden önce adres bilgilerinizi kontrol etmeli ve gerektiğinde güncellemelisiniz. Sipariş ettiğiniz ürünler, size kargo aracılığı ile ulaştırılacaktır ve kargo ücreti alınmayacaktır.</p>
      <br />
      <p>Teslimat sırasında siparişinizi açarak sağlamlığını kontrol ediniz. Herhangi bir hasar tespit edildiğinde, siparişinizi teslim eden kargo firması ile birlikte tutanak tutmanız gerekmektedir. Tutanak tutulduktan sonra 0850 532 5 584 (JTI) numaralı Çağrı Merkezi’mizi arayarak iade sürecini başlatabilirsiniz. Tutanak tutulmaması durumunda tarafımıza yapılacak hasarlı ürün iadeleri ve itirazlar kabul edilmeyecektir.</p>
      <br />
      <p>Benim Bayim Dükkanı’nda bir dönem içerisinde en fazla 3 adet hediye siparişi verebilirsiniz. Aşağıda puan kazanımı ve hediye siparişi ile ilgili şartların bir kısmını bulabilirsiniz. Tüm yasal şartların detaylarını Şart ve Koşullar sayfasında görebilirsiniz. Haklarınızdan en doğru şekilde faydalanmak için bu Şart ve Koşulları incelemeniz önemlidir.</p>
      <br />
      <p>
        <strong>1.</strong> Kazandığınız puanları Benim Bayim Dükkanı'nda kullanabilirsiniz.<br />
        <strong>2.</strong> Puan kazanma ve harcama 09 Haziran 2024 tarihine kadar devam edecektir.<br />
        <strong>3.</strong> Kazanılan puanlar 09 Haziran 2024 saat 23:59'a kadar harcanabilir. 09 Haziran 2024 saat 23:59'a kadar kullanılamamış tüm puanlar silinir. Silinen puanlara ilişkin JTI’dan hiçbir talepte bulunulamaz.<br />
        <strong>4.</strong> JTI gerekli gördüğü durumlarda Benim Bayim Dükkanı’nda yer alan ürünlerin puanlarını değiştirme veya dilediği ürünü, dilediği zaman Benim Bayim Dükkanı’ndan kaldırma hakkına sahiptir. Sitede yer alan ürünler tedarikçi stokları ile sınırlıdır. Stokların tükenmesi durumunda bayilerin herhangi bir hak iddiasında bulunması mümkün değildir.<br />
        <strong>5.</strong> JTI, Benim Bayim Dükkanı’nda yer alan ürünlerin tedariğinde sorun çıkması halinde ürünlerin muadilini veya bir üst modelini gönderebilir. Size gönderilen ürünün Benim Bayim Dükkanı’nda sergilenen ürün olmadığına dair JTI’dan hak talep etmeniz mümkün değildir.<br />
        <strong>6.</strong> Üçüncü şahıslardan puan satın alamaz, hesabınızdaki puanları para karşılığında satamaz, hiçbir suretle devredemez ve birden fazla noktaya ait birden fazla kullanıcınız varsa bu hesapların puanlarını birleştiremezsiniz. Puanları birleştirme veya devretmeye yönelik herhangi bir girişimde bulunmanız durumunda, Benim Bayim üyeliğinden çıkartılırsınız. Bu nedenlerden dolayı Benim Bayim’den çıkartıldığınız takdirde hesabınızdaki bütün puanlar silinecek ve gerekli görülen durumlarda hesabınız süresiz olarak kapatılacaktır.<br />
        <strong>7.</strong> Puan karşılığı aldığınız hediyeleri iade edemez veya değiştirilmesini talep edemezsiniz. Kazandığınız puanları Benim Bayim Dükkanı’nda sunulan maç ya da etkinlik biletleri için kullanmanız halinde, etkinliğin herhangi bir sebeple iptal edilmesi veya Benim Bayim’de belirtilen tarihten başka bir tarihe ertelenmesi halinde kullandığınız puanlar iade edilmeyecektir.
      </p>
      <br />
      <p>
        Puan karşılığı aldığınız ürünlerin kalite sorumluluğu üretici, ithalatçı ve satış firmasına aittir, dolayısıyla ürünlerle ilgili garanti kapsamındaki olası şikayetlerinizi bu firmalara yapmanız gerekmektedir.
      </p>
      <br />
      <p>
      Kullanıcının siteye erişim hakkı, Kullanıcının Satış Belgesi’nin, yenileme işleminin yapılmaması veya herhangi bir diğer nedenle iptal edilmesi halinde Satış Belgesi’nin iptal tarihinden, ticari faaliyetine son vermesi halinde ise Satış Belgesi iptal edilmemiş olsa dahi ticari faaliyetine son verdiği tarihten itibaren geçerli olmak üzere sona erecek olup, Kullanıcı’nın bu durumlarda siteye girmeye devam etmesi halinde kazandığı puanlar geçerli olmayacak, bu tarihe kadar Site’de kazandığı tüm puanlar sıfırlanacak ve bu tarihten sonra hediye sipariş etmiş olsa dahi hediyeler kendisine gönderilmeyecektir.
      </p>
      <br />
      <h3>Yarışmalar</h3>
      <h3>Rozet Macerası</h3>
      <p>JTI ürünleri ile ilgili bilgi yarışmasıdır. Her hafta Pazartesi, Çarşamba ve Perşembe günleri karşınıza çıkar. Soruyu 90 saniye içinde doğru yanıtlarsanız 100 puan kazanırsınız. Ayrıca Rozet Macerası sorularından marka rozetleri kazanırsınız. Rozet Macerası'nda her markanın kazanılabilecek 5'er rozeti mevcuttur. Her 6 doğru yanıtta 1 rozet kazanırsınız. Her hafta 3 soru cevaplama hakkı ile rozetler biriktirilmeye devam edilecektir. Dönem sonuna kadar 30 doğru cevap ile bir markanın 5 rozetini de kazanabilirsiniz. Toplam 120 doğru cevap ile 4 markanın da 5'er rozetini kazanabilirsiniz. Rozet Macerası sayfasında istediğiniz markayı seçip sorusunu yanıtladığınızda, soru yanıtlama hakkınız o gün için sona erecektir. Her markaya ait 1. sıradaki Rozeti tamamladığınızda 2. sıradaki rozet'e geçebilirsiniz.</p>
      <br />
      <h3>Puan Avı</h3>
      <p>JTI ürünleri ile ilgili bilgi yarışmasıdır. Her hafta Salı, Çarşamba ve Cuma günleri karşınıza çıkar.  Puan Avı sorusu her seferinde farklı bir markanın stillerinden birinde gizlidir. Başla butonuna tıkladığınızda otomatik olarak sorunun o gün gizlendiği markanın sayfasına yönlendirilirsiniz. Puan Avı ikonunu bulana kadar o markanın tüm stillerini inceleyin. Puan Avı ikonunu bulduğunuzda ikonu tıklayarak soruya ulaşın. 90 saniye içinde doğru cevabı verin, 100 puan kazanın.</p>
      <br />
      <h3>Eşle Kazan</h3>
      <p>JTI ürünleri ile ilgili bilgi yarışmasıdır. Her hafta Pazartesi, Salı, Perşembe ve Cuma günleri karşınıza çıkar. JTI ürünleri ile bu ürünlere ait özellikleri doğru olarak eşleştirip 100 puan kazanın.</p>
      <br />
      <h3>Haftanın Dükkanı</h3>
      <p>Benim Bayim üyelerinin yüklediği dükkan fotoğraflarının oylanması yarışmasıdır. Haftalık olarak düzenlenir. Her hafta 8 farklı bayimizin dükkan fotoğraflarını görebilir, bu fotoğraflardan en çok beğendiğinize haftada bir kez oy vererek 200 puan kazanırsınız. Dilerseniz birden fazla kez oy kullanabilirsiniz ancak sadece ilk oylamadan puan kazanırsınız. Her hafta en çok oy alan fotoğraf Haftanın Dükkanı seçilecek ve Haftanın Kazananları bölümünde ilan edilecektir. Haftanın Dükkanı seçilen fotoğrafın sahibi dükkan fotoğrafını, seçilmiş olduğu hafta boyunca güncelleyemeyecektir.</p>
      <br />
      <h3>Stand Puanı</h3>
      <p>Bayinizde bulunan JTI standının çeşidine göre puan kazanırsınız. Detaylı bilgi için Nasıl Puan Kazanırım bölümünden faydalanabilirsiniz.</p>
    </div>`
    },

    {
      title: `Benim Bayim Dünyası`,
      content: `<div>
      <h3>JTI Hakkında</h3>
      Bu bölümde JTI ve JTI Türkiye hakkında detaylı bilgiler
      edinebilirsiniz.
      <br />
      <br />
      <h3>Benim Bayim Dergi</h3>
      Bu bölümde her hafta Turizm, Sağlıklı Beslenme,
      Otomotiv, Teknoloji ve Bilgisayar, Ekonomi ve Finans ile
      Yeme İçme & Gurme konularında çeşitli makaleleri
      bulabilirsiniz.
      <br />
      <br />
      <h3>Bizim Meydan</h3>
      Bizim Meydan’da her hafta farklı bir konuda
      fikirlerinizi, yorumlarınızı veya tartışmak istediğiniz
      konuları paylaşabilirsiniz. Tüm yorumlar yayınlanmadan
      önce gözden geçirilir, anında yayınlanmaz. Yorum
      yapmadan önce Bizim Meydan Kullanım Koşulları’nı
      incelemenizi tavsiye ederiz.
      <br />
      <br />
      <h3>Haberler</h3>
      Bu bölümde Dünya ve Türkiye’deki spor, teknoloji ve
      sağlık haberlerini en güncel haliyle takip edebilir,
      döviz, altın kur durumlarına anında erişebilirsiniz.
      Aynı zamanda dünyadan futbol, basketbol ligleri ve spor
      ile ilgili eğlenceli görsel ve video içeriklerini de
      takip edebilirsiniz.
      <br />
      <br />
      <h3>Benim Bayim TV</h3>
      Benim bayim TV'de Teknoloji, Otomotiv ve Hukuk
      konularıyla ilgili güncel videolar izleyebilirsiniz.
    </div>`
    },

    {
      title: `Tek Tip Düz Paket`,
      content: `<p>
      Sigara paketlerinin üzerinde herhangi bir marka logosu,
      marka rengi ve markayı andıran herhangi bir işaret
      kullanmayı engelleyen tek tip düz paket uygulaması 5
      Ocak 2020 tarihi itibarı ile Türkiye'de uygulanmaya
      başlandı.
      <br />
      Tek tip düz paket uygulaması ile ilgili bilmek
      istediğiniz herşeyi, ayrıca eski ve yeni paket
      görsellerini bu bölümde bulabilirsiniz.
    </p>`
    },

    {
      title: `Profil Bilgilerim`,
      content: `Benim Bayim ana sayfasının sağ üst kısmındaki, ad - soyadınızın olduğu bölüme tıkladığınızda açılan menü aracılığıyla Hesap bilgilerinizi, mesajlarınızı, var ise yüklemiş olduğunuz profil ve dükkan fotoğraflarınızı görebilirsiniz. Profil fotoğrafı yükleyebilirsiniz.`
    }
  ];

  return (
    <section>
      <div className="container-fluid mx-auto text-center page-head">
        <h3>Kullanım Klavuzu</h3>
      </div>

      <div className="container mx-auto my-20">
        <div className="flex flex-col md:flex-row w-100">
          <div className="basis-full md:basis-3/12">
            <div className="detail-page-left-menu">
              <ul>
                <li>
                  <Link to={`/userportal/faq`} className="font-medium">
                    Sıkça Sorulan Sorular
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/tac`} className="font-medium">
                    Şartlar Koşullar
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/score`} className="font-medium">
                    Nasıl Puan Kazanırım
                  </Link>
                </li>

                <li className="active">
                  <Link to={`/userportal/userGuide`} className="font-medium">
                    Kullanım Kılavuzu
                  </Link>
                </li>
                <li>
                  <Link to={`/userportal/announcement`} className="font-medium">
                    Duyurular
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="basis-full md:basis-9/12 pl-10">
            <Accordion accordionItems={accordionItemsUserGuide} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default UserGuide;
