import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { newsAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

// Stylesheet
import "./Style.scss";

// Images
import categorySport from "../../../assets/images/news/category/sport.png";
import categoryTechnology from "../../../assets/images/news/category/technology.png";
import categoryHealth from "../../../assets/images/news/category/health.png";

// Pagination
import ReactPagination from "react-js-pagination";

// Swiper
import Swiper from "swiper";

// HTML PARSE IN STRING
import parse from "html-react-parser";

const News = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(store => store.news.isLoading);
  const newsLoading = useSelector(store => store.news.newsLoading);
  const financeList = useSelector(store => store.news.financeList);
  const newsByCategory = useSelector(store => store.news.newsByCategory);
  const newsByCategoryContent = useSelector(
    store => store.news.newsByCategoryContent
  );

  // Active Tab
  const [activeTab, setActiveTab] = useState("SPORT");

  // Pagination Things
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const handlePageChange = page => {
    if (page && activeTab) {
      dispatch(newsAction.getNewsByCategory(activeTab, page - 1, 9));
      if (
        newsByCategoryContent &&
        newsByCategoryContent.totalPages &&
        page > parseInt(newsByCategoryContent.totalPages)
      ) {
        dispatch(
          newsAction.getNewsByCategoryContent(
            activeTab,
            parseInt(newsByCategoryContent.totalPages) - 1,
            20
          )
        );
      } else {
        dispatch(newsAction.getNewsByCategoryContent(activeTab, page - 1, 20));
      }
    }
  };

  const handleChangeEvent = event => {
    setActiveTab(event);

    if (event === "TECHNOLOGY") {
      dispatch(newsAction.getNewsByCategory("TECHNOLOGY", 0, 9));
      dispatch(newsAction.getNewsByCategoryContent("TECHNOLOGY", 0, 20));
    } else if (event === "HEALTH") {
      dispatch(newsAction.getNewsByCategory("HEALTH", 0, 9));
      dispatch(newsAction.getNewsByCategoryContent("HEALTH", 0, 20));
    } else {
      dispatch(newsAction.getNewsByCategory("SPORT", 0, 9));
      dispatch(newsAction.getNewsByCategoryContent("SPORT", 0, 20));
    }
  };

  useEffect(() => {
    // Scroll Top
    window.scrollTo(0, 0);

    // Finance
    dispatch(newsAction.getFinance());

    // CATEGORY / PAGE / SIZE
    dispatch(newsAction.getNewsByCategory("SPORT", 0, 9));
    dispatch(newsAction.getNewsByCategoryContent("SPORT", 0, 20));
    return () => {};
  }, []);

  useEffect(() => {
    if (newsByCategory) {
      setActivePage(newsByCategory.number);
      setTotalPages(newsByCategory.totalPages);
      setTotalItems(newsByCategory.totalElements);
    }

    return () => {};
  }, [newsByCategory]);

  const newsData = (
    <div className="news">
      <div className="row mw-100 p-0 m-0">
        <div className="col-12 col-md-8 p-0">
          <div className="row mw-100 p-0 m-0 imageable-content">
            {newsByCategoryContent &&
              newsByCategoryContent.anatolianAgencyNewsDTOS &&
              newsByCategoryContent.anatolianAgencyNewsDTOS.length > 0 &&
              newsByCategoryContent.anatolianAgencyNewsDTOS.map(itemContent => {
                return (
                  <div className="col-12 col-md-6">
                    <Link
                      className={"d-block"}
                      to={{
                        pathname: `/userportal/news/${itemContent.id}`
                      }}
                      data-id={`list-${itemContent.id}`}
                    >
                      {itemContent.image && (
                        <img
                          src={`data:image/jpeg;base64,${itemContent.image}`}
                        />
                      )}

                      <span className="swiper-slide-title">
                        {itemContent.title && itemContent.title}
                      </span>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="col-12 col-md-4">
          <ul className="news-list p-0">
            {newsByCategory &&
              newsByCategory.content &&
              newsByCategory.content.length > 0 &&
              newsByCategory.content.map(item => {
                return (
                  <li key={item.id} className="mb-3">
                    {item.title && (
                      <Link
                        className="text-center text-desc"
                        to={{ pathname: `/userportal/news/${item.id}` }}
                      >
                        {item.title}
                      </Link>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <div className="super-sport-page">
      <GlobalLoader isLoading={isLoading} />
      <GlobalLoader isLoading={newsLoading} />

      {financeList && (
        <div className="finance">
          {financeList.length > 0 &&
            financeList.map((item, index) => {
              return (
                <div key={item.name} className="finance-content">
                  <span>{item.name}</span>

                  <span>
                    {item.diffDayPer > 0 ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{
                          fontSize: "16px",
                          color: "#01af65"
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{
                          fontSize: "16px",
                          color: "#af0000"
                        }}
                      />
                    )}
                  </span>

                  <span>{item.currentValue}</span>
                </div>
              );
            })}
        </div>
      )}

      <div className="news-tab">
        <div className="container">
          <div className="row mw-100">
            <div className="col">
              <Tab.Container id="left-tabs-example" defaultActiveKey="SPORT">
                <div className="row">
                  <div className="col-12">
                    <Nav
                      variant="pills"
                      className="flex-row justify-content-center"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="SPORT"
                          onSelect={e => handleChangeEvent(e)}
                        >
                          <div>
                            <img src={categorySport} alt="SPOR" />
                            <span>SPOR</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="TECHNOLOGY"
                          onSelect={e => handleChangeEvent(e)}
                        >
                          <div>
                            <img src={categoryTechnology} alt="TEKNOLOJİ" />
                            <span>TEKNOLOJİ</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="HEALTH"
                          onSelect={e => handleChangeEvent(e)}
                        >
                          <div>
                            <img src={categoryHealth} alt="SAĞLIK" />
                            <span>SAĞLIK</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <div className="col-12">
                    <Tab.Content>
                      <Tab.Pane eventKey="SPORT">{newsData}</Tab.Pane>
                      <Tab.Pane eventKey="TECHNOLOGY">{newsData}</Tab.Pane>
                      <Tab.Pane eventKey="HEALTH">{newsData}</Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>

          <div className="row mw-100 justify-content-center">
            <div className="col wrapper-pagination">
              <ReactPagination
                activePage={activePage + 1}
                totalItemsCount={totalItems}
                itemsCountPerPage={9}
                pageRangeDisplayed={
                  totalPages !== null && totalPages > 5 ? 5 : totalPages
                }
                itemClass="page-item"
                linkClass="page-link"
                onChange={handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default News;
