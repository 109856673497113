import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import IllegalTradeImg from "assets/portal-resource/img/illegal-trade-img.png";

const IllegalTrade = props => {
  return (
    <section className="about-page about-3-page bbd-menu-active">
      <div className="page-container">
        <div className="about-text">
          <h2>Yasa Dışı Ticaret</h2>
          <div className="about-bottom-img">
            <img src={IllegalTradeImg} alt="" />
          </div>
          <p>
            Yasa dışı ticaret; malların üretimi, ithalatı, ihracatı, satın
            alması, satışı veya temin edilmesinin mevzuata uymayan bir şekilde
            yapılması anlamına gelmektedir. Türkiye pazarı, tütün ürünleri
            üzerindeki vergi yükü ve buna bağlı olarak fiyatların komşu ülkelere
            oranla yüksek olması nedeniyle yasa dışı sigara ticareti açısından
            oldukça cazip bir konumdadır. JTI Türkiye’nin de üye olduğu Tütün
            Sanayicileri Derneği 2007 yılından bu yana sigarada yasa dışı
            ticaret oranının gelişimini izlemek üzere “Boş Paket Araştırması”
            çalışmasını gerçekleştirmektedir. 10.000 sigara paketinin
            sokaklardan toplanması ve incelenmesine dayanan bu çalışma 2019 yılı
            itibarıyla Türkiye’de yasa dışı sigara oranının pazarın 2,6'sı
            seviyesinde olduğunu göstermektedir. Yasa dışı sigara oranındaki
            azalmada, etkin kaçakçılıkla mücadele uygulamalarının yanı sıra,
            sarmalık kıyılmış tütünün yurt içinde yasa dışı ticaretindeki
            artışın da etkili olduğu gözlenmektedir. Ayrıca, tek tip pakete
            geçişle birlikte, daha önce sık rastlanmayan sahte sigaralarda da
            artış olması riski gündeme gelmiştir. JTI, kaçak ve sahte ürünlere
            karşı “sıfır tolerans” yaklaşımını desteklemekte ve iç
            prosedürlerini buna göre belirlemektedir. Bu prosedürler, JTI
            müşterilerinin ve tedarik zinciri kontrolünün yanı sıra ürün
            sevkiyatı, JTI çalışanları ve çalışma alanlarının güvenliğini de
            sağlamaktadır.
            <br />
            <br />
            JTI, kaçak ürünlere karşı dâhili kontrollerinin temelini oluşturan
            bir dizi önlemi içeren “Yasa Dışı Ticaretle Mücadele” programını
            geliştirmiştir. Müşterini Tanı Programı, JTI’ın pazar içindeki
            satışlarını izlemesini ve müşterilerinin o pazardaki ticari
            faaliyetlerini kapsamlı bir şekilde kontrol etmesini sağlamaktadır.
            Tedarikçini Tanı Programı, iş ilişkisi başlamadan önce
            tedarikçilerin JTI’ın iş bütünlüğü standartlarına uymalarını ve iş
            ilişkisi başladıktan sonra düzenli olarak denetlenmelerini sağlar.
            Kara Para Aklanmasıyla Mücadele Programı çerçevesinde JTI müşteri ve
            satıcı ödemelerinin yalnızca yasal kanallardan bankalar aracılığıyla
            yapılması sağlanmaktadır. Ürün Takip ve İzleme Sistemi, tedarik
            zincirini kontrol altında tutmayı hedeflemekte ve orijinal ürünlerin
            yasal tedarik zincirinden saptırılıp başka bir pazara
            yönlendirilmesini engellemeyi amaçlamaktadır.
          </p>
        </div>
      </div>
    </section>
  );
};
export default IllegalTrade;
