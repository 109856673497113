import * as disclaimerTypes from "./disclaimer.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getDisclaimerByFirmId = (
  id,
  employeeId,
  isHandleRoute = false
) => async dispatch => {
  dispatch({ type: disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_PENDING });
  try {
    const response = await API.get(
      `${REQUEST_URL}/disclaimer/${id}/${employeeId}`,
      config
    );
    if (isHandleRoute) {
      if (!response.data[0].checkedDisclaimerInTheWeek) {
        return Promise.resolve(response);
      } else {
        return false;
      }
    }
    dispatch({
      type: disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const saveDisclaimer = (
  disclaimer,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({ type: disclaimerTypes.SAVE_DISCLAIMER_CHECK_PENDING });

  const data = { disclaimer, employeeFirmId, firmId };
  try {
    const response = await API.post(`/disclaimer/save`, data);

    dispatch({
      type: disclaimerTypes.SAVE_DISCLAIMER_CHECK_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: disclaimerTypes.SAVE_DISCLAIMER_CHECK_REJECTED,
      payload: error
    });
  }
};
