import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import showErrMessage from "hooks/showErrMessage";
import { API } from "services/http.service";
import { imageShowUrl } from "../../../../config/api.config";
import "./question.scss";

const Question = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const competitionList = useSelector(store => store.game.competitionList);
  const controlCompetition = useSelector(
    store => store.game.controlCompetition
  );

  var disclaimer = null;
  var competition = null;

  const [seconds, setSeconds] = useState();
  const [isActive, setIsActive] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const choiceList = [];
  const [selectedChoice, setSelectedChoice] = useState([]);
  const [firstQuestionItem, setFirstQuestionItem] = useState();
  const [questionId, setQuestionId] = useState();
  const [questionList, setQuestionList] = useState([]);
  const [errorQuestionList, setErrorQuestionList] = useState(false);
  const [errorRemainTime, setErrorRemainTime] = useState(false);
  const [answerState, setAnswerState] = useState(true);
  const [questionAnswerState, setQuestionAnswerState] = useState(false);
  const [questionStep, setQuestionStep] = useState(1);
  const [answerMessage, setAnswerMessage] = useState(null);
  const [competetionTime, setCompetetionTime] = useState(false);
  const [competetionTimeLoad, setCompetetionTimeLoad] = useState(false);
  const [competetionTimeText, setCompetetionTimeText] = useState("");
  const [answeredList, setAnsweredList] = useState(null);

  const [reward, setReward] = useState(false);
  const [disclaimerList, setDisclaimerList] = useState([]);

  const [competitionRewardImage, setCompetitionRewardImage] = useState("");
  const [competitionSuccessImage2, setCompetitionSuccessImage2] = useState("");
  const [competitionErrorImage2, setCompetitionErrorImage2] = useState("");
  const [competitionTimeImage2, setCompetitionTimeImage2] = useState("");

  const [competitionSuccessImage3, setCompetitionSuccessImage3] = useState("");
  const [competitionErrorImage3, setCompetitionErrorImage3] = useState("");
  const [competitionErrorImage4, setCompetitionErrorImage4] = useState("");
  const [competitionErrorImage5, setCompetitionErrorImage5] = useState("");

  const getQuestion = useCallback(
    questionId => {
      const compId = localStorage.getItem("compId");

      dispatch(
        gameAction.getCompetitionByFirmId(
          employeeFirm.firm.id,
          compId,
          employeeFirm.id,
          questionId
        )
      ).then(e => {
        if (e.data) {
          setIsActive(true);
          e.data.map(item => {
            setSeconds(item.remainTime);
            setLoaded(true);
            if (item.remainTime <= 0) {
              setErrorRemainTime(true);
            }
          });
        }
      });
    },
    [dispatch, employeeFirm.firm.id, employeeFirm.id]
  );

  const getQuestionList = useCallback(() => {
    // soru listesini getir
    dispatch(
      gameAction.getControlCompetitionByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    )
      .then(response => {
        if (!global._.isEmpty(response)) {
          let questionList = global._.sortBy(response.data, [
            function(o) {
              return o.questionRank;
            }
          ]);

          // time control begin
          let currentDate = new Date();

          let firstQuestion = questionList[0];
          setFirstQuestionItem(questionList[0]);

          let arr = questionList[0].startTime.split(/[- :]/),
            startDate = new Date(
              arr[0],
              arr[1] - 1,
              arr[2],
              arr[3],
              arr[4],
              arr[5]
            );

          let arr1 = questionList[0].endTime.split(/[- :]/),
            endDate = new Date(
              arr1[0],
              arr1[1] - 1,
              arr1[2],
              arr1[3],
              arr1[4],
              arr1[5]
            );

          if (questionList[0].activeQuestion) {
            setCompetetionTime(false);
            setCompetetionTimeLoad(true);
          } else {
            setCompetetionTimeText(firstQuestion.answeredTodayQuestions);
            if (currentDate < startDate) {
              setCompetetionTimeText(firstQuestion.notStartedText);
              setCompetetionTime(true);
            }

            setCompetetionTimeLoad(true);
          }

          // time control end

          let questionObj = questionList[0];
          if (!questionList[0].answerable && !questionList[1].answerable) {
            setErrorQuestionList(true);
          }

          setQuestionList(questionList);

          if (
            !questionList[0].answerable &&
            !questionList[1].answerOfFirstQuestion
          ) {
            setErrorQuestionList(true);
          }

          if (
            questionList[0].enteredTodayQuestion &&
            !questionList[0].answerable &&
            questionList[0].answeredTodayQuestion &&
            questionList[1].answerOfFirstQuestion &&
            !questionList[1].enteredTodayQuestion
          ) {
            setQuestionStep(2);
          } else {
            // soru listesi dolu ise

            let questionId = questionList[0].questionId;
            if (questionList[1].answerOfFirstQuestion) {
              questionId = questionList[1].questionId;
              setQuestionStep(3);
            }

            setQuestionId(questionId);
            getQuestion(questionId);
          }

          // soru listesi dolu ise
        } else {
          // soru cekilirken hata olustu
          setErrorQuestionList(true);
          setLoaded(true);
        }
      })
      .catch(error => {
        setErrorQuestionList(true);
        setLoaded(true);
      });
  }, [dispatch, employeeFirm.firm.id, employeeFirm.id, getQuestion]);

  useEffect(() => {
    dispatch(
      gameAction.getCompetitionDisclaimerByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    ).then(response => {
      let disclaimerList = response.data;

      setCompetitionSuccessImage2(disclaimerList[0].answeredTrueImage);
      setCompetitionSuccessImage3(disclaimerList[0].noRewardImage);
      setCompetitionErrorImage2(disclaimerList[0].answeredFalseImage);
      setCompetitionErrorImage3(disclaimerList[0].answeredBeforeDayImage);
      setCompetitionErrorImage4(disclaimerList[0].wonBeforeImage);
      setCompetitionErrorImage5(disclaimerList[0].notStartedImage);
      setCompetitionTimeImage2(disclaimerList[0].answerTimeOutImage);
      setCompetitionRewardImage(disclaimerList[0].getRewardImage);

      let load = false;
      disclaimerList.forEach(disclaimer => {
        if (disclaimer.checkedDisclaimerCompetition) {
          load = true;
        }
      });

      if (load) {
        getQuestionList();
      }

      setLoaded(true);
      setDisclaimerList(response.data);
    });
    const answeredList = async () => {
      const compResponse = await API.get(`competition/${employeeFirm.firm.id}`);
      setAnsweredList(compResponse);
    };

    if (!answeredList) {
      answeredList();
    }
    return () => {};
  }, [dispatch, employeeFirm.firm.id, employeeFirm.id, getQuestionList]);

  function isCorrectAnswer(firmId, id, questionId) {
    let currentQuestionId = questionId;

    dispatch(gameAction.getResultCompetition(firmId, id, questionId)).then(
      response => {
        setQuestionAnswerState(true);
        let answerList = response.data;

        answerList.forEach(answer => {
          if (answer.questionId === currentQuestionId) {
            if (answer.answerFalse) {
              setAnswerState(false);
              setAnswerMessage(answer.userScreenText);
            }

            if (answer.answerTrue) {
              if (answer.reward) {
                setReward(true);
              }
              setAnswerState(true);
              setAnswerMessage(answer.userScreenText);
              window.localStorage.setItem(
                "answerMessage",
                answer.userScreenText
              );
              // getQuestion(questionId);
              if (questionStep === 3) {
              } else {
                setQuestionStep(2);
              }
            } else if (answer.answerTimeOut) {
              setAnswerMessage(answer.userScreenText);
              setErrorRemainTime(true);
            }
          }
        });
      }
    );
  }

  function getQuestionTwo() {
    setQuestionAnswerState(false);
    setQuestionStep(3);
    let questionId = questionList[1].questionId;
    getQuestion(questionId);
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  function stopTimer() {
    setIsActive(false);
  }

  function stopTimerToZero(question, questionChoice, employeeFirmId, firmId) {
    setSeconds(0);
    setIsActive(false);
    questionChoice = null;

    dispatch(
      gameAction.saveCompetitionAnswer(
        question,
        questionChoice,
        employeeFirmId,
        firmId
      )
    )
      .then(e => {
        isCorrectAnswer(firmId, employeeFirmId, question);
      })
      .catch(err => {
        showErrMessage("Seçenek seçiniz.");
      });
  }

  function onClickOption(value, choiceId) {
    choiceList.push(choiceId);
    setSelectedChoice(choiceList);
    dispatch(gameAction.changeOption(value));
  }

  function showChoices(choicesList) {
    var questionChoices = null;
    questionChoices = choicesList.map((itemChoice, index) => (
      <div
        className="col-12"
        key={itemChoice.id}
        onClick={() => onClickOption(index + 1, itemChoice.id)}
      >
        <div className={index === 0 ? "form-check mt-2" : "form-check mt-2"}>
          <input
            className={`form-check-input radio-button-${index}`}
            type="radio"
            name="question"
            id={itemChoice.id}
            value={itemChoice.id}
          />
          <label
            className="form-check-label"
            htmlFor={itemChoice.id}
            style={{ fontFamily: "Boxed Book" }}
          >
            {itemChoice.name}
          </label>
        </div>
      </div>
    ));
    return questionChoices;
  }

  function saveCompetition(
    question,
    questionChoice,
    employeeFirmId,
    firmId,
    e
  ) {
    if (questionChoice.length <= 0) {
      showErrMessage("Seçenek seçiniz.");
      e.preventDefault();
    } else {
      stopTimer();
      dispatch(
        gameAction.saveCompetitionAnswer(
          question,
          questionChoice,
          employeeFirmId,
          firmId
        )
      )
        .then(e => {
          isCorrectAnswer(firmId, employeeFirmId, question);
          setSelectedChoice([]);
          dispatch(gameAction.changeOption(null));
        })
        .catch(err => {
          isCorrectAnswer(firmId, employeeFirmId, question);
          showErrMessage("Seçenek seçiniz.");
        });
    }
  }

  if (competetionTime) {
    return (
      <div
        style={{
          color:
            disclaimerList &&
            disclaimerList[0] &&
            disclaimerList[0].primaryColor
              ? disclaimerList[0].primaryColor
              : "#FFF"
        }}
      >
        <div
          className="answer-text"
          id="competetion-answer-1"
          dangerouslySetInnerHTML={{ __html: competetionTimeText }}
        />
        <div className="text-center">
          <img
            src={imageShowUrl + competitionErrorImage5}
            className="question-result-image mx-auto"
            alt=""
          />
        </div>
        <div className="d-flex justify-content-center">
          <a
            href="/"
            className="btn mt-4"
            style={{
              borderRadius: "32px",
              background: disclaimerList[0]?.choiceOutColor
            }}
          >
            BENİM BAYİM'E GERİ DÖN
          </a>
        </div>
      </div>
    );
  }

  var buttonLink;
  if (questionStep == 2) {
    buttonLink = (
      <button
        onClick={e => getQuestionTwo()}
        className="btn mt-4"
        style={{
          borderRadius: "32px",
          background: disclaimerList[0]?.choiceOutColor
        }}
      >
        İKİNCİ SORUYU GÖRMEK İSTİYORUM
      </button>
    );

    if (!answerMessage) {
      let localMessage = window.localStorage.getItem("answerMessage");

      if (localMessage) {
        setAnswerMessage(localMessage);
      } else {
        dispatch(
          gameAction.getResultCompetition(
            1,
            employeeFirm.id,
            firstQuestionItem.questionId
          )
        ).then(res => {
          setAnswerMessage(res.data[0].userScreenText);
        });
      }
    }
  }

  if (questionStep == 3) {
    buttonLink = (
      <a
        href="/"
        className="btn mt-4"
        style={{
          borderRadius: "32px",
          background: disclaimerList[0]?.choiceOutColor
        }}
      >
        BENİM BAYİM'E GERİ DÖN
      </a>
    );
  }

  if (questionStep == 2) {
    return (
      <div
        style={{
          color:
            disclaimerList &&
            disclaimerList[0] &&
            disclaimerList[0].primaryColor
              ? disclaimerList[0].primaryColor
              : "#FFF"
        }}
      >
        <div
          className="answer-text"
          id="competetion-answer-2"
          dangerouslySetInnerHTML={{ __html: answerMessage }}
        />
        <div className="text-center">
          <img
            src={imageShowUrl + competitionSuccessImage2}
            className="question-result-image mx-auto burasiRewardDegil"
          />
        </div>
        <div className="d-flex justify-content-center">{buttonLink}</div>
      </div>
    );
  }

  if (loaded) {
    if (
      answerState &&
      questionAnswerState &&
      questionStep == 3 &&
      !errorRemainTime
    ) {
      return (
        <div
          style={{
            color:
              disclaimerList &&
              disclaimerList[0] &&
              disclaimerList[0].primaryColor
                ? disclaimerList[0].primaryColor
                : "#FFF"
          }}
        >
          <div
            className="answer-text"
            id="competetion-answer-3"
            dangerouslySetInnerHTML={{ __html: answerMessage }}
            style={{ padding: "0 20px" }}
          />
          <div className="text-center">
            {reward === true ? (
              <img
                src={imageShowUrl + competitionRewardImage}
                className="question-result-image mx-auto burasiReward"
              />
            ) : (
              <img
                src={imageShowUrl + competitionSuccessImage3}
                className="question-result-image mx-auto burasiRewardDegil"
              />
            )}
          </div>
          <div className="d-flex justify-content-center">{buttonLink}</div>
        </div>
      );
    }
    if (!answerState && questionAnswerState) {
      return (
        <div
          style={{
            color:
              disclaimerList &&
              disclaimerList[0] &&
              disclaimerList[0].primaryColor
                ? disclaimerList[0].primaryColor
                : "#FFF"
          }}
        >
          <div
            className="answer-text"
            id="competetion-answer-4"
            dangerouslySetInnerHTML={{ __html: answerMessage }}
          />
          <div className="text-center">
            <img
              src={imageShowUrl + competitionErrorImage2}
              className="question-result-image mx-auto"
            />
          </div>
          <div className="d-flex justify-content-center">
            <a
              href="/"
              className="btn mt-4"
              style={{
                borderRadius: "32px",
                background: disclaimerList[0]?.choiceOutColor
              }}
            >
              BENİM BAYİM'E GERİ DÖN
            </a>
          </div>
        </div>
      );
    }

    if (errorQuestionList) {
      return (
        <div
          style={{
            color:
              disclaimerList &&
              disclaimerList[0] &&
              disclaimerList[0].primaryColor
                ? disclaimerList[0].primaryColor
                : "#FFF"
          }}
        >
          <div className="wrapper-question-item wonbefore">
            {controlCompetition && controlCompetition.length > 0 && (
              <div>
                {controlCompetition[0].wonBefore === true && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: controlCompetition[0].wonBeforeText
                    }}
                  />
                )}

                {controlCompetition[0].wonBefore === false && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: controlCompetition[0].answeredTodayQuestions
                    }}
                  />
                )}
              </div>
            )}

            <div className="text-center">
              {controlCompetition && controlCompetition.length > 0 && (
                <>
                  {controlCompetition[0].wonBefore === true ? (
                    <img
                      src={imageShowUrl + competitionErrorImage4}
                      className="question-result-image mx-auto wonBeforeTrue"
                    />
                  ) : (
                    <img
                      src={imageShowUrl + competitionErrorImage3}
                      className="question-result-image mx-auto wonBeforeFalse"
                    />
                  )}
                </>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <a
                href="/"
                className="btn mt-4"
                style={{
                  borderRadius: "32px",
                  background: disclaimerList[0].choiceOutColor
                }}
              >
                BENİM BAYİM'E GERİ DÖN
              </a>
            </div>
          </div>
        </div>
      );
    }

    if (errorRemainTime) {
      return (
        <div
          style={{
            color:
              disclaimerList &&
              disclaimerList[0] &&
              disclaimerList[0].primaryColor
                ? disclaimerList[0].primaryColor
                : "#FFF"
          }}
        >
          <div
            className="answer-text"
            id="competetion-answer-5"
            dangerouslySetInnerHTML={{ __html: answerMessage }}
          />
          <div className="text-center">
            <img
              src={imageShowUrl + competitionTimeImage2}
              className="question-result-image mx-auto"
            />
          </div>
          <div className="d-flex justify-content-center">
            <a
              href="/"
              className="btn mt-4"
              style={{
                borderRadius: "32px",
                background: disclaimerList[0]?.choiceOutColor
              }}
            >
              BENİM BAYİM'E GERİ DÖN
            </a>
          </div>
        </div>
      );
    }
    if (competitionList) {
      competition = competitionList.map(item => {
        return (
          <div key={item.id} className="d-block">
            <h1 style={{ fontFamily: "winstonmedium", fontSize: "1.4rem" }}>
              {item.description}
            </h1>
            <form
              className="container-fluid"
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <div className="answer-container" id="answer-container-wrapper">
                <div className="answer-item">
                  <div className="custom-control custom-radio">
                    <div className="row">
                      {showChoices(item.questionChoices)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <div className="time active">
                    <h3 className="text-center pt-2">
                      <b style={{ fontFamily: "Winston" }}>
                        00:
                        {seconds < 0
                          ? stopTimerToZero(
                              item.questionId,
                              selectedChoice,
                              employeeFirm.id,
                              employeeFirm.firm.id
                            )
                          : seconds < 10
                          ? "0" + seconds
                          : seconds}
                      </b>
                    </h3>
                  </div>
                </div>
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn question-btn"
                    onClick={e =>
                      saveCompetition(
                        item.questionId,
                        selectedChoice,
                        employeeFirm.id,
                        employeeFirm.firm.id,
                        e
                      )
                    }
                    style={{
                      borderRadius: "32px",
                      backgroundColor: disclaimerList[0]?.choiceOutColor
                    }}
                  >
                    CEVAPLA
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="progress mt-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        backgroundColor: disclaimerList[0]?.choiceOutColor,
                        width:
                          questionStep == 1 ? "50%" : questionStep * 50 + "%"
                      }}
                      aria-valuenow={questionStep == 1 ? 50 : questionStep * 50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <p className="text-center mt-3">
                    {questionStep > 1 ? 2 : questionStep}/2
                  </p>
                </div>
              </div>
            </form>
          </div>
        );
      });
    }
  }

  return (
    <div
      style={{
        color:
          disclaimerList && disclaimerList[0] && disclaimerList[0].primaryColor
            ? disclaimerList[0].primaryColor
            : "#FFF"
      }}
    >
      {disclaimerList && disclaimerList.length > 0 && (
        <style>
          {`
          .competition-ramadan .custom-control .form-check .form-check-input + label::before {
            border: 6px solid ${disclaimerList[0]?.choiceOutColor} !important;
          }

          .competition-ramadan .custom-control .form-check .form-check-input:checked + label::before {
            background-color: ${disclaimerList[0]?.choiceInColor};
          }

          `}
        </style>
      )}
      {disclaimer}
      {competition}
    </div>
  );
};
export default Question;
