import React, { Component } from "react";
import Menu from "./menu";
import { imageShowUrl } from "../../../config/api.config";

const layout = OriginalComponent => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = { disclaimerList: null };
      this.gameActionList = this.gameActionList.bind(this);
    }

    gameActionList(param) {
      this.setState({ disclaimerList: param });
    }

    render() {
      require("./assets/scss/main.scss");
      return (
        <main className="competition competition-ramadan">
          {this.state.disclaimerList && (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundImage: `url(${imageShowUrl +
                  this.state.disclaimerList[0].backgroundImage})`,
                backgroundSize: "cover"
              }}
            ></div>
          )}

          <div className="header">
            {this.gameActionList && (
              <Menu getActionList={this.gameActionList} />
            )}
            <div className="animate__animated animate__slideInLeft mobile-banner pl-4 pr-4 pb-2 text-center">
              {this.state.disclaimerList && (
                <img
                  src={imageShowUrl + this.state.disclaimerList[0].bannerImage}
                  className="col-12 col-sm-7"
                  alt=""
                />
              )}
            </div>
          </div>

          <div className="container-xl content">
            <div className="row">
              <div className="left-content-wrapper col-xl-6 col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
                {this.state.disclaimerList && (
                  <img
                    src={
                      imageShowUrl + this.state.disclaimerList[0].bannerImage
                    }
                    className="img-fluid mw-450"
                    alt=""
                  />
                )}
              </div>
              <div className="right-content-wrapper col-xl-6 col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
                <div className="right-content">
                  <OriginalComponent {...this.props} />
                </div>
              </div>
            </div>
          </div>

          <div>
            {this.state.disclaimerList && (
              <>
                {this.state.disclaimerList[0].backgroundBottomImage1 && (
                  <img
                    src={
                      imageShowUrl +
                      this.state.disclaimerList[0].backgroundBottomImage1
                    }
                    className="img-fluid left-top"
                  />
                )}

                {this.state.disclaimerList[0].backgroundBottomImage2 && (
                  <img
                    src={
                      imageShowUrl +
                      this.state.disclaimerList[0].backgroundBottomImage2
                    }
                    className="img-fluid left-top"
                  />
                )}

                {this.state.disclaimerList[0].backgroundBottomImage3 && (
                  <img
                    src={
                      imageShowUrl +
                      this.state.disclaimerList[0].backgroundBottomImage3
                    }
                    className="img-fluid left-top"
                  />
                )}

                {this.state.disclaimerList[0].backgroundBottomImage4 && (
                  <img
                    src={
                      imageShowUrl +
                      this.state.disclaimerList[0].backgroundBottomImage4
                    }
                    className="img-fluid left-top"
                  />
                )}
              </>
            )}
          </div>
        </main>
      );
    }
  };
};

export default layout;
