import * as authTypes from "./auth.type";

import { API } from "../../../services/http.service";
import { SUCCESS_MESSAGES } from "../../../constants/messages.const";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json" }
};

export const login = ({
  username,
  password,
  confirmCode,
  proofKey
}) => async dispatch => {
  dispatch({ type: authTypes.LOGIN_PENDING });

  //const data = formUrlEncoded({ username, password });
  let data = {
    username: username,
    password: password,
    confirmCode: confirmCode,
    proofKey: proofKey
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/public/login`,
      data,
      config
    );
    dispatch({ type: authTypes.LOGIN_FULFILLED, payload: response });
    response.data.token && localStorage.setItem("token", response.data.token);

    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: authTypes.LOGIN_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

function returnComplist(value) {
  return value;
}

export const setCompList = value => async dispatch => {
  dispatch({ type: authTypes.SET_COMPLIST_PENDING });

  try {
    const returnComponent = returnComplist(value);

    dispatch({
      type: authTypes.SET_COMPLIST_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: authTypes.SET_COMPLIST_REJECTED,
      payload: error
    });
  }
};

export const register = ({ username, password }) => async dispatch => {
  dispatch({ type: authTypes.REGISTER_PENDING });

  const data = { username, password };
  try {
    const response = await API.post(`${REQUEST_URL}/signup`, data);
    dispatch({ type: authTypes.REGISTER_FULFILLED, payload: response });
  } catch (error) {
    dispatch({ type: authTypes.REGISTER_REJECTED, payload: error });
  }
};

export const logout = () => async dispatch => {
  //API.post(`${REQUEST_URL}/logout`);
  localStorage.clear();
  dispatch({ type: authTypes.LOGOUT });
};

export const resetPassword = email => async dispatch => {
  dispatch({ type: authTypes.RESET_PASSWORD_PENDING });

  const data = { username: email };
  try {
    const response = await API.post(`/user/resetPassword`, data);
    const interaction = {
      message: SUCCESS_MESSAGES.RESET_PASSWORD,
      type: "notification"
    };

    dispatch({
      type: authTypes.RESET_PASSWORD_FULFILLED,
      payload: response,
      interaction
    });
  } catch (error) {
    dispatch({ type: authTypes.RESET_PASSWORD_REJECTED, payload: error });
  }
};

export const initPassword = ({
  token,
  password,
  confirmPassword
}) => async dispatch => {
  dispatch({ type: authTypes.INIT_PASSWORD_PENDING });
  const data = { password, confirmPassword };
  try {
    const response = await API.post(`/public/changePassword/` + token, data);
    const interaction = {
      message: SUCCESS_MESSAGES.INIT_PASSWORD,
      type: "notification"
    };

    dispatch({
      type: authTypes.INIT_PASSWORD_FULFILLED,
      payload: response,
      interaction
    });
    /* dispatch({ type: authTypes.LOGOUT }); */
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: authTypes.INIT_PASSWORD_REJECTED, payload: error });
    return Promise.reject(
      JSON.parse(error.error.response.request.response).errors[0].message
    );
  }
};

export const forgotPassword = username => async dispatch => {
  dispatch({ type: authTypes.FORGOT_PASSWORD_PENDING });

  try {
    const response = await API.post(`/public/forgotPassword`, username);
    const interaction = {
      message: "",
      type: "notification"
    };
    dispatch({
      type: authTypes.FORGOT_PASSWORD_FULFILLED,
      payload: response,
      interaction
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: authTypes.FORGOT_PASSWORD_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

export const registerVendor = ({
  name,
  surname,
  mobileNo,
  vendorName,
  //vendorERPCode,
  tapdkCode,
  tapdkType,
  //username,
  vendorERCCode,
  password
}) => async dispatch => {
  dispatch({ type: authTypes.REGISTER_VENDOR_PENDING });

  mobileNo = "05" + mobileNo;

  const data = {
    name,
    surname,
    mobileNo,
    vendorName,
    //vendorERPCode,
    tapdkCode,
    tapdkType,
    //username,
    vendorERCCode,
    password
  };
  try {
    const response = await API.post(`${REQUEST_URL}/public/register`, data);
    dispatch({ type: authTypes.REGISTER_VENDOR_FULFILLED, payload: response });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: authTypes.REGISTER_VENDOR_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

export const returnNewScoreOfEmployee = (id, employeeId) => async dispatch => {
  dispatch({ type: authTypes.RETURN_EMPLOYEE_SCORE_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/newScore/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: authTypes.RETURN_EMPLOYEE_SCORE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.RETURN_EMPLOYEE_SCORE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const smsConfirmationCode = (
  username,
  password,
  confirmCode,
  proofKey
) => async dispatch => {
  dispatch({ type: authTypes.SMS_CONFIRMATION_CODE_PENDING });

  let data = null;
  if (proofKey) {
    data = {
      username,
      password,
      confirmCode,
      proofKey
    };
  } else {
    data = {
      username,
      password,
      confirmCode
    };
  }

  try {
    const response = await API.post(
      `${REQUEST_URL}/public/confirm`,
      data,
      config
    );
    response.data.token && localStorage.setItem("token", response.data.token);
    dispatch({
      type: authTypes.SMS_CONFIRMATION_CODE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.SMS_CONFIRMATION_CODE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};
