import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { employeeFirmAction } from "redux/modules";

const EmployeeFirm = props => {
  const dispatch = useDispatch();

  const employeeFirm = useSelector(store => store.employeeFirm.employeeFirm);
  const isLoading = useSelector(store => store.employeeFirm.isLoading);

  useEffect(() => {
    dispatch(employeeFirmAction.getEmployeeFirmById(1));
    return () => {};
  }, []);

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>ID</td>
            <td>{employeeFirm && employeeFirm.id}</td>
          </tr>
          <tr>
            <td>NAME</td>
            <td>
              {employeeFirm &&
                employeeFirm.employee &&
                employeeFirm.employee.name}
            </td>
          </tr>
          <tr>
            <td>SURNAME</td>
            <td>
              {employeeFirm &&
                employeeFirm.employee &&
                employeeFirm.employee.name}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeFirm;
