import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { newsAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

// HTML PARSE IN STRING
import parse from "html-react-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowUp,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons";

// Stylesheet
import "./Style.scss";

import Moment from "react-moment";
import "moment/locale/tr";

const NewsDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  Moment.globalLocale = "tr";

  const isLoading = useSelector(store => store.news.isLoading);
  const financeList = useSelector(store => store.news.financeList);
  const newsDetail = useSelector(store => store.news.newsDetail);

  useEffect(() => {
    // Scroll Top
    window.scrollTo(0, 0);

    const id = location.pathname.split("/")[
      location.pathname.split("/").length - 1
    ];
    dispatch(newsAction.getNewsById(id));
    dispatch(newsAction.getFinance());

    return () => {};
  }, []);

  return (
    <section id="dergi-detay" className="super-sport-page page-magazine-detail">
      <GlobalLoader isLoading={isLoading} />

      {financeList && (
        <div className="finance">
          {financeList.length > 0 &&
            financeList.map((item, index) => {
              return (
                <div key={index} className="finance-content">
                  <span>{item.name}</span>

                  <span>
                    {item.diffDayPer > 0 ? (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{
                          fontSize: "16px",
                          color: "#01af65"
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{
                          fontSize: "16px",
                          color: "#af0000"
                        }}
                      />
                    )}
                  </span>

                  <span>{item.currentValue}</span>
                </div>
              );
            })}
        </div>
      )}

      <div className="news-tab" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="row ml-auto mr-auto justify-content-center mw-100 mb-3">
            <div className="col-12">
              <div className="overflow-hidden rounded-3">
                {newsDetail && newsDetail.imageList && (
                  <img
                    src={`data:image/jpeg;base64,${newsDetail.imageList[0]}`}
                    style={{ marginTop: "-200px" }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row ml-auto mr-auto justify-content-center mw-100">
            <div className="col-10">
              <div className="article">
                <div className="article-head d-block">
                  {newsDetail && newsDetail.title && (
                    <h1>{newsDetail.title}</h1>
                  )}

                  {newsDetail && newsDetail.date && (
                    <p className="ml-auto mt-auto">
                      <Moment format="DD-MM-YYYY HH:mm">
                        {newsDetail.date}
                      </Moment>
                    </p>
                  )}
                </div>
                <div className="article-body">
                  {newsDetail && newsDetail.content && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parse(newsDetail.content)
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row m-auto justify-content-center mw-100">
            <div className="col-10">
              <p>Kaynak: AA Haber Ajansı</p>

              <Link to={{ pathname: `/userportal/news` }}>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ fontSize: "16px", color: "#007bff" }}
                  className="mr-2"
                />
                <span>Haberler'e Dön</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default NewsDetail;
