import * as profileTypes from "./profile.type";

import { API } from "../../../services/http.service";
import ProfileAccountInfo from "pages/UserPortal/Profile/ProfileAccountInfo";
import ProfileScoreHistory from "pages/UserPortal/Profile/ProfileScoreHistory";
import ProfileMessage from "pages/UserPortal/Profile/ProfileMessage";
import ProfileAddressInfo from "pages/UserPortal/Profile/ProfileAddressInfo";
import ProfilePhotos from "pages/UserPortal/Profile/ProfilePhotos";
import ProfileOrders from "pages/UserPortal/Profile/ProfileOrders";
import ProfileBadges from "pages/UserPortal/Profile/ProfileBadges";
const REQUEST_URL = "";

const config = {
  headers: {
    "Content-Type": "application/json-application"
  }
};

export const getMessageSubjectsByEmployeeFirmId = id => async dispatch => {
  dispatch({
    type: profileTypes.GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/contactSubjectList/${id}`,
      config
    );
    dispatch({
      type: profileTypes.GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: profileTypes.GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_REJECTED,
      payload: error
    });
  }
};

export const getContactMessagesBySubjectId = id => async dispatch => {
  dispatch({
    type: profileTypes.GET_MESSAGELIST_BY_SUBJECTID_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/contactMessageList/${id}`,
      config
    );
    dispatch({
      type: profileTypes.GET_MESSAGELIST_BY_SUBJECTID_FULFILLED,
      payload: response
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: profileTypes.GET_MESSAGELIST_BY_SUBJECTID_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getNumberOfMessages = id => async dispatch => {
  dispatch({
    type: profileTypes.GET_MESSAGE_NUMBER_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/contactMessageNumber/${id}`,
      config
    );
    dispatch({
      type: profileTypes.GET_MESSAGE_NUMBER_FULFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: profileTypes.GET_MESSAGE_NUMBER_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};
export const saveNewMessage = (
  message,
  contactSubjectId,
  senderEmployeeFirmId,
  imgSrc
) => async dispatch => {
  dispatch({
    type: profileTypes.SAVE_NEW_MESSAGE_PENDING
  });

  const data = {
    message,
    contactSubjectId,
    senderEmployeeFirmId,
    imgSrc
  };
  try {
    const response = await API.post(`/contactMessage/save`, data);
    const interaction = {
      message: "Mesajınız iletildi",
      type: "notification"
    };

    dispatch({
      type: profileTypes.SAVE_NEW_MESSAGE_FULFILLED,
      payload: response,
      interaction
    });

    dispatch(getContactMessagesBySubjectId(contactSubjectId));
  } catch (error) {
    dispatch({
      type: profileTypes.SAVE_NEW_MESSAGE_REJECTED,
      payload: error
    });
  }
};

// YUKSEL DYNAMIC PROFILE CONTENT START
function returnFromKey(key) {
  switch (key) {
    case `accountinfo`:
      return ProfileAccountInfo;

    case `message`:
      return ProfileMessage;

    case `addressinfo`:
      return ProfileAddressInfo;

    case `photos`:
      return ProfilePhotos;

    case `orders`:
      return ProfileOrders;

    case `badges`:
      return ProfileBadges;

    case `scoreHistory`:
      return ProfileScoreHistory;

    default:
      return ProfileAccountInfo;
  }
}

export const setProfileContent = key => async dispatch => {
  dispatch({
    type: profileTypes.SET_PROFILE_CONTENT_PENDING
  });

  try {
    const returnComponent = returnFromKey(key);
    dispatch({
      type: profileTypes.SET_PROFILE_CONTENT_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileTypes.SET_PROFILE_CONTENT_REJECTED,
      payload: error
    });
  }
};
// YUKSEL DYNAMIC PROFILE CONTENT END

export const saveInitialMessage = (
  message,
  contactSubjectText,
  senderEmployeeFirmId,
  imgSrc
) => async dispatch => {
  dispatch({
    type: profileTypes.SAVE_INITIAL_MESSAGE_PENDING
  });

  const data = {
    message,
    contactSubjectText,
    senderEmployeeFirmId,
    imgSrc
  };
  try {
    const response = await API.post(`/contactMessageInitial/save`, data);
    const interaction = {
      message: "Mesajınız iletildi",
      type: "notification"
    };

    dispatch({
      type: profileTypes.SAVE_INITIAL_MESSAGE_FULFILLED,
      payload: response,
      interaction
    });

    //dispatch(getContactMessagesBySubjectId(contactSubjectText));
    dispatch(getMessageSubjectsByEmployeeFirmId(senderEmployeeFirmId));
  } catch (error) {
    dispatch({
      type: profileTypes.SAVE_INITIAL_MESSAGE_REJECTED,
      payload: error
    });
  }
};

/* export const setProfileOrigin = footerData => dispatch => {
  dispatch({ type: profileTypes.SET_PROFILE_ORIGIN_PENDING });

  try {
    dispatch({
      type: profileTypes.SET_PROFILE_ORIGIN_FULFILLED,
      payload: footerData
    });
  } catch (error) {
    dispatch({
      type: profileTypes.SET_PROFILE_ORIGIN_REJECTED,
      payload: error
    });
  }
}; */

/* export const setProfileOrigin = footerData => {
  return {
    type: profileTypes.SET_PROFILE_ORIGIN_FULFILLED,
    payload: footerData
  };
}; */

export const showContactUs = showParam => {
  return {
    type: profileTypes.SET_SHOW_CONTACT_US_FULFILLED,
    payload: showParam
  };
};

export const uploadPhoto = (
  selectedChoice,
  imgSrc,
  employeeFirmId,
  usePermission
) => async dispatch => {
  dispatch({
    type: profileTypes.UPLOAD_PHOTO_PENDING
  });

  const data = {
    selectedChoice,
    imgSrc,
    employeeFirmId,
    usePermission
  };
  try {
    const response = await API.post(`/uploadPhoto`, data);

    dispatch({
      type: profileTypes.UPLOAD_PHOTO_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: profileTypes.UPLOAD_PHOTO_REJECTED,
      payload: error
    });
  }
};

export const getImagesByEmployeeFirmId = employeeFirmId => async dispatch => {
  dispatch({
    type: profileTypes.GET_EMPLOYEE_IMAGES_PENDING
  });
  try {
    const response = await API.get(
      `${REQUEST_URL}/employeePhotoList/${employeeFirmId}`
    );

    dispatch({
      type: profileTypes.GET_EMPLOYEE_IMAGES_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_EMPLOYEE_IMAGES_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const returnToPhotoList = showParam => {
  return {
    type: profileTypes.RETURN_TO_PHOTO_LIST_FULFILLED,
    payload: showParam
  };
};

export const getProfileImageByEmployeeFirmId = employeeFirmId => async dispatch => {
  dispatch({
    type: profileTypes.GET_PROFILE_IMAGE_PENDING
  });
  try {
    const response = await API.get(
      `${REQUEST_URL}/profilePhoto/${employeeFirmId}`
    );

    dispatch({
      type: profileTypes.GET_PROFILE_IMAGE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PROFILE_IMAGE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const goToNewAddress = showParam => {
  return {
    type: profileTypes.GO_TO_NEW_ADDRESS_FULFILLED,
    payload: showParam
  };
};

function returnActives(actives) {
  return actives;
}

export const setActives = actives => async dispatch => {
  dispatch({
    type: profileTypes.SET_ACTIVES_PENDING
  });

  try {
    const returnComponent = returnActives(actives);
    dispatch({
      type: profileTypes.SET_ACTIVES_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: profileTypes.SET_ACTIVES_REJECTED,
      payload: error
    });
  }
};

export const showCroppedImage = showParam => {
  return {
    type: profileTypes.SET_SHOW_CROPPED_IMAGE_FULFILLED,
    payload: showParam
  };
};

export const goToPendingPhotoTab = showParam => {
  return {
    type: profileTypes.GO_TO_PENDING_PHOTO_TAB_FULFILLED,
    payload: showParam
  };
};

export const saveMessageManagement = data => async dispatch => {
  dispatch({
    type: profileTypes.SAVE_MESSAGE_MANAGEMENT_PENDING
  });

  try {
    const response = await API.post(`/iys`, data);
    dispatch({
      type: profileTypes.SAVE_MESSAGE_MANAGEMENT_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.SAVE_MESSAGE_MANAGEMENT_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getProfileBadge = employeeFirmId => async dispatch => {
  dispatch({
    type: profileTypes.GET_PROFILE_BADGE_PENDING
  });
  try {
    const response = await API.get(
      `${REQUEST_URL}/profileBadge/${employeeFirmId}`
    );

    dispatch({
      type: profileTypes.GET_PROFILE_BADGE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.GET_PROFILE_BADGE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const setProfileBadge = (employeeFirmId, badgeId) => async dispatch => {
  dispatch({
    type: profileTypes.SET_PROFILE_BADGE_PENDING
  });
  try {
    const response = await API.post(
      `${REQUEST_URL}/setProfileBadge/${employeeFirmId}/${badgeId}`
    );

    dispatch({
      type: profileTypes.SET_PROFILE_BADGE_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: profileTypes.SET_PROFILE_BADGE_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};
