import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchFindAction, authAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import { Link, Redirect } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { imageShowUrl } from "../../../config/api.config";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { IoArrowBack, IoArrowForward } from "react-icons/io5";

const SearchFind = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const searchFindList = useSelector(store => store.searchFind.searchFindList);
  const selectedOption = useSelector(store => store.searchFind.selectedOption);
  const isLoading = useSelector(store => store.searchFind.isLoading);

  var searchFindGame = null;
  const [countDownTimer, setCountDownTimer] = useState(null);

  const [isActive, setIsActive] = useState(true);

  const [redirect, setRedirect] = useState(false);
  const [redirectNoQuestion, setRedirectNoQuestion] = useState(false);

  const choiceList = [];
  const [selectedChoice, setSelectedChoice] = useState([]);

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [nextBadge, setNextBadge] = useState({});

  useEffect(() => {
    // Set SelectedItem to State
    if (localStorage.getItem("selectedItem")) {
      setSelectedItem(JSON.parse(localStorage.getItem("selectedItem")));
    }

    if (localStorage.getItem("questionId")) {
      setSelectedQuestion(localStorage.getItem("questionId"));

      localStorage.setItem(
        "selectedBrandQuestionId",
        JSON.parse(localStorage.getItem("selectedItem")).id
      );
    }

    if (localStorage.getItem("nextBadge")) {
      setNextBadge(JSON.parse(localStorage.getItem("nextBadge")));
    }

    return () => {};
  }, []);

  // Write SelectedItem Console
  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      // Get Question Request
      dispatch(
        searchFindAction.getSearchFindByBrandId(
          selectedItem.id,
          employeeFirm.id,
          selectedQuestion
        )
      ).then(e => {
        if (e.data) {
          e.data.map(item => {
            const renderTime = ({ remainingTime }) => {
              if (remainingTime === 0) {
                return (
                  <div className="timer text-[16px] md:text-[24px] font-bold text-white">
                    Süre Doldu!
                  </div>
                );
              }

              return (
                <div className="timer text-center">
                  <div className="value text-[36px] md:text-[48px] md:leading-[58px] leading-[44px] font-bold text-white">
                    {remainingTime}
                  </div>
                  <div className="text text-[12px] font-bold text-white">
                    SANİYE
                  </div>
                </div>
              );
            };

            setCountDownTimer(
              <CountdownCircleTimer
                isPlaying
                size={156}
                strokeLinecap="square"
                duration={item.totalTime}
                initialRemainingTime={item.remainTime}
                colors="#FFFFFF"
                onComplete={() => {
                  stopTimerToZero(
                    item.questionId,
                    selectedChoice,
                    employeeFirm.id,
                    employeeFirm.firm.id
                  );
                }}
              >
                {renderTime}
              </CountdownCircleTimer>
            );
          });
        }
      });
    }

    return () => {};
  }, [selectedItem]);

  // When Timer 0 Stop
  function stopTimerToZero(question, questionChoice, employeeFirmId, firmId) {
    setIsActive(false);
    questionChoice = null;
    dispatch(
      searchFindAction.saveFindQuestionAnswer(
        question,
        questionChoice,
        employeeFirmId,
        firmId,
        selectedItem.id
      )
    )
      .then(e => {
        console.log("e", e);
      })
      .catch(err => {
        // Show Error Message
        if (err.error.response.data) {
          if (err.error.response.data.errors) {
            err.error.response.data.errors.map(item => {
              showErrMessage(item.message);
            });
          }
        }
      });
    setRedirect(true);
  }

  // Choose one answer
  function onClickOption(value, choiceId) {
    choiceList.push(choiceId);
    setSelectedChoice(choiceList);
    dispatch(searchFindAction.changeOption(value));
  }

  // Show Choices
  // function showChoices(choicesList) {
  //   var questionChoices = null;

  //   questionChoices = choicesList.map((itemChoice, index) => (
  //     <div className="col-12 col-lg-6" key={itemChoice.id}>
  //       <div id="cevaplar">
  //         <div
  //           className="cevap"
  //           onClick={() => onClickOption(index + 1, itemChoice.id)}
  //         >
  //           <div
  //             className={
  //               selectedOption === index + 1 ? "option active" : "option"
  //             }
  //             data-cevap={index + 1}
  //           >
  //             <div></div>
  //           </div>
  //           <div
  //             className={
  //               selectedOption === index + 1
  //                 ? "text align-self-center active"
  //                 : "text align-self-center"
  //             }
  //           >
  //             {itemChoice.name}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ));
  //   return questionChoices;
  // }

  function showChoices(choicesList) {
    var questionChoices = null;

    questionChoices = choicesList.map((itemChoice, index) => (
      <div
        className="flex items-center mb-[16px] md:mb-[24px]"
        key={itemChoice.id}
      >
        {selectedOption === index + 1 ? (
          <input
            id={`radio${index + 1}`}
            type="radio"
            name="radio"
            className="hidden"
            checked
          />
        ) : (
          <input
            id={`radio${index + 1}`}
            type="radio"
            name="radio"
            className="hidden"
          />
        )}

        <label
          htmlFor={`radio${index + 1}`}
          onClick={() => onClickOption(index + 1, itemChoice.id)}
          className="flex items-center cursor-pointer md:text-[16px] text-[14px] font-medium"
        >
          <span className="w-6 h-6 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
          {itemChoice.name}
        </label>
      </div>
    ));
    return questionChoices;
  }

  // Save Question or Answer (TODO: Check it)
  function saveQuestion(question, questionChoice, employeeFirmId, firmId) {
    localStorage.setItem("questionId", question);

    if (questionChoice.length <= 0) {
      showErrMessage("Seçenek seçiniz.");
      //e.preventDefault();
    } else {
      // Save Answer
      dispatch(
        searchFindAction.saveFindQuestionAnswer(
          question,
          questionChoice,
          employeeFirmId,
          firmId,
          selectedItem.id
        )
      )
        .then(e => {
          // Get New Score
          dispatch(
            authAction.returnNewScoreOfEmployee(
              employeeFirm.firm.id,
              employeeFirm.id
            )
          );
          setRedirect(true);
        })
        .catch(err => {
          // Show Error Message
          if (err.error.response.data) {
            if (err.error.response.data.errors) {
              err.error.response.data.errors.map(item => {
                showErrMessage(item.message);
              });
            }
          }
        });
    }
  }

  if (redirect) {
    return (
      <Redirect
        from="/userportal/searchFindQuestion"
        to="/userportal/searchFindAnswer"
      />
    );
  }

  function noQuestion() {
    setRedirectNoQuestion(true);
  }

  if (redirectNoQuestion) {
    return (
      <Redirect
        from="/userportal/searchFindQuestion"
        to="/userportal/searchFind"
      />
    );
  }

  if (searchFindList) {
    searchFindGame = searchFindList.map(item => {
      return (
        <div className="mx-auto px-4 pt-4 xl:container lg:pt-20" key={item.id}>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4 mb-[16px]">
            <div className="col-span-4 flex flex-col justify-center bg-white px-[16px] pt-[34px] pb-[24px] text-center md:col-span-2 md:col-start-2 lg:col-span-1 rounded-[12px]">
              <img
                src={imageShowUrl + selectedItem.icon}
                className="mx-auto max-h-[66px] lg:max-h-[66px]"
                style={
                  selectedItem.id === 149467
                    ? { height: "66px" }
                    : selectedItem.id === 149466
                    ? { height: "58px" }
                    : {}
                }
              />
              <div className="flex items-center justify-between mt-[48px]">
                <span className="text-primary-500 font-semibold text-[16px]">
                  Rozet Durumu
                </span>
                <span className="text-primary-500 font-semibold text-[16px]">
                  {selectedItem.employeeBadge}/{selectedItem.totalBadge}
                </span>
              </div>
              <div className="mt-3 h-2 w-full overflow-hidden rounded-lg bg-slate-200">
                <div
                  className="h-full rounded-lg bg-primary-500"
                  style={{
                    width:
                      (
                        (selectedItem.employeeBadge * 100) /
                        selectedItem.totalBadge
                      ).toFixed(2) + "%"
                  }}
                ></div>
              </div>
            </div>

            <div className="relative col-span-4 bg-white flex md:flex-row flex-col justify-between items-center py-[64px] px-[48px] lg:col-span-3 rounded-[12px]">
              {selectedItem && selectedItem.id === 149464 && (
                <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[200px]">
                  Rozet Macerası Winston
                </p>
              )}

              {selectedItem && selectedItem.id === 149465 && (
                <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[200px]">
                  Rozet Macerası Camel
                </p>
              )}

              {selectedItem && selectedItem.id === 149466 && (
                <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[200px]">
                  Rozet Macerası LD
                </p>
              )}

              {selectedItem && selectedItem.id === 149467 && (
                <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[200px]">
                  Rozet Macerası Monte Carlo
                </p>
              )}

              {selectedItem.employeeBadge !== selectedItem.totalBadge && (
                <div className="flex items-center">
                  <p className="md:text-[16px] text-[14px] text-[#64748b] font-normal pr-4">
                    Sıradaki Rozet:
                  </p>
                  <img
                    src={imageShowUrl + nextBadge.icon}
                    className="mx-auto max-h-[70px] lg:max-h-[125px]"
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <div className="col-span-4 flex flex-col max-h-[270px] md:max-h-[333px] h-[100%] md:h-[333px] justify-center rounded-xl bg-[url('assets/images/new-design/game/card-bg-green.png')] bg-no-repeat mx-auto w-full bg-cover p-6 text-center md:col-span-2 md:col-start-2 lg:col-span-1 lg:rounded-bl-[100px] rounded-bl-[22px]">
              <h1 className="mb-4 text-[20px] font-bold text-white md:mb-6 lg:text-[24px]">
                Kalan Süre
              </h1>
              <div className="countdown flex justify-center">
                {countDownTimer}
              </div>
            </div>

            <div className="col-span-4 lg:col-span-3 lg:col-start-2">
              <div className="bg-white h-[100%] flex-1 flex justify-center items-start rounded-[12px] lg:flex-row flex-col md:py-12 py-8 md:px-12 px-8">
                <div className="left flex flex-col items-start lg:w-[40%] w-[100%]">
                  <div className="question-count bg-[#38c086] rounded-[17px] px-[16px] py-[8px]">
                    <p className="text-[12px] text-center font-bold text-white">
                      Soru
                    </p>
                  </div>
                  <div className="questions mt-[12px]">
                    <p className="md:text-[24px] text-[18px] font-bold text-[#64748b]">
                      {item.description}
                    </p>
                  </div>
                </div>
                <div className="right lg:w-[60%] w-[100%] lg:pl-[12%] pl-[0] lg:pt-[0] pt-[10%]">
                  {showChoices(item.questionChoices)}
                </div>
              </div>
            </div>
            <div className="col-span-4 lg:col-span-3 lg:col-start-2">
              <div className="grid grid-cols-1 gap-2  md:gap-4">
                <div className=" bg-white w-full flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col py-6 px-6">
                  <div className="action flex justify-between w-full">
                    <button
                      type="button"
                      className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
                    >
                      <IoArrowBack />
                      Geri Git{" "}
                    </button>
                    <button
                      type="button"
                      className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px]  max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] py-[16px] pl-[24px] pr-[24px] md:pr-[36px] text-white"
                      onClick={e =>
                        saveQuestion(
                          item.questionId,
                          selectedChoice,
                          employeeFirm.id,
                          employeeFirm.firm.id,
                          e
                        )
                      }
                    >
                      Cevapla <IoArrowForward />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <main className="bg-slate-50 pb-20">
      <GlobalLoader isLoading={isLoading} />
      {searchFindGame}
    </main>
  );
};
export default SearchFind;
