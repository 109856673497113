import * as profileTypes from "./profile.type";
import ProfileAccountInfo from "pages/UserPortal/Profile/ProfileAccountInfo";

const initialState = {
  subjectList: null,
  messageList: null,
  currentContent: ProfileAccountInfo,
  footer: null,
  error: null,
  isLoading: false,
  employeeImageList: null,
  displayPhotoList: null,
  profileImage: null,
  displayNewAddressPage: null,
  actives: [],
  numberOfMessages: 0,
  croppedImage: null,
  displayPendingPhotoTab: null,
  profileBadge: null
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${profileTypes.GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_PENDING}`:
    case `${profileTypes.GET_MESSAGELIST_BY_SUBJECTID_PENDING}`:
    case `${profileTypes.SAVE_NEW_MESSAGE_PENDING}`:
    case `${profileTypes.SET_PROFILE_CONTENT_PENDING}`:
    case `${profileTypes.SAVE_INITIAL_MESSAGE_PENDING}`:

    // case `${profileTypes.SET_PROFILE_ORIGIN_PENDING}`:
    case `${profileTypes.SET_SHOW_CONTACT_US_PENDING}`:
    case `${profileTypes.UPLOAD_PHOTO_PENDING}`:
    case `${profileTypes.GET_EMPLOYEE_IMAGES_PENDING}`:
    case `${profileTypes.RETURN_TO_PHOTO_LIST_PENDING}`:
    case `${profileTypes.GET_PROFILE_IMAGE_PENDING}`:
    case `${profileTypes.GO_TO_NEW_ADDRESS_PENDING}`:
    case `${profileTypes.SET_ACTIVES_PENDING}`:
    case `${profileTypes.GET_MESSAGE_NUMBER_PENDING}`:
    case `${profileTypes.SET_SHOW_CROPPED_IMAGE_PENDING}`:
    case `${profileTypes.GO_TO_PENDING_PHOTO_TAB_PENDING}`:
    case `${profileTypes.SAVE_MESSAGE_MANAGEMENT_PENDING}`:
    case `${profileTypes.GET_PROFILE_BADGE_PENDING}`:
    case `${profileTypes.SET_PROFILE_BADGE_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case `${profileTypes.GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        subjectList: payload.data
      };
    case `${profileTypes.GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        subjectList: null
      };
    case `${profileTypes.GET_MESSAGE_NUMBER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        numberOfMessages: payload
      };
    case `${profileTypes.GET_MESSAGE_NUMBER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${profileTypes.GET_MESSAGELIST_BY_SUBJECTID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        messageList: payload.data
      };
    case `${profileTypes.GET_MESSAGELIST_BY_SUBJECTID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        messageList: null
      };

    case `${profileTypes.SAVE_NEW_MESSAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${profileTypes.SAVE_NEW_MESSAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.SET_PROFILE_CONTENT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        currentContent: payload
      };
    case `${profileTypes.SET_PROFILE_CONTENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        currentContent: null
      };

    case `${profileTypes.SAVE_INITIAL_MESSAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${profileTypes.SAVE_INITIAL_MESSAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    /* case `${profileTypes.SET_PROFILE_ORIGIN_FULFILLED}`:
        return {
          ...state,
          isLoading: false,
          isFromFooter: payload
        };
      case `${profileTypes.SET_PROFILE_ORIGIN_REJECTED}`:
        return {
          ...state,
          isLoading: false,
          error: payload
        }; */

    case `${profileTypes.SET_SHOW_CONTACT_US_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        footer: payload
      };
    case `${profileTypes.SET_SHOW_CONTACT_US_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.UPLOAD_PHOTO_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${profileTypes.UPLOAD_PHOTO_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.GET_EMPLOYEE_IMAGES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        employeeImageList: payload.data
      };
    case `${profileTypes.GET_EMPLOYEE_IMAGES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        employeeImageList: null
      };

    case `${profileTypes.RETURN_TO_PHOTO_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        displayPhotoList: payload
      };
    case `${profileTypes.RETURN_TO_PHOTO_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.GET_PROFILE_IMAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        profileImage: payload.data
      };
    case `${profileTypes.GET_PROFILE_IMAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        profileImage: null
      };

    case `${profileTypes.GO_TO_NEW_ADDRESS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        displayNewAddressPage: payload
      };
    case `${profileTypes.GO_TO_NEW_ADDRESS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${profileTypes.SET_ACTIVES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        actives: payload
      };
    case `${profileTypes.SET_ACTIVES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        actives: [],
        error: payload
      };

    case `${profileTypes.SET_SHOW_CROPPED_IMAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        croppedImage: payload
      };
    case `${profileTypes.SET_SHOW_CROPPED_IMAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.GO_TO_PENDING_PHOTO_TAB_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        displayPendingPhotoTab: payload
      };
    case `${profileTypes.GO_TO_PENDING_PHOTO_TAB_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case `${profileTypes.SAVE_MESSAGE_MANAGEMENT_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${profileTypes.SAVE_MESSAGE_MANAGEMENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.GET_PROFILE_BADGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        profileBadge: payload.data
      };
    case `${profileTypes.GET_PROFILE_BADGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        profileBadge: null
      };

    case `${profileTypes.SET_PROFILE_BADGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };
    case `${profileTypes.SET_PROFILE_BADGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default reducer;
