import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

const HowCompete = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const howPlayList = useSelector(store => store.game.howPlayList);
  var howPlay = null;
  const [loaded, setLoaded] = useState(false);
  const [disclaimerList, setDisclaimerList] = useState([]);

  useEffect(() => {
    dispatch(
      gameAction.getCompetitionHowPlayByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    );
    return () => {};
  }, []);

  useEffect(() => {
    if (!disclaimerList || disclaimerList.length === 0) {
      dispatch(
        gameAction.getCompetitionDisclaimerByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      ).then(response => {
        setLoaded(true);
        setDisclaimerList(response.data);
      });
    }

    return () => {};
  });

  useEffect(() => {
    return () => {
      setLoaded(true);
    };
  }, [howPlayList]);

  if (howPlayList) {
    howPlay = howPlayList.map((item, index) => {
      return (
        <div key={index}>
          <p dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
      );
    });
  } else {
    howPlay = <div>Yarışmaya ait Nasıl Oynarım metni düzenlenmemiştir.</div>;
  }

  return (
    <div
      className="wrapper-scroller p-0 m-0"
      style={{
        color:
          disclaimerList && disclaimerList[0] && disclaimerList[0].primaryColor
            ? disclaimerList[0].primaryColor
            : "#FFF"
      }}
    >
      <div className="wrapper-question-item">
        {/* <GlobalLoader isLoading={!loaded} /> */}
        <h1
          style={{
            fontWeight: "bold",
            color:
              disclaimerList &&
              disclaimerList[0] &&
              disclaimerList[0].primaryColor
                ? disclaimerList[0].primaryColor
                : "#FFF"
          }}
        >
          Nasıl Yarışırım?
        </h1>
        <div className="how-to-compete">{howPlay}</div>
      </div>
    </div>
  );
};
export default HowCompete;
