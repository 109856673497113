import React, { useState, useRef, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "redux/modules";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";

import DefaultProfileImg from "assets/portal-resource/img/default-profile-img.png";

const ProfilePhotoUpload = props => {
  const [imgSrc, setImgSrc] = useState(null);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const uploadInput = useRef();
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const [usePermission, setUsePermission] = useState(null);
  const [redirect, setRedirect] = useState(false);
  //const croppedImage = useSelector(store => store.profile.croppedImage);

  /* useEffect(() => {
    if (croppedImage) {
      setImgSrc(croppedImage);
    }

    return () => {};
  }, [croppedImage]); */

  function changeButton(btnType) {
    if (btnType === "upload") {
      document.getElementById("upload").style.display = "none";
      document.getElementById("cancel").style.display = "block";
    }
    if (btnType === "cancel") {
      document.getElementById("cancel").style.display = "none";
      document.getElementById("upload").style.display = "block";
    }
  }
  function fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        620,
        465,
        "JPEG",
        100,
        0,
        uri => {
          setImgSrc(uri);
        },
        "base64"
      );
    }
  }

  function fileUpload(employeeFirmId) {
    if (imgSrc != null && selectedChoice != null) {
      const splittedImgSrc = imgSrc.split(",");

      dispatch(
        profileAction.uploadPhoto(
          selectedChoice,
          splittedImgSrc[1],
          employeeFirmId,
          usePermission
        )
      )
        .then(e => {
          showMessage("Fotoğrafınız yüklendi");
          dispatch(profileAction.goToPendingPhotoTab("showPendingPhotoTab"));
          dispatch(profileAction.returnToPhotoList("return"));
        })
        .catch(err => {
          showErrMessage("Hata oluştu");
        });
    } else {
      if (imgSrc == null) {
        showErrMessage("Fotoğraf yükleyiniz");
      }
      if (selectedChoice == null) {
        showErrMessage("Profil veya Dükkan Fotoğrafı seçiniz");
      }
    }
  }

  function uploadOrCancel() {
    if (!redirect) {
      uploadInput.current.click();
      changeButton("upload");
      setRedirect(true);
    } else {
      changeButton("cancel");
      dispatch(profileAction.returnToPhotoList("return"));
    }
  }

  return (
    <div
      className="user-content-item photos add-photos"
      style={{ display: "block" }}
    >
      <div className="user-content-text">
        <p>
          Aşağıdaki kritik bilgilerde (Bayi Tabela Adınız, Bayi Kodunuz, TAPDK
          Ruhsat Numaranız, Adınız, Soyadınız, Adresiniz ve Cep Telefonunuz)
          değişiklik yapabilmek için 0850 532 5 584 (JTI) numaralı Çağrı
          Merkezi’mizi arayabilir, TAPDK ruhsat numaranızı ve bayi kodunuzu
          kullanarak müşteri temsilcimizle görüşebilirsiniz.
          <br />
          <br />
          Bayi kodunuzu bilmiyorsanız JTI satış temsilcinizden öğrenebilirsiniz
          <br />
          <br />
          Diğer bilgilerinizi güncellemek için form alanında gerekli
          değişiklikleri yapıp, “Kaydet” butonuna tıklayın. Adres alanına
          girdiğiniz bilgiler, siparişlerinizin gönderimi için kullanılacağından
          bilgilerinizin doğruluğunu kontrol ediniz.
        </p>
      </div>

      <div className="photos-header">
        <h2>YENİ FOTOĞRAF YÜKLE</h2>
      </div>

      <div className="add-photos-container">
        <div className="add-photos-content">
          <div
            className="add-photos-content-img"
            style={{ height: "240px", width: "320px" }}
          >
            <div>
              {imgSrc ? (
                <img src={imgSrc} alt=""></img>
              ) : (
                <img src={DefaultProfileImg} alt=""></img>
              )}
            </div>
            {/* {selectedChoice === "SHOP" && imgSrc ? (
              <div className="photo-upload-confirmation">
                <input
                  type="checkbox"
                  defaultChecked
                  onChange={e =>
                    e.target.checked
                      ? setUsePermission(true)
                      : setUsePermission(false)
                  }
                ></input>
                <label>
                  Yüklediğiniz dükkan fotoğrafınızın Haftanın Dükkanı
                  Yarışması'nda kullanılmasını ister misiniz?
                </label>
              </div>
            ) : null} */}
          </div>
          <div className="add-photos-radio">
            <div className="add-photos-content-item">
              <div className="custom-control custom-radio text-left pl-4 pl-md-0">
                <input
                  type="radio"
                  id="profile-photos"
                  name="photos-radio"
                  className="custom-control-input"
                  checked={selectedChoice === "PROFILE" ? true : false}
                  onChange={() => {
                    setSelectedChoice("PROFILE");
                    setUsePermission(null);
                  }}
                ></input>
                <label
                  className="custom-control-label"
                  htmlFor="profile-photos"
                >
                  Profil Fotoğrafı
                </label>
              </div>

              <div className="custom-control custom-radio text-left pl-4 pl-md-0">
                <input
                  type="radio"
                  id="shop-photos"
                  name="photos-radio"
                  className="custom-control-input"
                  checked={selectedChoice === "SHOP" ? true : false}
                  onChange={() => {
                    setSelectedChoice("SHOP");
                    setUsePermission(true);
                  }}
                ></input>
                <label className="custom-control-label" htmlFor="shop-photos">
                  Dükkan Fotoğrafı
                </label>
              </div>
            </div>
            <div className="submit">
              <input
                type="file"
                ref={uploadInput}
                style={{ display: "none" }}
                onChange={fileChangedHandler}
              />
              <button
                id="upload"
                type="submit"
                className="photos-load"
                onClick={() => {
                  uploadOrCancel();
                }}
              >
                YÜKLE
              </button>
              <button
                style={{ display: "none" }}
                id="cancel"
                type="submit"
                className="photos-load"
                onClick={() => {
                  uploadOrCancel();
                }}
              >
                VAZGEÇ
              </button>
              <button
                type="submit"
                className="photos-save"
                onClick={() => fileUpload(employeeFirm.id)}
              >
                KAYDET
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePhotoUpload;
