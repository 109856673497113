export const GET_HOW_PLAY_BY_FIRMID_PENDING = "GET_HOW_PLAY_BY_FIRMID_PENDING";
export const GET_HOW_PLAY_BY_FIRMID_FULFILLED =
  "GET_HOW_PLAY_BY_FIRMID_FULFILLED";
export const GET_HOW_PLAY_BY_FIRMID_REJECTED =
  "GET_HOW_PLAY_BY_FIRMID_REJECTED";

export const GET_DISCLAIMER_BY_FIRMID_PENDING =
  "GET_DISCLAIMER_BY_FIRMID_PENDING";
export const GET_DISCLAIMER_BY_FIRMID_FULFILLED =
  "GET_DISCLAIMER_BY_FIRMID_FULFILLED";
export const GET_DISCLAIMER_BY_FIRMID_REJECTED =
  "GET_DISCLAIMER_BY_FIRMID_REJECTED";

export const SAVE_COMPETITION_DISCLAIMER_PENDING =
  "SAVE_COMPETITION_DISCLAIMER_PENDING";
export const SAVE_COMPETITION_DISCLAIMER_FULFILLED =
  "SAVE_COMPETITION_DISCLAIMER_FULFILLED";
export const SAVE_COMPETITION_DISCLAIMER_REJECTED =
  "SAVE_COMPETITION_DISCLAIMER_REJECTED";

export const GET_ALL_TRUE_ANSWER_BY_FIRMID_PENDING =
  "GET_ALL_TRUE_ANSWER_BY_FIRMID_PENDING";
export const GET_ALL_TRUE_ANSWER_BY_FIRMID_FULFILLED =
  "GET_ALL_TRUE_ANSWER_BY_FIRMID_FULFILLED";
export const GET_ALL_TRUE_ANSWER_BY_FIRMID_REJECTED =
  "GET_ALL_TRUE_ANSWER_BY_FIRMID_REJECTED";

export const GET_WINNER_BY_FIRMID_PENDING = "GET_WINNER_BY_FIRMID_PENDING";
export const GET_WINNER_BY_FIRMID_FULFILLED = "GET_WINNER_BY_FIRMID_FULFILLED";
export const GET_WINNER_BY_FIRMID_REJECTED = "GET_WINNER_BY_FIRMID_REJECTED";

export const GET_AVAILABLE_COMPETITION_BY_FIRMID_PENDING =
  "GET_AVAILABLE_COMPETITION_BY_FIRMID_PENDING";
export const GET_AVAILABLE_COMPETITION_BY_FIRMID_FULFILLED =
  "GET_AVAILABLE_COMPETITION_BY_FIRMID_FULFILLED";
export const GET_AVAILABLE_COMPETITION_BY_FIRMID_REJECTED =
  "GET_AVAILABLE_COMPETITION_BY_FIRMID_REJECTED";

export const GET_COMPETITION_BY_FIRMID_PENDING =
  "GET_COMPETITION_BY_FIRMID_PENDING";
export const GET_COMPETITION_BY_FIRMID_FULFILLED =
  "GET_COMPETITION_BY_FIRMID_FULFILLED";
export const GET_COMPETITION_BY_FIRMID_REJECTED =
  "GET_COMPETITION_BY_FIRMID_REJECTED";

export const GET_COMPETITION_DATE_PENDING = "GET_COMPETITION_DATE_PENDING";
export const GET_COMPETITION_DATE_FULFILLED = "GET_COMPETITION_DATE_FULFILLED";
export const GET_COMPETITION_DATE_REJECTED = "GET_COMPETITION_DATE_REJECTED";

export const SAVE_COMPETITION_ANSWER_PENDING =
  "SAVE_COMPETITION_ANSWER_PENDING";
export const SAVE_COMPETITION_ANSWER_FULFILLED =
  "SAVE_COMPETITION_ANSWER_FULFILLED";
export const SAVE_COMPETITION_ANSWER_REJECTED =
  "SAVE_COMPETITION_ANSWER_REJECTED";

export const GET_RESULT_COMPETITION_BY_FIRMID_PENDING =
  "GET_RESULT_COMPETITION_BY_FIRMID_PENDING";
export const GET_RESULT_COMPETITION_BY_FIRMID_FULFILLED =
  "GET_RESULT_COMPETITION_BY_FIRMID_FULFILLED";
export const GET_RESULT_COMPETITION_BY_FIRMID_REJECTED =
  "GET_RESULT_COMPETITION_BY_FIRMID_REJECTED";

export const SELECTED_OPTION = "SELECTED_OPTION";

export const GET_ALL_TRUE_ANSWER_BY_FILTER_PENDING =
  "GET_ALL_TRUE_ANSWER_BY_FILTER_PENDING";
export const GET_ALL_TRUE_ANSWER_BY_FILTER_FULFILLED =
  "GET_ALL_TRUE_ANSWER_BY_FILTER_FULFILLED";
export const GET_ALL_TRUE_ANSWER_BY_FILTER_REJECTED =
  "GET_ALL_TRUE_ANSWER_BY_FILTER_REJECTED";

export const SET_ACTIVE_MENU_ITEM_PENDING = "SET_ACTIVE_MENU_ITEM_PENDING";
export const SET_ACTIVE_MENU_ITEM_FULFILLED = "SET_ACTIVE_MENU_ITEM_FULFILLED";
export const SET_ACTIVE_MENU_ITEM_REJECTED = "SET_ACTIVE_MENU_ITEM_REJECTED";
