import * as gameTypes from "./game.type";
import { getActiveMenuItem } from "./game.action";
import * as brandStyleTypes from "../brandStyle/brandStyle.type";

const initialState = {
  howPlayList: null,
  disclaimerList: null,
  allTrueAnswerList: null,
  winnerList: null,
  controlCompetition: null,
  competitionList: null,
  resultCompetition: null,
  competitionDate: null,
  error: null,
  totalPages: null,
  isLoading: false,
  indexMenu: 0
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${gameTypes.GET_HOW_PLAY_BY_FIRMID_PENDING}`:
    case `${gameTypes.GET_DISCLAIMER_BY_FIRMID_PENDING}`:
    case `${gameTypes.SAVE_COMPETITION_DISCLAIMER_PENDING}`:
    case `${gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_PENDING}`:
    case `${gameTypes.GET_WINNER_BY_FIRMID_PENDING}`:
    case `${gameTypes.SAVE_COMPETITION_ANSWER_PENDING}`:
    case `${gameTypes.GET_AVAILABLE_COMPETITION_BY_FIRMID_PENDING}`:
    case `${gameTypes.GET_COMPETITION_BY_FIRMID_PENDING}`:
    case `${gameTypes.GET_RESULT_COMPETITION_BY_FIRMID_PENDING}`:
    case `${gameTypes.GET_ALL_TRUE_ANSWER_BY_FILTER_PENDING}`:
    case `${gameTypes.GET_COMPETITION_DATE_PENDING}`:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case `${gameTypes.GET_HOW_PLAY_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        howPlayList: payload.data
      };

    case `${gameTypes.GET_HOW_PLAY_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        disclaimerList: payload.data
      };

    case `${gameTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.SAVE_COMPETITION_DISCLAIMER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${gameTypes.SAVE_COMPETITION_DISCLAIMER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        allTrueAnswerList: payload.competitionParticipantOrderDTOS,
        totalPages: payload.totalPages
      };

    case `${gameTypes.GET_ALL_TRUE_ANSWER_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.GET_ALL_TRUE_ANSWER_BY_FILTER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        allTrueAnswerList: payload.data
      };

    case `${gameTypes.GET_ALL_TRUE_ANSWER_BY_FILTER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.GET_WINNER_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        winnerList: payload.data
      };

    case `${gameTypes.GET_WINNER_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.GET_AVAILABLE_COMPETITION_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        controlCompetition: payload.data
      };

    case `${gameTypes.GET_AVAILABLE_COMPETITION_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.GET_COMPETITION_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        competitionList: payload.data
      };

    case `${gameTypes.GET_COMPETITION_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.SAVE_COMPETITION_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${gameTypes.SAVE_COMPETITION_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.SELECTED_OPTION}`:
      return {
        ...state,
        selectedOption: payload
      };

    case `${gameTypes.GET_RESULT_COMPETITION_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        resultCompetition: payload.data
      };

    case `${gameTypes.GET_RESULT_COMPETITION_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.GET_COMPETITION_DATE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        competitionDate: payload.data
      };

    case `${gameTypes.GET_COMPETITION_DATE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${gameTypes.SET_ACTIVE_MENU_ITEM_FULFILLED}`:
      return {
        ...state,
        indexMenu: payload
      };
    case `${gameTypes.SET_ACTIVE_MENU_ITEM_PENDING}`:
      return {
        ...state,
        indexMenu: false
      };
    case `${gameTypes.SET_ACTIVE_MENU_ITEM_REJECTED}`:
      return {
        ...state,
        indexMenu: false
      };

    default:
      return state;
  }
};

export default reducer;
