import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { widgetAction } from "redux/modules";
import { useLocation, useHistory, Link } from "react-router-dom";
import { imageShowUrl } from "../../../config/api.config";
import $ from "jquery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import "./UserPortalWidget.scss";
import "animate.css";

const UserPortalWidget = props => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const widgetList = useSelector(store => store.widget.widgetList);
  const [widgetListData, setWidgetListData] = useState(null);

  useEffect(() => {
    $(document).on("click", function(e) {
      if ($(e.target).closest(".wrapper-widget").length == 0) {
        closeAccordion();
        $(".wrapper-widget-image").click();
        $("#collapseWidget").removeClass("show");
      }
    });

    $(".dropdown-toggle").on("click", function(event) {
      closeAccordion();
      $(".wrapper-widget-image").click();
      $("#collapseWidget").removeClass("show");
    });

    dispatch(widgetAction.getWidget());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    setWidgetListData(widgetList);

    return () => {};
  }, [widgetList]);

  function toggleAccordion() {
    const widgetWrapper = document.querySelectorAll(".wrapper-widget");
    for (let i = 0; i < widgetWrapper.length; i++) {
      widgetWrapper[i].classList.toggle("active");
    }
  }

  function closeAccordion() {
    const widgetWrapper = document.querySelectorAll(".wrapper-widget");
    for (let i = 0; i < widgetWrapper.length; i++) {
      widgetWrapper[i].classList.remove("active");
    }
  }

  return (
    <>
      <div className="wrapper-widget">
        {widgetListData
          ? widgetListData.map((item, index) => {
              return (
                <div key={index}>
                  {item.widgetType &&
                  item.widgetType === "LAUNCH" &&
                  item.widgetContentDtos &&
                  location.pathname === "/userportal/camel" ? (
                    <div className="wrapper-widget-content">
                      <div
                        className={
                          item.newContent
                            ? "wrapper-widget-image-container animate__animated animate__bounce"
                            : "wrapper-widget-image-container"
                        }
                      >
                        <a
                          className="wrapper-widget-image collapsed"
                          data-toggle="collapse"
                          href="#collapseWidget"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseWidget"
                          onClick={() => toggleAccordion()}
                        >
                          <h4>Benim Bayim'den Haberler</h4>

                          <div className="widget-image-content position-relative">
                            {item.newContent === true && (
                              <div className="widget-new-content">
                                <svg viewBox="0 0 500 500">
                                  <path
                                    id="curve"
                                    d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
                                  />
                                  <text width="500">
                                    <textPath xlinkHref="#curve">YENİ</textPath>
                                  </text>
                                </svg>
                              </div>
                            )}

                            <img
                              src={imageShowUrl + item.image}
                              className="img-fluid"
                            />
                          </div>
                        </a>
                      </div>

                      <div className="collapse" id="collapseWidget">
                        <div className="accordion" id="accordionWidget">
                          {item.widgetContentDtos &&
                            item.widgetContentDtos.map(
                              (widgetItem, widgetIndex) => {
                                return (
                                  <div className="card" key={widgetIndex}>
                                    <div
                                      className="card-header"
                                      id={"heading" + widgetIndex}
                                    >
                                      <h2>
                                        <button
                                          className="btn btn-block text-left collapsed"
                                          type="button"
                                          data-toggle="collapse"
                                          data-target={
                                            "#collapse" + widgetIndex
                                          }
                                          aria-expanded="true"
                                          aria-controls={
                                            "#collapse" + widgetIndex
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            className="plus-or-minus"
                                            style={{
                                              fontSize: "12px",
                                              color: "#575859"
                                            }}
                                          />

                                          <FontAwesomeIcon
                                            icon={faMinus}
                                            className="plus-or-minus"
                                            style={{
                                              fontSize: "12px",
                                              color: "#575859"
                                            }}
                                          />

                                          {widgetItem.contentTitle}
                                        </button>
                                      </h2>
                                    </div>

                                    <div
                                      id={"collapse" + widgetIndex}
                                      className="collapse"
                                      aria-labelledby={"heading" + widgetIndex}
                                      data-parent="#accordionWidget"
                                    >
                                      <div className="card-body">
                                        {widgetItem.detailTitle && (
                                          <h3>{widgetItem.detailTitle}</h3>
                                        )}
                                        {widgetItem.detailDescription && (
                                          <p>{widgetItem.detailDescription}</p>
                                        )}
                                        {widgetItem.detailUrl && (
                                          <Link
                                            to={{
                                              pathname: widgetItem.detailUrl,
                                              brandId: 149465,
                                              brandIndex: 2,
                                              handleImgClick: true
                                            }}
                                            target={
                                              widgetItem.newTab
                                                ? "_blank"
                                                : "_self"
                                            }
                                          >
                                            Detayını Gör
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  ) : item.widgetType &&
                    item.widgetType === "EORDER" &&
                    item.widgetContentDtos &&
                    (location.pathname === "/userportal/eorder" ||
                      location.pathname === "/userportal/eOrderHistory" ||
                      location.pathname === "/userportal/eorderBridge" ||
                      location.pathname === "/userportal/eorderSummary" ||
                      location.pathname === "/userportal/eorderDetail") ? (
                    <div className="wrapper-widget-content" key={index}>
                      <div
                        className={
                          item.newContent &&
                          location.pathname === "/userportal/eorderBridge"
                            ? "wrapper-widget-image-container animate__animated animate__bounce"
                            : "wrapper-widget-image-container"
                        }
                      >
                        <a
                          className="wrapper-widget-image collapsed"
                          data-toggle="collapse"
                          href="#collapseWidget"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseWidget"
                          onClick={() => toggleAccordion()}
                        >
                          <h4>Benim Bayim'den Haberler</h4>

                          <div className="widget-image-content position-relative">
                            {item.newContent === true && (
                              <div className="widget-new-content">
                                <svg viewBox="0 0 500 500">
                                  <path
                                    id="curve"
                                    d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
                                  />
                                  <text width="500">
                                    <textPath xlinkHref="#curve">YENİ</textPath>
                                  </text>
                                </svg>
                              </div>
                            )}

                            <img
                              src={imageShowUrl + item.image}
                              className="img-fluid"
                            />
                          </div>
                        </a>
                      </div>

                      <div className="collapse" id="collapseWidget">
                        <div className="accordion" id="accordionWidget">
                          {item.widgetContentDtos &&
                            item.widgetContentDtos.map(
                              (widgetItem, widgetIndex) => {
                                return (
                                  <div className="card" key={widgetIndex}>
                                    <div
                                      className="card-header"
                                      id={"heading" + widgetIndex}
                                    >
                                      <h2>
                                        <button
                                          className="btn btn-block text-left collapsed"
                                          type="button"
                                          data-toggle="collapse"
                                          data-target={
                                            "#collapse" + widgetIndex
                                          }
                                          aria-expanded="true"
                                          aria-controls={
                                            "#collapse" + widgetIndex
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            className="plus-or-minus"
                                            style={{
                                              fontSize: "12px",
                                              color: "#575859"
                                            }}
                                          />

                                          <FontAwesomeIcon
                                            icon={faMinus}
                                            className="plus-or-minus"
                                            style={{
                                              fontSize: "12px",
                                              color: "#575859"
                                            }}
                                          />

                                          {widgetItem.contentTitle}
                                        </button>
                                      </h2>
                                    </div>

                                    <div
                                      id={"collapse" + widgetIndex}
                                      className="collapse"
                                      aria-labelledby={"heading" + widgetIndex}
                                      data-parent="#accordionWidget"
                                    >
                                      <div className="card-body">
                                        {widgetItem.detailTitle && (
                                          <h3>{widgetItem.detailTitle}</h3>
                                        )}
                                        {widgetItem.detailDescription && (
                                          <p>{widgetItem.detailDescription}</p>
                                        )}
                                        {widgetItem.detailUrl && (
                                          <Link
                                            to={{
                                              pathname: widgetItem.detailUrl,
                                              brandId: 149465,
                                              brandIndex: 2,
                                              handleImgClick: true
                                            }}
                                            target={
                                              widgetItem.newTab
                                                ? "_blank"
                                                : "_self"
                                            }
                                          >
                                            Detayını Gör
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  ) : item.widgetType &&
                    item.widgetType === "STANDART" &&
                    item.widgetContentDtos &&
                    !(
                      location.pathname === "/userportal/eorder" ||
                      location.pathname === "/userportal/eOrderHistory" ||
                      location.pathname === "/userportal/eorderBridge" ||
                      location.pathname === "/userportal/eorderSummary" ||
                      location.pathname === "/userportal/eorderDetail" ||
                      location.pathname === "/userportal/camel"
                    ) ? (
                    <div className="wrapper-widget-content" key={index}>
                      <div
                        className={
                          item.newContent && location.pathname === "/"
                            ? "wrapper-widget-image-container animate__animated animate__bounce"
                            : "wrapper-widget-image-container"
                        }
                      >
                        <a
                          className="wrapper-widget-image collapsed"
                          data-toggle="collapse"
                          href="#collapseWidget"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseWidget"
                          onClick={() => toggleAccordion()}
                        >
                          <h4>Benim Bayim'den Haberler</h4>

                          <div className="widget-image-content position-relative">
                            {item.newContent === true && (
                              <div className="widget-new-content">
                                <svg viewBox="0 0 320 320">
                                  <path
                                    id="curve"
                                    d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
                                  />
                                  <text width="500">
                                    <textPath xlinkHref="#curve">YENİ</textPath>
                                  </text>
                                </svg>
                              </div>
                            )}

                            <img
                              src={imageShowUrl + item.image}
                              className="img-fluid"
                            />
                          </div>
                        </a>
                      </div>

                      <div className="collapse" id="collapseWidget">
                        <div className="accordion" id="accordionWidget">
                          {item.widgetContentDtos &&
                            item.widgetContentDtos.map(
                              (widgetItem, widgetIndex) => {
                                return (
                                  <div className="card" key={widgetIndex}>
                                    <div
                                      className="card-header"
                                      id={"heading" + widgetIndex}
                                    >
                                      <h2>
                                        <button
                                          className="btn btn-block text-left collapsed"
                                          type="button"
                                          data-toggle="collapse"
                                          data-target={
                                            "#collapse" + widgetIndex
                                          }
                                          aria-expanded="true"
                                          aria-controls={
                                            "#collapse" + widgetIndex
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            className="plus-or-minus"
                                            style={{
                                              fontSize: "12px",
                                              color: "#575859"
                                            }}
                                          />

                                          <FontAwesomeIcon
                                            icon={faMinus}
                                            className="plus-or-minus"
                                            style={{
                                              fontSize: "12px",
                                              color: "#575859"
                                            }}
                                          />
                                          {widgetItem.contentTitle}
                                        </button>
                                      </h2>
                                    </div>

                                    <div
                                      id={"collapse" + widgetIndex}
                                      className="collapse"
                                      aria-labelledby={"heading" + widgetIndex}
                                      data-parent="#accordionWidget"
                                    >
                                      <div className="card-body">
                                        {widgetItem.detailTitle && (
                                          <h3>{widgetItem.detailTitle}</h3>
                                        )}
                                        {widgetItem.detailDescription && (
                                          <p>{widgetItem.detailDescription}</p>
                                        )}
                                        {widgetItem.detailUrl && (
                                          <Link
                                            to={{
                                              pathname: widgetItem.detailUrl,
                                              brandId: 149465,
                                              brandIndex: 2,
                                              handleImgClick: true
                                            }}
                                            target={
                                              widgetItem.newTab
                                                ? "_blank"
                                                : "_self"
                                            }
                                          >
                                            Detayını Gör
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </>
  );
};

export default UserPortalWidget;
