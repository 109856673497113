import * as newsTypes from "./news.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getFinance = () => async dispatch => {
  dispatch({ type: newsTypes.GET_NEWS_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/get/anatolian/agency/finance`,
      config
    );
    dispatch({
      type: newsTypes.GET_NEWS_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: newsTypes.GET_NEWS_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getNewsByCategory = (
  categoryType,
  page,
  size
) => async dispatch => {
  dispatch({ type: newsTypes.GET_NEWS_BY_CATEGORY_PENDING });

  const data = {
    categoryType: categoryType
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/get/all/anatolian/agency/news/category?page=${page}&size=${size}`,
      data,
      config
    );
    dispatch({
      type: newsTypes.GET_NEWS_BY_CATEGORY_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: newsTypes.GET_NEWS_BY_CATEGORY_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getNewsByCategoryContent = (
  categoryType,
  page,
  size
) => async dispatch => {
  dispatch({ type: newsTypes.GET_NEWS_BY_CATEGORY_CONTENT_PENDING });

  const data = {
    categoryType: categoryType
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/get/all/anatolian/agency/news/category/content?page=${page}&size=${size}`,
      data,
      config
    );
    dispatch({
      type: newsTypes.GET_NEWS_BY_CATEGORY_CONTENT_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: newsTypes.GET_NEWS_BY_CATEGORY_CONTENT_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getNewsById = id => async dispatch => {
  dispatch({ type: newsTypes.GET_NEWS_BY_ID_PENDING });

  const data = {
    id: id
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/get/anatolian/agency/news`,
      data,
      config
    );
    dispatch({
      type: newsTypes.GET_NEWS_BY_ID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: newsTypes.GET_NEWS_BY_ID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};
