import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { Redirect } from "react-router-dom";
import { imageShowUrl } from "../../../../config/api.config";

const CompetitionRule = props => {
  const dispatch = useDispatch();
  const [gift, setGift] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);

  const [disclaimerList, setDisclaimerList] = useState([]);

  useEffect(() => {
    if (!disclaimerList || disclaimerList.length === 0) {
      dispatch(
        gameAction.getCompetitionDisclaimerByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      ).then(response => {
        setLoaded(true);
        setDisclaimerList(response.data);
      });
    }

    return () => {};
  });

  useEffect(() => {
    if (gift.length == 0) {
      dispatch(gameAction.getCompetetionGift()).then(response => {
        if (typeof response.data === "undefined") {
          setGift({
            giftStatus: true,
            headerName: "Tanımlanmış herhangi bir hediye yok",
            name: "Herhangi bir ürün yok",
            description: "Herhangi bir ürün yok"
          });
          setLoaded(true);
        } else {
          setGift(response.data[0]);
          setLoaded(true);
        }
      });
    }
    return () => {};
  });

  return (
    <div
      className="wrapper-scroller d-flex p-0 m-0"
      style={{
        color:
          disclaimerList && disclaimerList[0] && disclaimerList[0].primaryColor
            ? disclaimerList[0].primaryColor
            : "#FFF"
      }}
    >
      {/* <GlobalLoader isLoading={!loaded} /> */}
      {/*  <h1 className="text-center">{gift.headerName}</h1> */}
      {!gift.giftStatus ? (
        <div className="gift-content wrapper-question-item">
          <div className="row col-12 mt-0 m-0">
            <div className="col-12 mb-3 text-center">
              <h2
                className="mb-3"
                dangerouslySetInnerHTML={{ __html: gift.description }}
                style={{
                  color:
                    disclaimerList &&
                    disclaimerList[0] &&
                    disclaimerList[0].primaryColor
                      ? disclaimerList[0].primaryColor
                      : "#FFF"
                }}
              />
            </div>
            <div className="col-12 text-center">
              <img
                className="img-fluid mx-auto"
                src={imageShowUrl + gift.icon}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default CompetitionRule;
