export const GET_DISCLAIMER_BY_FIRMID_PENDING =
  "GET_DISCLAIMER_BY_FIRMID_PENDING";
export const GET_DISCLAIMER_BY_FIRMID_FULFILLED =
  "GET_DISCLAIMER_BY_FIRMID_FULFILLED";
export const GET_DISCLAIMER_BY_FIRMID_REJECTED =
  "GET_DISCLAIMER_BY_FIRMID_REJECTED";

export const SAVE_DISCLAIMER_CHECK_PENDING = "SAVE_DISCLAIMER_CHECK_PENDING";
export const SAVE_DISCLAIMER_CHECK_FULFILLED =
  "SAVE_DISCLAIMER_CHECK_FULFILLED";
export const SAVE_DISCLAIMER_CHECK_REJECTED = "SAVE_DISCLAIMER_CHECK_REJECTED";
