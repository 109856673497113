import React, { useEffect } from "react";

import $ from "jquery";
import { Link } from "react-router-dom";

import "./style.scss";

// New Design Imports
// New Design Imports
import Atropos from "atropos/react";

import Atropos1 from "assets/images/new-design/atropos/1-bg.png";
import Atropos11 from "assets/images/new-design/atropos/1-1.png";
import Atropos12 from "assets/images/new-design/atropos/1-2.png";
import Atropos13 from "assets/images/new-design/atropos/1-3.png";
import Atropos14 from "assets/images/new-design/atropos/1-4.png";
import Atropos15 from "assets/images/new-design/atropos/1-5.png";

import Atropos2 from "assets/images/new-design/atropos/2-bg.png";
import Atropos20 from "assets/images/new-design/atropos/2-0.png";
import Atropos21 from "assets/images/new-design/atropos/2-1.png";
import Atropos22 from "assets/images/new-design/atropos/2-2.png";
import Atropos23 from "assets/images/new-design/atropos/2-3.png";
import Atropos24 from "assets/images/new-design/atropos/2-4.png";
import Atropos25 from "assets/images/new-design/atropos/2-5.png";
import Atropos26 from "assets/images/new-design/atropos/2-6.png";

import Atropos3 from "assets/images/new-design/atropos/3-bg.png";
import Atropos30 from "assets/images/new-design/atropos/3-0.png";
import Atropos31 from "assets/images/new-design/atropos/3-1.png";

import Atropos4 from "assets/images/new-design/atropos/4-bg.png";
import Atropos40 from "assets/images/new-design/atropos/4-0.png";

import { IoArrowForward } from "react-icons/io5";

const CompetitionMain = () => {
  useEffect(() => {
    $("html, body").animate({ scrollTop: 0 });

    return () => {};
  }, []);

  return (
    <section>
      <section className="glaire bg-[#16435E] py-6 md:py-12 flex flex-col justify-center items-center min-h-[146px]">
        <div className="container mx-auto px-4">
          <h2 className="text-center text-2xl font-semibold leading-none text-white md:text-[32px]">
            Yarışmalar
          </h2>
        </div>
      </section>

      <div className="container mx-auto max-w-[1280px] py-16 px-4">
        <div className="grid grid-cols-6 gap-2 lg:gap-4">
          <Atropos
            shadowScale={1.05}
            className="col-span-6 cursor-pointer max-lg:h-[180px] md:col-span-3 lg:col-span-2"
          >
            <Link to={`/userportal/searchFind`}>
              <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-r-[100px]">
                <div
                  data-atropos-offset="10"
                  className="absolute z-10 max-lg:left-[24px] max-lg:top-[32px]"
                >
                  <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-sm text-white">
                    Yarışma
                  </span>
                  <h2 className="text-[36px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                    Rozet <br className="max-lg:block lg:hidden" /> Macerası
                  </h2>
                </div>

                <img
                  src={Atropos1}
                  alt=""
                  data-atropos-offset="-5"
                  className="absolute -left-[50px] -top-[50px] z-0 w-[500px] !max-w-none max-lg:w-[900px]"
                />

                <div className="absolute w-[400px] max-lg:-bottom-[10px] max-lg:-right-[100px] max-lg:scale-[50%] lg:left-1/2 lg:top-0 lg:h-full lg:-translate-x-[50%] lg:transform">
                  <img
                    src={Atropos11}
                    alt=""
                    data-atropos-offset="10"
                    className="absolute -bottom-[10px] left-[55px] z-10"
                  />
                  <img
                    src={Atropos12}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute bottom-[155px] left-[75px] z-10"
                  />
                  <img
                    src={Atropos13}
                    alt=""
                    data-atropos-offset="100"
                    className="absolute bottom-[160px] left-[270px] z-10"
                  />
                  <img
                    src={Atropos14}
                    alt=""
                    data-atropos-offset="300"
                    className="absolute bottom-[280px] left-[300px] z-10"
                  />
                  <img
                    src={Atropos15}
                    alt=""
                    data-atropos-offset="150"
                    className="absolute bottom-[70px] left-[80px] z-10"
                  />
                </div>
              </div>
            </Link>
          </Atropos>
          <Atropos
            shadowScale={1.05}
            className="col-span-6 cursor-pointer max-lg:h-[180px] md:col-span-3 lg:col-span-2"
          >
            <Link to={`/userportal/pickFind`}>
              <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-tl-[100px]">
                <div
                  data-atropos-offset="10"
                  className="absolute z-10 max-lg:left-[24px] max-lg:top-[56px]"
                >
                  <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-sm text-white">
                    Yarışma
                  </span>
                  <h2 className="text-[36px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                    Eşle Kazan
                  </h2>
                </div>

                <img
                  src={Atropos2}
                  alt=""
                  data-atropos-offset="-5"
                  className="absolute -left-[50px] -top-[50px] z-0 w-[500px] !max-w-none max-lg:w-[900px]"
                />

                <div className="absolute w-[400px] max-lg:-bottom-[10px] max-lg:-right-[100px] max-lg:scale-[50%] lg:left-1/2 lg:top-0 lg:h-full lg:w-[400px] lg:-translate-x-[50%] lg:transform">
                  <img
                    src={Atropos20}
                    alt=""
                    data-atropos-offset="0"
                    className="absolute bottom-[240px] left-[60px] z-10"
                  />
                  <img
                    src={Atropos21}
                    alt=""
                    data-atropos-offset="10"
                    className="absolute bottom-[90px] left-[100px] z-10"
                  />
                  <img
                    src={Atropos22}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute -bottom-[20px] left-[240px] z-10"
                  />
                  <img
                    src={Atropos23}
                    alt=""
                    data-atropos-offset="20"
                    className="absolute bottom-[160px] left-[145px] z-10"
                  />
                  <img
                    src={Atropos24}
                    alt=""
                    data-atropos-offset="80"
                    className="absolute bottom-[160px] left-[280px] z-10"
                  />
                  <img
                    src={Atropos25}
                    alt=""
                    data-atropos-offset="100"
                    className="absolute bottom-[70px] left-[80px] z-10"
                  />
                  <img
                    src={Atropos26}
                    alt=""
                    data-atropos-offset="150"
                    className="absolute bottom-[280px] left-[160px] z-10"
                  />
                </div>
              </div>
            </Link>
          </Atropos>
          <div className="col-span-6 grid grid-cols-1 gap-2 md:grid-cols-2 lg:col-span-2 lg:grid-cols-1 lg:gap-4">
            <Atropos
              shadowScale={1.05}
              className="h-[260px] cursor-pointer max-lg:h-[180px]"
            >
              <Link to={`/userportal/scoreHunt`}>
                <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-bl-[100px]">
                  <div
                    data-atropos-offset="10"
                    className="absolute left-[48px] top-[100px] z-20 max-lg:left-[24px] max-lg:top-[56px]"
                  >
                    <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-sm text-white">
                      Yarışma
                    </span>
                    <h2 className="text-[24px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                      Puan Avı
                    </h2>
                  </div>

                  <img
                    src={Atropos3}
                    alt=""
                    data-atropos-offset="-5"
                    className="absolute -left-[50px] -top-[30px] z-0 w-[500px] !max-w-none max-lg:w-[900px]"
                  />
                  <div className="">
                    <img
                      src={Atropos30}
                      alt=""
                      data-atropos-offset="10"
                      className="absolute z-10 max-lg:bottom-[10px] max-lg:right-[0] max-lg:!scale-[70%] lg:bottom-[50px] lg:right-[0] xl:right-[20px]"
                    />
                    <img
                      src={Atropos31}
                      alt=""
                      data-atropos-offset="40"
                      className="absolute z-10 max-lg:-right-[20px] max-lg:bottom-[10px] max-lg:!scale-[80%] lg:-right-[30px] lg:bottom-[20px] xl:-right-[10px]"
                    />
                  </div>
                </div>
              </Link>
            </Atropos>
            <Atropos
              shadowScale={1.05}
              className="h-[260px] cursor-pointer max-lg:h-[180px]"
            >
              <Link to={`/userportal/weekStore`}>
                <div className="relative h-full overflow-hidden rounded-xl p-12 lg:rounded-tr-[100px]">
                  <div
                    data-atropos-offset="10"
                    className="absolute right-[48px] top-[70px] z-20 max-lg:left-[24px] max-lg:top-[32px]"
                  >
                    <span className="mb-2 inline-block rounded-full bg-black/25 px-3 py-1 text-sm text-white">
                      Yarışma
                    </span>
                    <h2 className="text-[24px] font-bold text-white drop-shadow-lg max-lg:text-[24px]">
                      Haftanın <br />
                      Dükkanı
                    </h2>
                  </div>

                  <img
                    src={Atropos4}
                    alt=""
                    data-atropos-offset="-5"
                    className="absolute -left-[50px] -top-[30px] z-0 w-[500px] !max-w-none max-lg:w-[900px]"
                  />
                  <div className="absolute z-10 max-lg:-bottom-[35px] max-lg:right-[10px] lg:-left-[20px] lg:bottom-0 xl:left-[20px]">
                    <img
                      src={Atropos40}
                      alt=""
                      data-atropos-offset="10"
                      className="max-lg:!scale-[70%]"
                    />
                  </div>
                </div>
              </Link>
            </Atropos>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CompetitionMain;
