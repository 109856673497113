export const GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_PENDING =
  "GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_PENDING";
export const GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_FULFILLED =
  "GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_FULFILLED";
export const GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_REJECTED =
  "GET_SUBJECTLIST_BY_EMPLOYEEFIRMID_REJECTED";

export const GET_MESSAGELIST_BY_SUBJECTID_PENDING =
  "GET_MESSAGELIST_BY_SUBJECTID_PENDING";
export const GET_MESSAGELIST_BY_SUBJECTID_FULFILLED =
  "GET_MESSAGELIST_BY_SUBJECTID_FULFILLED";
export const GET_MESSAGELIST_BY_SUBJECTID_REJECTED =
  "GET_MESSAGELIST_BY_SUBJECTID_REJECTED";

export const GET_MESSAGE_NUMBER_PENDING = "GET_MESSAGE_NUMBER_PENDING";
export const GET_MESSAGE_NUMBER_FULFILLED = "GET_MESSAGE_NUMBER_FULFILLED";
export const GET_MESSAGE_NUMBER_REJECTED = "GET_MESSAGE_NUMBER_REJECTED";

export const SAVE_NEW_MESSAGE_PENDING = "SAVE_NEW_MESSAGE_PENDING";
export const SAVE_NEW_MESSAGE_FULFILLED = "SAVE_NEW_MESSAGE_FULFILLED";
export const SAVE_NEW_MESSAGE_REJECTED = "SAVE_NEW_MESSAGE_REJECTED";

export const SET_PROFILE_CONTENT_PENDING = "SET_PROFILE_CONTENT_PENDING";
export const SET_PROFILE_CONTENT_FULFILLED = "SET_PROFILE_CONTENT_FULFILLED";
export const SET_PROFILE_CONTENT_REJECTED = "SET_PROFILE_CONTENT_REJECTED";

export const SAVE_INITIAL_MESSAGE_PENDING = "SAVE_INITIAL_MESSAGE_PENDING";
export const SAVE_INITIAL_MESSAGE_FULFILLED = "SAVE_INITIAL_MESSAGE_FULFILLED";
export const SAVE_INITIAL_MESSAGE_REJECTED = "SAVE_INITIAL_MESSAGE_REJECTED";

/* export const SET_PROFILE_ORIGIN_PENDING = "SET_PROFILE_ORIGIN_PENDING";
export const SET_PROFILE_ORIGIN_FULFILLED = "SET_PROFILE_ORIGIN_FULFILLED";
export const SET_PROFILE_ORIGIN_REJECTED = "SET_PROFILE_ORIGIN_REJECTED"; */

export const SET_SHOW_CONTACT_US_PENDING = "SET_SHOW_CONTACT_US_PENDING";
export const SET_SHOW_CONTACT_US_FULFILLED = "SET_SHOW_CONTACT_US_FULFILLED";
export const SET_SHOW_CONTACT_US_REJECTED = "SET_SHOW_CONTACT_US_REJECTED";

export const UPLOAD_PHOTO_PENDING = "UPLOAD_PHOTO_PENDING";
export const UPLOAD_PHOTO_FULFILLED = "UPLOAD_PHOTO_FULFILLED";
export const UPLOAD_PHOTO_REJECTED = "UPLOAD_PHOTO_REJECTED";

export const GET_EMPLOYEE_IMAGES_PENDING = "GET_EMPLOYEE_IMAGES_PENDING";
export const GET_EMPLOYEE_IMAGES_FULFILLED = "GET_EMPLOYEE_IMAGES_FULFILLED";
export const GET_EMPLOYEE_IMAGES_REJECTED = "GET_EMPLOYEE_IMAGES_REJECTED";

export const RETURN_TO_PHOTO_LIST_PENDING = "RETURN_TO_PHOTO_LIST_PENDING";
export const RETURN_TO_PHOTO_LIST_FULFILLED = "RETURN_TO_PHOTO_LIST_FULFILLED";
export const RETURN_TO_PHOTO_LIST_REJECTED = "RETURN_TO_PHOTO_LIST_REJECTED";

export const GET_PROFILE_IMAGE_PENDING = "GET_PROFILE_IMAGE_PENDING";
export const GET_PROFILE_IMAGE_FULFILLED = "GET_PROFILE_IMAGE_FULFILLED";
export const GET_PROFILE_IMAGE_REJECTED = "GET_PROFILE_IMAGE_REJECTED";

export const GO_TO_NEW_ADDRESS_PENDING = "GO_TO_NEW_ADDRESS_PENDING";
export const GO_TO_NEW_ADDRESS_FULFILLED = "GO_TO_NEW_ADDRESS_FULFILLED";
export const GO_TO_NEW_ADDRESS_REJECTED = "GO_TO_NEW_ADDRESS_REJECTED";

export const SET_ACTIVES_PENDING = "SET_ACTIVES_PENDING";
export const SET_ACTIVES_FULFILLED = "SET_ACTIVES_FULFILLED";
export const SET_ACTIVES_REJECTED = "SET_ACTIVES_REJECTED";

export const SET_SHOW_CROPPED_IMAGE_PENDING = "SET_SHOW_CROPPED_IMAGE_PENDING";
export const SET_SHOW_CROPPED_IMAGE_FULFILLED =
  "SET_SHOW_CROPPED_IMAGE_FULFILLED";
export const SET_SHOW_CROPPED_IMAGE_REJECTED =
  "SET_SHOW_CROPPED_IMAGE_REJECTED";

export const GO_TO_PENDING_PHOTO_TAB_PENDING =
  "GO_TO_PENDING_PHOTO_TAB_PENDING";
export const GO_TO_PENDING_PHOTO_TAB_FULFILLED =
  "GO_TO_PENDING_PHOTO_TAB_FULFILLED";
export const GO_TO_PENDING_PHOTO_TAB_REJECTED =
  "GO_TO_PENDING_PHOTO_TAB_REJECTED";

export const SAVE_MESSAGE_MANAGEMENT_PENDING =
  "SAVE_MESSAGE_MANAGEMENT_PENDING";
export const SAVE_MESSAGE_MANAGEMENT_FULFILLED =
  "SAVE_MESSAGE_MANAGEMENT_FULFILLED";
export const SAVE_MESSAGE_MANAGEMENT_REJECTED =
  "SAVE_MESSAGE_MANAGEMENT_REJECTED";

export const GET_PROFILE_BADGE_PENDING = "GET_PROFILE_BADGE_PENDING";
export const GET_PROFILE_BADGE_FULFILLED = "GET_PROFILE_BADGE_FULFILLED";
export const GET_PROFILE_BADGE_REJECTED = "GET_PROFILE_BADGE_REJECTED";

export const SET_PROFILE_BADGE_PENDING = "SET_PROFILE_BADGE_PENDING";
export const SET_PROFILE_BADGE_FULFILLED = "SET_PROFILE_BADGE_FULFILLED";
export const SET_PROFILE_BADGE_REJECTED = "SET_PROFILE_BADGE_REJECTED";
