import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { catalogAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowForward
} from "react-icons/io5";

const MagazineLeftMenu = props => {
  const dispatch = useDispatch();
  const categoryList = useSelector(store => store.catalog.categoryList);
  const actives = useSelector(store => store.catalog.actives);
  const activesSubCategory = useSelector(
    store => store.catalog.activesSubCategory
  );
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.catalog.isLoading);
  const sortingType = useSelector(store => store.catalog.sortingType);
  const sortAsc = useSelector(store => store.catalog.sortAsc);

  const sortByTypeAsc = useSelector(store => store.catalog.sortByTypeAsc);
  const homeOrderInfo = useSelector(store => store.home.homeOrderInfo);

  const selectedSubCategory = useSelector(
    store => store.catalog.selectedSubCategory
  );

  /*  const sortByTypeAsc = useSelector(
     store => store.catalog.sortByTypeAsc
   ); */
  const selectedCategory = useSelector(store => store.catalog.selectedCategory);

  const [inputSw, setInputSw] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [activeSub, setActiveSub] = useState(null);

  useEffect(() => {
    dispatch(catalogAction.getCategoryList(employeeFirm.firm.id));
    dispatch(catalogAction.getProductsBySubCatId(0, true, 0, 0));
    dispatch(catalogAction.getAddresses(employeeFirm.employee.id));
    return () => {};
  }, [dispatch, employeeFirm.employee.id, employeeFirm.firm.id, props]);

  useEffect(() => {
    const { category, subcategory } = props.props.match.params;
    const relItem = document.querySelectorAll(".category")[
      parseInt(category - 1)
    ];
    if (typeof relItem !== "undefined") {
      relItem.classList.add("active");
      setClicked(categoryList[parseInt(category - 1)], parseInt(category - 1));
      setClickedSubCategory(
        categoryList[parseInt(category - 1)],
        categoryList[parseInt(category - 1)].subCategoryList[
          parseInt(subcategory - 1)
        ],
        parseInt(subcategory - 1),
        categoryList[parseInt(category - 1)].subCategoryList[
          parseInt(subcategory - 1)
        ].id
      );
    }

    setTimeout(function() {
      if (window.localStorage.getItem("mothersDay") == "true") {
        const categoryElem = document.querySelectorAll(".category a.title");
        categoryElem.forEach(item => {
          if (item.innerHTML === "ANNELER GÜNÜ KAMPANYASI") {
            item.click();

            setTimeout(function() {
              const subCategoryElem = document.querySelectorAll(
                ".sub-category a.title"
              );
              subCategoryElem.forEach(subItem => {
                if (subItem.innerHTML === "ANNELER GÜNÜ KAMPANYASI") {
                  subItem.click();
                  window.localStorage.removeItem("mothersDay");
                }
              });
            }, 250);
          }
        });
      }
    }, 250);
  }, [actives, categoryList, props]);

  function setClicked(category, index2) {
    categoryList.map((item, index) => {
      if (actives[index2] === true && selectedCategory === category) {
        dispatch(catalogAction.setCategoryName(""));
        actives[index] = false;
      } else {
        if (index === index2) {
          if (actives[index] === true) {
            dispatch(catalogAction.setCategoryName(""));
            actives[index] = false;
          } else {
            dispatch(catalogAction.setCategoryName(category));
            actives[index] = true;
          }
        } else {
          actives[index] = false;
        }
      }
    });
    dispatch(catalogAction.setSubCategoryName(""));
  }

  function setClickedSubCategory(category, subCategory, index2, subCatId) {
    setActiveSub(category);

    dispatch(catalogAction.setSelectedProduct(""));
    categoryList.map((item, index) => {
      if (item.subCategoryList)
        item.subCategoryList.map((item2, index3) => {
          if (index3 === index2 && subCategory.id === item2.id)
            item2.activesSubCategory[0] = true;
          else item2.activesSubCategory[0] = false;
        });
    });

    dispatch(catalogAction.setCatalogContent());
    dispatch(catalogAction.setCategoryName(category));
    dispatch(catalogAction.setSubCategoryName(subCategory));
    dispatch(catalogAction.getProductsBySubCatId(subCatId, sortAsc, 0, 0));
    dispatch(catalogAction.setSortingByProductTypeAsc("ALL"));
    dispatch(catalogAction.setSortingType(sortingType));
  }

  function getAll() {
    categoryList.map((item, index) => {
      actives[index] = false;
      if (item.subCategoryList)
        item.subCategoryList.map((item2, index3) => {
          item2.activesSubCategory[0] = false;
        });
    });
    dispatch(catalogAction.getProductsBySubCatId(0, sortAsc, 0, 0));
    dispatch(catalogAction.setCategoryName(""));
    dispatch(catalogAction.setSubCategoryName(""));
    dispatch(catalogAction.setSortingType(sortingType));
  }

  function getSortedByProductType(productType, sorting, score) {
    if (selectedSubCategory !== "") {
      dispatch(
        catalogAction.getProductsBySubCatId(
          selectedSubCategory.id,
          sorting,
          score,
          0
        )
      );
    } else {
      if (sortByTypeAsc === "SCORE")
        dispatch(catalogAction.getProductsBySubCatId(0, sorting, 0, 0));
      else
        dispatch(
          catalogAction.getProductsBySubCatId(
            0,
            sorting,
            employeeFirm.score.score,
            0
          )
        );
    }
    dispatch(catalogAction.setSortingAsc("ASC"));
    dispatch(catalogAction.setSortingByProductTypeAsc(productType));
    dispatch(catalogAction.setSelectedProduct(null));
    setActivePage(1);
  }

  const handleSwitch = sw => {
    setInputSw(sw);

    if (sw) {
      getSortedByProductType("SCORE", true, employeeFirm.score.score);
    } else {
      getSortedByProductType("ALL", true, 0);
    }
  };

  return (
    <>
      <div className="md:col-span-4 pr-8 lg:col-span-3">
        <div className="glaire2 overflow-hidden rounded-xl relative">
          <div className="bg-[url('assets/images/new-design/pattern.png')] absolute top-0 left-0 h-[236px] w-100"></div>

          <div className="flex items-center justify-center gap-3 bg-black bg-opacity-25 p-6 text-center text-2xl font-semibold text-white">
            {employeeFirm?.score?.score} <span className="text-base">Puan</span>
          </div>
          <div className="flex flex-col gap-4 px-4 pb-9 pt-6 text-xs font-semibold text-white">
            <div className="flex items-center gap-3">
              <span className="w-[100px] text-right opacity-80">
                Puanıma Göre :
              </span>{" "}
              <div>
                {/* {sortByTypeAsc === "ALL" ? (
                <div
                  className={"switch on"}
                  onClick={() => {
                    getSortedByProductType("SCORE", true, employeeFirm.score.score);
                  }}
                >
                  <div className={"shifter"}></div>
                </div>
              ) : (
                <div
                  className={"switch off"}
                  onClick={() => {
                    getSortedByProductType("ALL", true, 0);
                  }}
                >
                  <div className={"shifter"}></div>
                </div>
              )} */}

                <label className="relative -ml-1 inline-flex scale-75 cursor-pointer items-center">
                  <input type="checkbox" value="" className="peer sr-only" />
                  <div
                    onClick={() => handleSwitch(!inputSw)}
                    className="peer h-6 w-11 rounded-full bg-whitec after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-transparent after:bg-primary-500 after:transition-all after:content-[''] peer-checked:bg-primary-500 peer-checked:after:translate-x-full peer-checked:after:bg-white peer-focus:outline-none"
                  ></div>
                </label>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <span className="w-[100px] text-right opacity-80">
                Sipariş Hakkı :
              </span>{" "}
              <span>
                {homeOrderInfo?.orderCount && homeOrderInfo?.orderCount}
              </span>
            </div>
            <div className="flex items-center gap-3">
              <span className="w-[100px] text-right opacity-80">
                Kalan Süre :
              </span>{" "}
              <span className="font-normal">
                {homeOrderInfo?.remainTime && homeOrderInfo.remainTime}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-6 rounded-xl rounded-bl-[48px] bg-slate-200 p-6 pb-9 font-medium sm:mt-6 select-none">
          <a
            href="#"
            className={
              actives
                ? "active [&.active]:text-primary-500 w-100"
                : "flex items-center justify-between gap-4 text-slate-500 w-100"
            }
            onClick={e => {
              e.preventDefault();
              getAll();
            }}
          >
            <span>TÜM ÜRÜNLER</span>
          </a>

          {categoryList && categoryList.length > 0
            ? categoryList.map((item, index) => {
                return actives[index] ? (
                  <div key={index}>
                    <div className={"main d-flex align-items-center pb-1"}>
                      <a
                        className={
                          actives && actives[index]
                            ? "active [&.active]:text-primary-500 w-100 flex items-center justify-between"
                            : "flex items-center justify-between gap-4 text-slate-500 w-100"
                        }
                        onClick={() => {
                          setClicked(item, index);
                        }}
                      >
                        {item.name}
                        <IoChevronDown className="w-4 shrink-0" />
                      </a>
                    </div>
                    {item.subCategoryList && item.subCategoryList.length > 0
                      ? item.subCategoryList.map((item2, index2) => {
                          return item2.activesSubCategory[0] ? (
                            <div key={index2} className={"sub-categories"}>
                              <div
                                className={`sub-category d-flex align-items-center ${
                                  item2.activesSubCategory &&
                                  item2.activesSubCategory[0]
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <a
                                  className={`sub-category d-flex align-items-center ${
                                    item2.activesSubCategory &&
                                    item2.activesSubCategory[0]
                                      ? "active [&.active]:text-primary-500 flex items-center justify-between gap-4 w-100 pl-3 py-[10px]"
                                      : "flex items-center justify-between gap-4 text-slate-500 w-100 pl-3 py-[10px]"
                                  }`}
                                  onClick={() => {
                                    setClickedSubCategory(
                                      item,
                                      item2,
                                      index2,
                                      item2.id
                                    );
                                  }}
                                >
                                  {item2.name}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div key={index2} className={"sub-categories"}>
                              <div
                                className={
                                  "sub-category d-flex align-items-center"
                                }
                              >
                                <a
                                  className={`sub-category d-flex align-items-center ${
                                    item2.activesSubCategory &&
                                    item2.activesSubCategory[0]
                                      ? "active [&.active]:text-primary-500 flex items-center justify-between gap-4 w-100 pl-3 py-[10px]"
                                      : "flex items-center justify-between gap-4 text-slate-500 w-100 pl-3 py-[10px]"
                                  }`}
                                  onClick={() => {
                                    setClickedSubCategory(
                                      item,
                                      item2,
                                      index2,
                                      item2.id
                                    );
                                  }}
                                >
                                  {item2.name}
                                </a>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                ) : (
                  <div key={index} className={"category"}>
                    <div
                      className={"main d-flex align-items-center pb-1"}
                      onClick={() => {
                        setClicked(item, index);
                      }}
                    >
                      <a
                        className={
                          actives && actives[index]
                            ? "active [&.active]:text-primary-500 w-100"
                            : "flex items-center justify-between gap-4 text-slate-500 w-100"
                        }
                      >
                        {item.name}
                        <IoChevronDown className="w-4 shrink-0" />
                      </a>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};
export default MagazineLeftMenu;
